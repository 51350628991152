import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function FeedbackModal({ setFeedbackModalOpen }) {
  const dispatch = useDispatch();

  const toastOption = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };

  const modalCloseHandler = () => {
    setFeedbackModalOpen(false);
    dispatch({ type: "UNSHOW_FEEDBACK" });
    toast.success("Thanks for your response.", toastOption);
  };
  return (
    <div className="absolute top-[36%] z-10 border border-viewMore w-[90%] px-6 py-2 rounded-lg shadow-xl bg-card sm:w-[50%] md:w-[40%] md:top-[22rem]">
      <p className="text-justify text-primary">
        Since you have spent thirty minutes on our website, would you kindly
        assist us in enhancing our services?
      </p>
      <div className="flex justify-between mt-2">
        <Link to="/feedback" className="text-primary cursor-pointer">
          Yes
        </Link>
        <p
          onClick={modalCloseHandler}
          className="text-primary cursor-pointer">
          No
        </p>
      </div>
    </div>
  );
}

export default FeedbackModal;
