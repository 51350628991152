import Button from "@mui/material/Button";
import { Avatar } from "@mui/material";
import React from "react";
import A from "./CompetitionUI/A";
import B from "./CompetitionUI/B";
import C from "./CompetitionUI/C";
import { useHistory } from "react-router-dom";
const CompCard = ({ contest, getcontestdetails }) => {
  const history = useHistory();
  function formatDate(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dateParts = dateString.split("-");
    const year = dateParts[0].slice(2);
    const month = months[parseInt(dateParts[1]) - 1];
    const day = parseInt(dateParts[2].split(" ")[0]);
    const time = dateParts[2].split(" ")[2];
    const hour = parseInt(time.split(":")[0]);
    const minute = parseInt(time.split(":")[1]);
    const period = hour >= 12 ? "pm" : "am";
    const hour12 = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

    return `${day} ${month} ${year}, ${hour12}:${minute < 10 ? "0" + minute : minute
      } ${period}`;
  }
  return (
    <div
      className="p-0 m-0 text-primary flex flex-col rounded-sm overflow-hidden h-[20
        vh]  "
    >
      <div onClick={() => history.push(`/competition/${contest._id}`)} className="flex gap-2 flex-col  w-full font-medium bg-card rounded-lg">
        < A fields={contest?.responses.sections[1].fields} />
        < B fields={contest?.responses.sections[2].fields} />
        <div className=" grid grid-cols-2  gap-y-4 p-3 pt-0">
          {contest?.responses.sections[3].fields.slice(0, 2).map((field, index) => {
            return (<div key={index}>
              {/* {<div className="border-t-[0.5px] border-[#E4E4E4] dark:border-[#919EAB52] border-opacity-35 col-span-2"></div>} */}
              <div className="flex gap-2 items-start justify-start mt-3">
                <div className='pt-[0.1rem]'>
                  <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/3620a2f7d7715589ab912a69f9b3c186f3e2034a5f15bec4ad3ff36536a5c1f3?apiKey=3fb58597d6194b3d87a8416bcc508a07&" alt="" />
                </div>
                <div className='text-primary'>
                  <p className="lg:text-[0.63rem] mlg:text-sm font-gilroy-medium text-compPrimary ">{field.title}</p>
                  {field.fieldType === "date" ? <h3 className="text-xs lg:text-[0.5rem] text-compSec mlg:text-sm font-gilroy-semibold mlg:whitespace-nowrap">{formatDate(field.response)}</h3> : <h3 className="text-xs text-compSec md:text-sm font-gilroy-semibold ">{field.response}</h3>}
                </div>
              </div>
            </div>)
          })}
        </div>
      </div>
    </div>
  );
};
export default CompCard;
