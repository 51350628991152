// Main Function where our Global State is created and every Reducer is imported here and passed to middleware to create the state
import { createStore, combineReducers, applyMiddleware } from "redux";
import storage from "redux-persist/lib/storage";
// Thunk Middleware is used to handle async fetching call e.g., fetching the data from localhost:5000
import thunkMiddleware from "redux-thunk";
import AuthReducer from "./reducers/AuthReducer";
import ProcessReducer from "./reducers/ProcessReducer";
import SearchReducer from "./reducers/SearchReducer";
import SearchPostReducer from "./reducers/SearchPostReducer";
import NotificationReducer from "./reducers/NotificationReducer";
import VideoMuteToggleReducer from "./reducers/VideoMuteToggle";
import {
  PostReducer,
  FetchPosts,
  FetchPost,
  updatePost,
  UpdateImage,
} from "./reducers/PostReducer";
// devTools for viewing our states in dev tools extensions
import { composeWithDevTools } from "redux-devtools-extension";
import { updateName } from "./reducers/ProfileReducer";
import { updateTheme } from "./reducers/ThemeReducer";
// function to combine multiple reducers

import { persistReducer, persistStore } from "redux-persist";

const rootReducers = combineReducers({
  AuthReducer,
  PostReducer,
  SearchReducer,
  SearchPostReducer,
  FetchPosts,
  FetchPost,
  updatePost,
  UpdateImage,
  updateName,
  NotificationReducer,
  ProcessReducer,
  VideoMuteToggleReducer,
  updateTheme,
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["AuthReducer", "ThemeReducer"],

};
const middlewares = [thunkMiddleware];

const persistedReducer = persistReducer(persistConfig, rootReducers);
// crreating main Store which will be exported
const Store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
// console.log(Store.getState());
export const persistor = persistStore(Store);

export default Store;
