import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Avatar } from "@mui/material";
import Man from "../Header/img/Man.svg";
import Female from "../Header/img/Female.svg";
import Others from "../Header/img/Others.svg";
import logoAnt from "../Header/img/Ant.png";
import logoAntDark from "../Header/img/Ant_Dark.png"

import ReplyIcon from "@mui/icons-material/Reply";

function SingleComment({
  cmt,
  index,
  setparentCmt,
  setfocus,
  setreplymodel,
  post,
  commentDeleteHandler,
  nestcommentDeleteHandler,
}) {
  const { user } = useSelector((state) => state?.AuthReducer);
  const [showreply, setshowreply] = useState(false);
  return (
    <div
      className={`border-l-2 rounded ${cmt?.isPositive ? "border-l-teal-500" : "border-l-yellow-500"
        } w-[100%] self-center`}>
      <li
        // ${cmt?.isPositive ? "bg-appreciateComment" : "bg-improveComment"}
        className={`items-center my-1  ${cmt?.isPositive ? "border-teal-600" : "border-yellow-600"} border-[0.5px] text-appreciateComment rounded-3xl`}
        key={cmt?.comment + index}>
        <div
          className={`gap-2 p-1 text-appreciateComment rounded-xl`}>
          <div className="flex items-center  justify-between w-[95%] sm:w-[100%] md:w-[90%] lg:w-[96%] xl:w-[100%]">
            {cmt.userId !== null &&
              cmt?.userId &&
              cmt.userId?.profilePicture ? (
              <Link
                to={
                  cmt.userId?._id === user._id
                    ? "/profile"
                    : `/userDetails/${cmt.userId?._id}`
                }>
                <Avatar
                  sx={{
                    // bgcolor: profilePictureColor,
                    width: 32,
                    height: 32,
                  }}
                  className=""
                  src={cmt.userId?.profilePicture}
                />
              </Link>
            ) : cmt.userId !== null && cmt.userId?.gender === "male" ? (
              <Link
                to={
                  cmt.userId?._id === user._id
                    ? ".profile"
                    : `/userDetails/${cmt.userId?._id}`
                }>
                <Avatar src={Man} sx={{ width: 25, height: 25 }} />
              </Link>
            ) : cmt.userId?.gender === "female" ? (
              <Link
                to={
                  cmt.userId?._id === user._id
                    ? ".profile"
                    : `/userDetails/${cmt.userId?._id}`
                }>
                <Avatar src={Female} sx={{ width: 25, height: 25 }} />
              </Link>
            ) : cmt.userId?.gender === "others" ? (
              <Link
                to={
                  cmt.userId?._id === user._id
                    ? ".profile"
                    : `/userDetails/${cmt.userId?._id}`
                }>
                <Avatar src={Others} sx={{ width: 25, height: 25 }} />
              </Link>
            ) : cmt.userId?.theme === "dark" ? (
              <Link
                to={
                  cmt.userId?._id === user._id
                    ? ".profile"
                    : `/userDetails/${cmt.userId?._id}`
                }>
                <Avatar src={logoAntDark} sx={{ width: 25, height: 25 }} />
              </Link>
            ) : (
              <Link
                to={
                  cmt.userId?._id === user._id
                    ? "/profile"
                    : `/userDetails/${cmt.userId?._id}`
                }>
                <Avatar src={logoAnt} sx={{ width: 25, height: 25 }} />
              </Link>
            )}
            <div className="max-w-[86%] min-w-[80%] lg:min-w-[86%]">
              <p className="break-words text-primary font-gilroy-semibold text-sm">
                {cmt?.comment}
              </p>
            </div>
            <div className="flex">
              <button>
                <ReplyIcon
                  className={`${cmt?.isPositive ? "text-teal-300" : "text-yellow-300"
                    }`}
                  onClick={() => {
                    setparentCmt(cmt);
                    setfocus();
                    setreplymodel(true);
                  }}
                />
              </button>
              {post?.userId?._id === user?._id ||
                cmt?.userId?._id === user?._id ? (
                <button
                  data-testid="delete-comment"
                  onClick={() => commentDeleteHandler(cmt?._id, post?._id)}>
                  <DeleteOutlinedIcon
                    className={`${cmt?.isPositive ? "text-teal-300" : "text-yellow-300"
                      }`}
                  />
                </button>
              ) : (
                // <button>
                //   <DeleteOutlinedIcon className="text-transparent" />
                // </button>
                <></>
              )}
            </div>
          </div>
        </div>
      </li>
      <ul className="w-[90%] ml-[auto]">
        {cmt?.child?.length !== 0 && !showreply && (
          <button
            onClick={() => setshowreply((perv) => !perv)}
            className={`text-sm font-bold ${user.theme == "light" ? "text-gray-700" : "text-gray-400"}`}>
            View replies
          </button>
        )}
        {cmt?.child?.length !== 0 &&
          showreply &&
          cmt?.child.map((nestCmt) => {
            return (
              <li
                className={`items-center my-1 ${nestCmt?.isPositive === true
                  ? "border-teal-600 "
                  : "border-yellow-600  "
                  } rounded-3xl border-[0.5px] text-primary`}
                key={nestCmt?.comment + index}>
                <div className="p-1 text-appreciateComment rounded-xl">
                  <div className="flex items-center  justify-between w-[100%]">
                    {nestCmt.userId !== null &&
                      nestCmt?.userId &&
                      nestCmt.userId?.profilePicture ? (
                      <Link
                        to={
                          nestCmt.userId?._id === user._id
                            ? "/profile"
                            : `/userDetails/${nestCmt.userId?._id}`
                        }>
                        <Avatar
                          sx={{
                            // bgcolor: profilePictureColor,
                            width: 25,
                            height: 25,
                          }}
                          className="w-6 h-6"
                          src={nestCmt.userId?.profilePicture}
                        />
                      </Link>
                    ) : nestCmt.userId !== null &&
                      nestCmt.userId?.gender === "male" ? (
                      <Link
                        to={
                          nestCmt.userId?._id === user._id
                            ? "/profile"
                            : `/userDetails/${nestCmt.userId?._id}`
                        }>
                        <Avatar src={Man} sx={{ width: 25, height: 25 }} />
                      </Link>
                    ) : nestCmt.userId?.gender === "female" ? (
                      <Link
                        to={
                          nestCmt.userId?._id === user._id
                            ? ".profile"
                            : `/userDetails/${nestCmt.userId?._id}`
                        }>
                        <Avatar src={Female} sx={{ width: 25, height: 25 }} />
                      </Link>
                    ) : nestCmt.userId?.gender === "others" ? (
                      <Link
                        to={
                          nestCmt.userId?._id === user._id
                            ? ".profile"
                            : `/userDetails/${nestCmt.userId?._id}`
                        }>
                        <Avatar src={Others} sx={{ width: 25, height: 25 }} />
                      </Link>
                    ) : nestCmt.userId?.theme === "dark" ? (
                      <Link
                        to={
                          nestCmt.userId?._id === user._id
                            ? ".profile"
                            : `/userDetails/${nestCmt.userId?._id}`
                        }>
                        <Avatar src={logoAntDark} sx={{ width: 25, height: 25 }} />
                      </Link>
                    ) : (
                      <Link
                        to={
                          nestCmt.userId?._id === user._id
                            ? "/profile"
                            : `/userDetails/${nestCmt.userId?._id}`
                        }>
                        <Avatar src={logoAnt} sx={{ width: 25, height: 25 }} />
                      </Link>
                    )}
                    <div className="max-w-[86%] min-w-[80%] lg:min-w-[86%]">
                      <p className="break-words text-primary font-gilroy-semibold text-sm">
                        {nestCmt?.comment}
                      </p>
                    </div>
                    <div className="flex">
                      {post?.userId?._id === user?._id ||
                        nestCmt?.userId?._id === user?._id ? (
                        <button
                          data-testid="delete-comment"
                          onClick={() =>
                            nestcommentDeleteHandler(cmt?._id, nestCmt?._id)
                          }>
                          <DeleteOutlinedIcon
                            className={`${nestCmt?.isPositive == true
                              ? " text-teal-300"
                              : " text-yellow-300 "
                              }`}
                          />
                        </button>
                      ) : (
                        // <button>
                        //   <DeleteOutlinedIcon className="text-transparent" />
                        // </button>
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        {cmt?.child?.length !== 0 && showreply && (
          <button
            onClick={() => setshowreply((perv) => !perv)}
            className={`text-sm font-bold ${user.theme == "light" ? "text-gray-700" : "text-gray-400"}`}>
            Hide replies
          </button>
        )}
      </ul>
    </div >
  );
}

export default React.memo(SingleComment);
