import React, { useEffect, useState, useRef, useContext } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  postComment,
  postNestedComment,
} from "../../store/asyncMethods/PostMethods";
import ProfilePicture from "../ProfilePicture/ProfilePicture";

import { WebSocketContext } from "../../WebSocket";
import Appri from "./like.svg";
import Improve from "./dislike.svg";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import CancelIcon from "@mui/icons-material/Cancel";
const PostComment = ({
  post,
  allComments,
  setAllComments,
  parentCmt,
  myInputRef,
  replymodel,
  setreply,
}) => {
  const socket = useContext(WebSocketContext);
  const { commentErrors } = useSelector((state) => state.PostReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const [commentVal, setCommentVal] = useState("");
  const [commentLoader, setCommentLoader] = useState({
    type: false,
    flag: false,
  });
  const [CmtType, setCmtType] = useState();
  const dispatch = useDispatch();
  const handleComment = (e) => {
    setCommentVal(e.target.value);
  };

  const [posCmt, setposCmt] = useState();
  const [negCmt, setnegCmt] = useState();
  const handle = () => {
    setreply((perv) => !perv);
  };

  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    toastId: "IO0nn",
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: user.theme === "dark" ? "colored" : "light",
  };

  const handleCommentClick = async (e) => {
    setCommentLoader({ type: e, flag: true });

    setCommentVal((perv) => perv.trim());
    if (commentVal.trim() == "") {
      toast.info("Comment can't be empty", toastOption);
      setCommentLoader({ flag: false });
      return;
    }

    console.log("comment", commentVal);

    if (!replymodel) {
      const newComment = await dispatch(postComment(commentVal, post, e));
      const cmtData = {
        comment: commentVal,
        post: post,
        isPositive: e,
        user: user,
      };
      if (post.userId._id != user._id) {
        socket.emit("react notification", cmtData);
        console.log("react notification", cmtData);
      }

      if (newComment) {
        setAllComments((prev) => [newComment, ...prev]);
        setCommentLoader({ flag: false });
        setCmtType(null);
        setposCmt(null);
        setnegCmt(null);
        setCommentVal("");
        // console.log("newCmt" + newComment);
      }
    } else {
      console.log(parentCmt?._id);
      const nestedcmt = await dispatch(
        postNestedComment(parentCmt?._id, commentVal, e)
      );
      console.log(nestedcmt);
      const newallCmt = allComments.map((obj) => {
        if (obj._id == parentCmt._id) {
          obj.child.push(nestedcmt);
        }
        return obj;
      });
      console.log(newallCmt);
      setAllComments(newallCmt);
      setCommentLoader({ flag: false });
      setCmtType(null);
      setposCmt(null);
      setnegCmt(null);
      setCommentVal("");
      setreply(false);
    }
  };

  const handleCmtTypePos = () => {
    handleCommentClick(true);
  };
  const handleCmtTypeNeg = () => {
    handleCommentClick(false);
  };

  useEffect(() => {
    if (commentErrors && commentErrors.length > 0) {
      alert(commentErrors[0].msg);
    }
  }, [commentErrors]);

  useEffect(() => {
    setCommentVal("");
  }, [replymodel]);

  function useKey(key, cb) {
    const callbackRef = useRef(cb);

    useEffect(() => {
      callbackRef.current = cb;
    }, []);

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          callbackRef.current(event);
        }
      }
    }, [key]);
  }

  const [linkdisplay, setlinkdisplay] = useState("none");

  const sharebuttonHandler = () => {
    if (linkdisplay == "none") {
      setlinkdisplay("block");
    } else {
      setlinkdisplay("none");
    }
  };

  return (
    <div className="flex flex-row my-1 w-[100%] sm:w-[100%] py-1 relative items-center bg-compBg text-card ">
      {/* <div className="flex items-center justify-center ">
        <ProfilePicture profilePic={user} />
      </div> */}
      {/* <div className="mx-2 flex flex-row item-center rounded-2xl border-2 w-[100%] border-viewMore">
      </div> */}
      <div className="flex w-full bg-compBg items-center pl-[2px] pr-1 ml-1 focus-within:border-transparent border-2 border-viewMore rounded-3xl text-card  focus-within:ring-2 focus-within:ring-teal-700">
        <ProfilePicture profilePic={user} />
        <input
          type="text"
          placeholder={
            replymodel === true
              ? `Reply to ${parentCmt?.userId?.firstName}`
              : `Write a comment..`
          }
          value={commentVal}
          onChange={handleComment}
          ref={myInputRef}
          maxLength={200}
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     e.preventDefault();
          //     handleCmtTypeNeg();
          //   } else if (e.key === "Shift") {
          //     e.preventDefault();
          //     handleCmtTypePos();
          //   }
          // }}
          data-testid="commentInput"
          className=" text-sm sm:text-lg md:text-sm md:py-1 md:border-1 w-[100%] h-[100%] px-2 py-[2px] flex items-center outline-none text-card bg-compBg border-none"
        />

        <div className="flex flex-row items-center justify-between px-2">
          <div id="comment_type" className="flex flex-row ">
            {true && (
              <button
                className="flex items-center justify-center rounded-full"
                onClick={handleCmtTypePos}
                data-testid="Post-Comment">
                {commentLoader.type && commentLoader.flag ? (
                  <ClipLoader
                    color={"#008080"}
                    //loading={loading}
                    //cssOverride={override}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <div className="relative group bottom-zero">
                    <div className="py-1 px-2  opacity-0 group-hover:opacity-100 transition-opacity bg-teal-600 rounded-full top-7 -left-6 absolute ">
                      <h3 className="text-white font-semibold text-xs">
                        Appreciate
                      </h3>
                    </div>
                    <img
                      src={Appri}
                      alt=""
                      height={"30px"}
                      width={"30px"}
                      style={{
                        opacity: 50,
                      }}
                    />
                  </div>
                )}
              </button>
            )}
            {true && (
              <button
                className="flex items-center p-1 rounded-3xl"
                onClick={handleCmtTypeNeg}>
                {!commentLoader.type && commentLoader.flag ? (
                  <ClipLoader
                    color={"#ffc107"}
                    //loading={loading}
                    //cssOverride={override}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <div className="relative group bottom-zero">
                    <div className="py-1 px-2  opacity-0 group-hover:opacity-100 transition-opacity bg-yellow-500 rounded-full top-7 -left-6 absolute ">
                      <h3 className="text-white font-semibold text-xs">
                        Improve
                      </h3>
                    </div>
                    <img src={Improve} alt="" height={"30px"} width={"30px"} />
                  </div>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      {replymodel === true && (
        <CancelIcon
          fontSize="60px"
          className="cursor-pointer ml-2 text-red-400"
          onClick={() => {
            setreply((perv) => !perv);
            setCommentVal("");
          }}
        />
      )}
    </div>
  );
};
export default React.memo(PostComment);
