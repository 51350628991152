import React, { useRef, useState } from "react";
import "./CloseAccount.css";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, Link } from "react-router-dom";
import { path } from "../../path";
import Alert from "../Alert/Alert";
import { Grid, Paper } from "@mui/material";
import { SendHGoogleEvent } from "../../analytics/ga";

const CloseAccount = ({ setVal }) => {
  const [checks1, setChecks1] = useState(false);
  const [checks2, setChecks2] = useState(false);
  const [pwdConfirmField, setPwdConfirmField] = useState(false);
  const { user, token } = useSelector((state) => state.AuthReducer);
  const buttonref = useRef(null);
  const [state, setState] = useState({
    currPassword: "",
    select: "",
    deletePost: "",
    check1: "",
    check2: "",
    comment: "",
    username: user?.username,
  });
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: user.theme === "dark" ? "colored" : "light",
  };
  const [FinalForm, setFinalForm] = useState(false);
  const dispatch = useDispatch();
  const handleInput = (e) => {
    setState({
      ...state,
      [e.target.name]:
        e.target.name == "check1" || e.target.name == "check2"
          ? "checked"
          : e.target.value,
    });
  };
  // console.log(state)
  const history = useHistory();
  const logout = () => {
    localStorage.removeItem("myToken");
    dispatch({ type: "LOGOUT" });
    dispatch({ type: "RESET_POSTS" });
    history.push("/");
  };
  const handleForm = async (e) => {
    e.preventDefault();
    console.log(state.check1, state.check2);
    if (checks1 === false || checks2 === false || !state.select) {
      // alert("Please fill all the fields")
      toast.error("Please check all the fields.", toastOption);
      return;
    } else {
      setPwdConfirmField(true);
    }
  };
  const config = {
    host: `${path}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${path}`,
      Authorization: `Bearer ${token}`,
    },
  };
  const finalSubmit = async () => {
    buttonref.current.disable = true;
    try {
      // api request to add user into closeAccount user's
      const response = await axios.post(
        `${path}/closeAccount/${user._id}`,
        state,
        config
      );
      // console.log(response);

      // to delete some users details from main user database
      const res2 = await axios.post(
        `${path}/deleteuserdetails/${user._id}`,
        state,
        config
      );
      // console.log(res2);

      // api call to delete all posts of user if he wants
      if (state.deletePost === "Yes") {
        const res = await axios.post(
          `${path}/deleteallpost/${user._id}`,
          state
        );
        // console.log(res);
      }

      if (Object.keys(response.data).length === 1) {
        toast.error(response.data.msg, toastOption);
      } else {
        setFinalForm(true);
        setTimeout(() => {
          setFinalForm(false);
        }, 1000);
        SendHGoogleEvent("Close account", "User closed their account");

        setTimeout(() => {
          setVal((prev) => !prev);
          dispatch({ type: "LOGOUT" });
          localStorage.removeItem("persist:root");
          localStorage.clear();
          dispatch({ type: "LOGOUT_SR" });
          dispatch({ type: "UNSET_HEADER" });
          dispatch({ type: "RESET_POSTS" });
          history.push("/");
        }, 1000);
        setPwdConfirmField(false);
      }
    } catch (error) {
      console.log(error);
    }
    buttonref.current.disable = false;
  };

  return (
    <>
      <div>
        {!FinalForm ? (
          <form onSubmit={handleForm} data-testid="form">
            <div className="md:mx-4 my-5">
              <h3 className="text-primary">Reason for close Account</h3>
              <div className="grid grid-cols-1">
                <select
                  name="select"
                  value={state.select}
                  onChange={handleInput}
                  placeholder="Select a reason"
                  data-testid="reason"
                  className="bg-neutral text-primary outline-none focus:ring-2 ring-teal-700 w-full md:w-[50%] p-2 border border-viewMore rounded-lg">
                  <option value="">Select any Reason</option>
                  <option value="Not enough motivated to enhance your skill">
                    Not enough motivated to enhance your skill.
                  </option>
                  <option value="Acquired competency, not looking to acquire new skill">
                    Acquired competency, not looking to acquire new skill.
                  </option>
                  <option value="Looking forward to explore offline world">
                    Looking forward to explore offline world.
                  </option>
                  <option value="Want to explore efficient options">
                    Want to explore efficient options.
                  </option>
                  <option value="Difficulty to find people with similar skill">
                    Difficulty to find people with similar skill.
                  </option>
                  <option value="Concerned about data">
                    Concerned about data.
                  </option>
                </select>
              </div>
            </div>
            <div className="md:mx-4 my-5 grid grid-cols-1">
              <textarea
                name="comment"
                value={state.comment}
                onChange={handleInput}
                rows={4}
                data-testid="comment"
                placeholder="Enter your comment (optional)"
                className="bg-neutral text-primary outline-none focus:ring-2 ring-teal-700 w-full md:w-[50%] p-2 border-viewMore border-1 rounded-lg"></textarea>
            </div>
            <div className="grid grid-cols-1 md:mx-4 my-5">
              <h3 className="text-primary">Do you want to delete your posts</h3>
              <select
                name="deletePost"
                value={state.deletePost}
                onChange={handleInput}
                data-testid="post-option"
                className="bg-neutral text-primary outline-none focus:ring-2 ring-teal-700 w-full md:w-[50%] p-2 border border-viewMore rounded-lg"
                placeholder="Select an option ">
                <option value="">Select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="md:mx-4">
              <div className="flex space-x-2">
                <input
                  name="check1"
                  id="check1"
                  value={checks1}
                  onChange={() => setChecks1(!checks1)}
                  type="checkbox"
                  data-testid="checkbox"
                  className="border border-viewMore rounded w-3 h-3 md:w-4 md:h-4 mt-1 accent-teal-700"
                />
                <label for="check1">
                  <span className="text-secondary  text-[0.7rem] md:text-[1rem]">
                    Your account will be erased after 30 days and all of your
                    data that you have opted for will be removed from server.
                  </span>
                </label>
              </div>
              <div className="flex space-x-2">
                <input
                  name="check2"
                  id="check2"
                  value={checks2}
                  data-testid="checkbox"
                  onChange={() => setChecks2(!checks2)}
                  type="checkbox"
                  className="border border-viewMore rounded w-3 h-3 md:w-4 md:h-4 mt-1 accent-teal-700"
                />
                <label for="check2">
                  <span className="text-secondary text-[0.7rem] md:text-[1rem]">
                    I authorize the account closure.
                  </span>
                </label>
              </div>
            </div>
            <hr className="border-viewMore mt-2"></hr>
            {pwdConfirmField !== true ? (
              <div className="flex flex-row justify-center md:justify-start">
                <input
                  style={{ border: "black", marginLeft: "0.5rem" }}
                  type="submit"
                  data-testid="button"
                  className="cursor-pointer rounded-full field4 w-[73vw] h-[5vh] px-2 border-2 border-[#0E0E0E] bg-[#FC5757]  my-[0.7rem]  md:h-[6vh] md:text-[1rem] md:w-[15vw] md:border-[#FC5757] md:bg-[#FC5757]   text-white hover:bg-red-600 transition duration-200"
                  value="Close My Account"
                />
              </div>
            ) : (
              <div
                className="ml-[1rem] mt-[0.2rem] flex flex-col space-y-2 md:flex-row md:items-center md:space-x-2"
                data-testid="final-div">
                <input
                  className="w-full md:w-[50%] md:mt-[0.3rem] p-2 bg-card border-viewMore border-1 rounded"
                  type="password"
                  onChange={handleInput}
                  value={state.currPassword}
                  name="currPassword"
                  placeholder="Enter Password"
                />
                <button
                  className="w-[10rem] bg-[#FC5757] rounded-full text-white cursor-pointer p-2"
                  data-testid="final-button"
                  ref={buttonref}
                  onClick={finalSubmit}>
                  Submit
                </button>
              </div>
            )}
          </form>
        ) : (
          <Alert
            msg={"Your account would be deleted after 30 days!!"}
            type="success"
          />
        )}
      </div>
    </>
  );
};

export default CloseAccount;
