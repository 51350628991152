import React from "react";
import { useState, useEffect } from "react";
import { path } from "../../path";
import axios from "axios";
import "./AllCompetitions.css"
import { Link, useHistory } from "react-router-dom";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

// import Avatar_similar_user from "../SimillarUsers/Avatar_similar_user.js";
import "./skills.css";
import UserAvatar from "./UserAvatar";
import AllFriends from "../AllFriendsPopUp.js/AllFriends";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import {  Card } from "@mui/material";
import AllCompetitions from "./AllCompetitions";
import SingleCompetition from "./../competition/SingleCompetition";
import CompCard from "../competition/CompCard";
const UserComps = () => {
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [similarSkillsUsers, setSimilarSkillsUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(5);
  const [AllUser, setAllUser] = useState(false);



  const [isRightSectionOpen, setIsRightSectionOpen] = useState(false);
  const [selectedCompetition, setSelectedCompetition] = useState();
  const [contests, setContests] = useState([]);
  const history = useHistory();

  const handleCompetitionClick = (competitionData) => {
    setSelectedCompetition(competitionData);
    setIsRightSectionOpen(true);
  };

  const handleRightSectionClose = () => {
    setIsRightSectionOpen(false);
  };

  //finding all the users having similar skillset
  const config = {
    host: `${path}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${path}`,
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    const findSimilarSkillsetUsers = async () => {
      setLoading(true);
      const res = await axios.get(
        `${path}/allSimilarSkillsUsers/${user._id}`,
        config
      );
      setSimilarSkillsUsers(res.data.data);
      setLoading(false);
    };
    findSimilarSkillsetUsers();
  }, []);

  //   set the default color of the profile picture
  const [profilePictureColor, setProfilePictureColor] = useState("green");

  const handleClick = (contest) => {
    // Redirect to the next page with the clicked card data
    history.push(`/competition/${contest?._id}`, { contestData: contest });
  };


  useEffect(() => {
    const getContests = async () => {
      try {
        setLoading(true);
        // const config = {
        //   host: `${path}`,
        //   headers: {
        //     "Access-Control-Allow-Origin": `${path}`,
        //     Authorization: `Bearer ${token}`,
        //   },
        //   // token,
        // };
        const res = await axios.get(`${path}/api/response/getAllresponse`, );
        console.log(res, "contest");
        // const x = res.data.filter((r) => r.status == "upcoming")

        setContests(res?.data.data);

         // Setting the first contest as default


        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getContests();
  }, []);
  console.log(contests)
  const getcontestdetails = async (cid) => {
    try {
        history.push(`/competition/${cid}`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
    {contests && 
    <div
      style={{
        overflow: "scroll",
        "@media (min-width: 767px)": {
          maxHeight: "71vh", // Adjust this accordingly for smaller devices
        },
      }}
      className={" w-[100%] h-max-[65vh] "}>
    {contests.length>0 && contests?.map((contest) => (
            <Card key={contest?._id} className="mb-4">
              <CompCard contest={contest} getcontestdetails={getcontestdetails}></CompCard>
            </Card>
          ))}
    </div>
        }
        </>
  );
};

export default React.memo(UserComps);
