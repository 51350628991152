import React, { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import deleteIcon from "../Alert/39-trash-solid.gif";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const AlertSignUp = ({ msg, type }) => {
  useEffect(() => {
    var home = document.getElementById("home-content");
    if (home) {
      home.style.opacity = 0.01;
    }
    return () => {
      if (home) {
        home.style.opacity = 1;
      }
    };
  }, []);
  return (
    <div
      className="absolute bg-card text-primary top-12  left-[5%] w-[90%] sm:left-[25%] sm:right-[25%] sm:w-[50%] lg:left-[30%] lg:right-[30%] lg:w-[40%] xl:left-[35%] xl:right-[35%] xl:w-[30%]  mx-auto p-5 border   shadow-lg rounded-2xl bg-card
     z-10">
      <div className="flex flex-row items-center ">
        {type === "success" && (
          <div>
            {" "}
            <CheckCircleIcon
              style={{ width: 40, height: 60 }}
              className="text-teal-700"
            />{" "}
          </div>
        )}
        {type === "delete" && (
          <div>
            {" "}
            <img src={deleteIcon} alt="df" className="w-20 h-20" />{" "}
          </div>
        )}
        {type === "error" && (
          <div>
            {" "}
            <ErrorOutlineIcon
              style={{ width: 40, height: 60 }}
              className="text-red-700"
            />{" "}
          </div>
        )}
        <div className="ml-3">
          {" "}
          <h2> {msg}</h2>{" "}
        </div>
      </div>
    </div>
  );
};

export default AlertSignUp;
