
import { useSelector } from "react-redux";
export default function Widget({contest}) {
    function formatDate(dateString) {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
          ];
        const dateParts = dateString.split("-");
        const year = dateParts[0].slice(2);
        const month = months[parseInt(dateParts[1]) - 1];
        const day = parseInt(dateParts[2].split(" ")[0]);
        const time = dateParts[2].split(" ")[2];
        const hour = parseInt(time.split(":")[0]);
        const minute = parseInt(time.split(":")[1]);
        const period = hour >= 12 ? "pm" : "am";
        const hour12 = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;
      
        return `${day} ${month} ${year}, ${hour12}:${minute < 10 ? '0' + minute : minute} ${period}`;
      }
    const { user, token } = useSelector((state) => state.AuthReducer);

    return (
        <div className={`w-full rounded-xl  h-fit overflow-hidden hover:cursor-pointer mx-auto  ${user.theme === 'dark' ? 'bg-[#161C24]' : 'bg-primary'} shadow-md  ${user.theme === 'dark' ? 'text-white' : 'text-black'} ${user.theme === 'dark' ? 'bg-[#151a21]' : 'bg-primary'}`}>
    <div class="flex justify-space-between  bg-primary mt-3">
        <div class="flex items-center w-[55%] mx-2  ">
            <div class="w-[30%] flex flex-row items-center justify-center rounded-lg py-2">
                <img class="object-cover h-20 w-32 rounded-md mb-3" src={contest?.responses.sections[1].fields[0].response} alt="Contest " />
               
            {/* <div className="mt-2 invisible">Feedants</div> */}
            </div>
            <div class="flex-none ml-2">
                <h1 class="text-lg font-gilroy-semibold text-gilroy-black ">  {contest?.responses.sections[2].fields[0].response}</h1>
                <p class="font-gilroy  dark:text-[#9C9C9D] text-compPrimary text-xs">{contest?.responses.sections[0].description.trim()} •{" "} 
                    {contest?.responses.sections[2].fields[2]?.response.trim()} •  {" "}  {contest?.responses.sections[2].fields[3]?.response.trim()}</p>
                    <p class="font-gilroy invisible dark:text-[#9C9C9D] text-compPrimary text-xs">{contest?.responses.sections[0].description.trim()} •{" "} 
                    {contest?.responses.sections[2].fields[2]?.response.trim()} •  {" "}  {contest?.responses.sections[2].fields[3]?.response.trim()}</p>
            </div>
        </div>
        <div class="w-[60%] flex mx-2 ">
        <div className="font-gilroy flex flex-row px-4 py-2 justify-between ">
                  <div className="flex flex-col ">
                    <p className="text-sm invisible">Feedants</p>
                    <p className="font-gilroy-medium dark:text-[#9C9C9D] text-compPrimary text-xs">{contest?.responses.sections[3].fields[0]?.title}</p>
                    <p className="text-sm text-gilroy-black whitespace-nowrap">{formatDate(contest?.responses.sections[3].fields[0]?.response)}</p>
                  </div>
                  <br></br>
                  <div className="flex flex-col mx-2 ">
                  <p className="text-sm invisible">Feedants</p>
                  <p className=" font-gilroy-medium dark:text-[#9C9C9D] text-compPrimary text-xs">{contest?.responses.sections[3].fields[1]?.title}</p>
                  <p className="text-sm text-gilroy-black whitespace-nowrap">{formatDate(contest?.responses.sections[3].fields[1]?.response)}</p>
                  </div>
                </div>
        </div>
        <div class="mx-9 w-10 flex flex-col items-center justify-end ">
            <div class="mb-12">
                <div class="flex flex-col ">
                    {/* <button class="my-1 px-3 py-1 bg-teal-600 text-white text-md font-bold-gilroy uppercase rounded-full">Participate now</button> */}
                    <center><span class=" font-bold-gilroy whitespace-nowrap text-[#008080]">INR  {contest?.responses?.sections[2].fields[1].response}</span></center>
                </div>
            </div>
        </div>
    </div>
</div>
    )
}