import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
const AlertInvalidFormat = ({ msg, toggle }) => {
  const handleClose = () => {
    toggle("");
  };
  return (
    <div className="text-primary fixed  top-12  left-[5%] w-[90%] sm:left-[25%] sm:right-[25%] sm:w-[50%] lg:left-[30%] lg:right-[30%] lg:w-[40%] xl:left-[35%] xl:right-[35%] xl:w-[30%]  mx-auto p-5 border-viewMore shadow-lg rounded-2xl bg-card z-10">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center ">
          <div>
            {" "}
            <ErrorIcon
              style={{ width: 40, height: 60 }}
              className="text-red-500"
            />{" "}
          </div>
          <div className="ml-3">
            {" "}
            <div dangerouslySetInnerHTML={{ __html: msg }} />
          </div>
        </div>
        <div className="">
          <CloseIcon
            className="text-red-400 border-2 border-red-400 cursor-pointer rounded-2xl"
            onClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};

export default AlertInvalidFormat;
