import React from 'react'

import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import feedants_logo from "./yellow.png"

function ParticipateUserList({ userList, closeList }) {

    const { user } = useSelector(state => state.AuthReducer)
    const history = useHistory();
    console.log(userList.map(user => {
        return user?.profilePicture;
    }))

    const RedirectUser = (id) => {
        if (id === user._id) {
            history.push("/profile");
        } else {
            history.replace(`/userDetails/${id}`)
        }
    }

    return (

        <div className='flex flex-col gap-2 h-full md:h-[60%] overflow-auto md:overflow-y-scroll justify-between self-center px-5 mt-12 w-full font-semibold text-primary md:max-w-full max-w-[392px]'>
            <div className='flex items-center justify-between'>
                <h1 className='text-base font-gilroy-semibold cursor-pointer text-compSec '> Participants ({userList?.length})</h1>
                <ArrowBackIcon className='cursor-pointer text-compSec' onClick={() => closeList(true)} />
            </div>
            <div className='flex flex-col gap-4 md:h-[60%] h-full'>
                {userList.map((user, index) => {
                    return (
                        <div key={index} onClick={() => RedirectUser(user._id)} className="flex cursor-pointer flex-1 items-center gap-2 p-1.5 md:py-0.5 rounded-md bg-compBg max-h-[53px]">
                            {(user?.profilePicture !== ("") && user?.profilePicture) ? <img
                                loading="lazy"
                                src={user?.profilePicture}
                                alt="profilePic"
                                className="shrink-0 aspect-square rounded-md w-[39px] h-[39px]"
                            /> : <img
                                loading="lazy"
                                src={feedants_logo}
                                alt="profilePic"
                                className="shrink-0 aspect-square rounded-md w-[29px] h-[29px]"
                            />}

                            <h2 className="flex-auto my-auto text-compSec font-gilroy-regular text-xs">{user.firstName} {user.lastName} </h2>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ParticipateUserList