import axios from "axios";
import { useSelector } from "react-redux";
import { UPDATE_ACCOUNT } from "../types/AuthTypes";
import {
  CREATE_ERRORS,
  SET_LOADER,
  CLOSE_LOADER,
  UPDATE_IMAGE_ERRORS,
  REDIRECT_TRUE,
  SET_MESSAGE,
  REMOVE_ERRORS,
  SET_POSTS,
  SET_PHOTO_POSTS,
  SET_VIDEO_POSTS,
  SET_BLOG_POSTS,
  POST_REQUEST,
  SET_POST,
  SET_UPDATE_ERRORS,
} from "../types/postTypes";
import { path } from "./../../path";

// function for creating BLOG

export const createAction = (postData) => {
  return async (dispatach, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    let response;
    try {
      // validating a user using token
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      //  dispacth function check reducders
      //   dispatach({ type: SET_LOADER });
      dispatach({ type: "SET_POSTLOADING", payload: true });
      response = await axios.post(`${path}/create_post`, postData, config);

      const { data } = response;
      dispatach({ type: "SET_POSTLOADING", payload: false });
      console.log("here");
      if ("errors" in data) {
        const { errors } = data;
        // dispatach({ type: CLOSE_LOADER });
        dispatach({ type: "CREATE_BLOG_ERRORS", payload: errors });
      } else {
        //  handling errors
        dispatach({ type: "ADD_NEW_TEMP_POSTS", payload: data.newPost });
        dispatach({ type: REDIRECT_TRUE });
        dispatach({ type: REMOVE_ERRORS });
        dispatach({ type: SET_MESSAGE, payload: data.msg });
        // dispatach({ type: CLOSE_LOADER });
        dispatach({ type: "SET_USER_LEVEL", payload: data.level });
        dispatach({ type: "NEW_POST", payload: data.newPost });
      }
    } catch (error) {
      dispatach({ type: "SET_POSTLOADING", payload: false });
      //   dispatach({ type: CLOSE_LOADER });
    }
  };
};
// Function for Creating Image Blog
export const createActionImage = (postData, setProgress) => {
  return async (dispatach, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    // dispatach({ type: SET_LOADER });
    let response;
    try {
      // validating a user using token
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      dispatach({ type: "SET_POSTLOADING", payload: true });
      response = await axios.post(`${path}/create_post_image`, postData, {
        ...config,
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });
      dispatach({ type: "SET_POSTLOADING", payload: false });
      const { data } = response;
      //  Handling erros
      if ("errors" in data) {
        const { errors } = data;
        // dispatach({ type: CLOSE_LOADER });
        dispatach({ type: "CREATE_IMAGE_ERRORS", payload: errors });
      } else {
        dispatach({ type: "ADD_NEW_TEMP_POSTS", payload: data.newPost });
        dispatach({ type: REDIRECT_TRUE });
        dispatach({ type: "NEW_POST", payload: data.newPost });
        dispatach({ type: "SET_USER_LEVEL", payload: data.level });
        dispatach({ type: REMOVE_ERRORS });
        dispatach({ type: SET_MESSAGE, payload: data.msg });
        // dispatach({ type: CLOSE_LOADER });
      }
    } catch (error) {
      dispatach({ type: SET_MESSAGE, payload: error.messsage });
      dispatach({ type: "SET_POSTLOADING", payload: false });
      //   dispatach({ type: CLOSE_LOADER });
    }
  };
};
// function for creating Video Post
export const createActionVideo = (postData, setProgress) => {
  return async (dispatach, getState) => {
    const {
      AuthReducer: { token },
    } = getState();
    // dispatach({ type: SET_LOADER });
    let response;
    try {
      // validating a user using token
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      dispatach({ type: "SET_POSTLOADING", payload: true });
      dispatach({
        type: "SET_POSTLOADING_TYPE",
        payload: "Video is Uploading...",
      });

      response = await axios.post(`${path}/create_post_video`, postData, {
        ...config,
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      });
      dispatach({ type: "SET_POSTLOADING", payload: false });
      dispatach({ type: "SET_POSTLOADING_TYPE", payload: "" });
      const { data } = response;
      //console.log(data);
      //  hanling errors
      if ("errors" in data) {
        const { errors } = data;
        //console.log(errors);
        // dispatach({ type: CLOSE_LOADER });
        dispatach({ type: "CREATE_VIDEO_ERRORS", payload: errors });
      } else {
        dispatach({ type: REDIRECT_TRUE });
        // //console.log(response);
        dispatach({ type: REMOVE_ERRORS });
        dispatach({ type: SET_MESSAGE, payload: data.msg });
        dispatach({ type: "ADD_NEW_TEMP_POSTS", payload: data.newPost });
        console.log(data, "aYUSH VID");
        dispatach({ type: "SET_USER_LEVEL", payload: data.level });
        // dispatach({ type: CLOSE_LOADER });
        dispatach({ type: "NEW_POST", payload: data.newPost });
      }
    } catch (error) {
      dispatach({ type: "SET_POSTLOADING", payload: false });
      dispatach({ type: SET_MESSAGE, payload: error.messsage });
      //   dispatach({ type: CLOSE_LOADER });
    }
  };
};

export const FetchPosts = (userId) => {
  return async (dispatach, getState) => {
    const {
      AuthReducer: { token, user },
    } = getState();
    // validating a user using token
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
      token,
    };
    dispatach({ type: SET_LOADER });
    try {
      dispatach({ type: SET_LOADER });
      const response = await axios.get(`${path}/posts/${userId}`, config);

      const { data } = response;

      // console.log("in the fetch dispactch", response);
      dispatach({ type: SET_POSTS, payload: data.posts });
      dispatach({ type: CLOSE_LOADER });
      dispatach({ type: POST_REQUEST });
    } catch (error) {
      dispatach({ type: CLOSE_LOADER });
      // //console.log(error);
    }
  };
};

// Fetch all the posts created by a user using his ID.
export const FetchPhotoPosts = (id, count) => {
  return async (dispatach, getState) => {
    const {
      AuthReducer: { token, user },
    } = getState();
    // validating a user using token
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
      token,
    };
    dispatach({ type: SET_LOADER });
    try {
      dispatach({ type: SET_LOADER });
      const response = await axios.get(
        `${path}/photoposts/${id}/${count}`,
        config
      );
      const { data } = response;
      // console.log(data, "photo");
      dispatach({ type: SET_PHOTO_POSTS, payload: data.data });
      dispatach({ type: CLOSE_LOADER });
      // dispatach({ type: POST_REQUEST });
    } catch (error) {
      dispatach({ type: CLOSE_LOADER });
      // //console.log(error);
    }
  };
};
// Fetch all the video posts created by a user using his ID.
export const FetchVideoPosts = (id, count) => {
  return async (dispatach, getState) => {
    const {
      AuthReducer: { token, user },
    } = getState();
    // validating a user using token
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
      token,
    };
    dispatach({ type: SET_LOADER });
    try {
      dispatach({ type: SET_LOADER });
      const response = await axios.get(
        `${path}/videoposts/${id}/${count}`,
        config
      );
      const { data } = response;
      //console.log(data, "video")
      dispatach({ type: SET_VIDEO_POSTS, payload: data.data });
      dispatach({ type: CLOSE_LOADER });
      // dispatach({ type: POST_REQUEST });
    } catch (error) {
      dispatach({ type: CLOSE_LOADER });
      // //console.log(error);
    }
  };
};
// Fetch all the posts created by a user using his ID.
export const FetchBlogPosts = (id, count) => {
  return async (dispatach, getState) => {
    const {
      AuthReducer: { token, user },
    } = getState();
    // validating a user using token
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
      token,
    };
    dispatach({ type: SET_LOADER });
    try {
      dispatach({ type: SET_LOADER });
      const response = await axios.get(
        `${path}/blogposts/${id}/${count}`,
        config
      );
      const { data } = response;
      // //console.log(data)
      dispatach({ type: SET_BLOG_POSTS, payload: data.data });
      dispatach({ type: CLOSE_LOADER });
      // dispatach({ type: POST_REQUEST });
    } catch (error) {
      dispatach({ type: CLOSE_LOADER });
      // //console.log(error);
    }
  };
};

// Fetching the Post(particular Post) using Post ID.
export const fetchPost = (id) => {
  return async (dispatach, getState) => {
    const {
      AuthReducer: { token, user },
    } = getState();
    const { FetchPost } = getState();
    // validating a user using token
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
      token,
    };
    dispatach({ type: SET_LOADER });
    try {
      const response = await axios.get(`${path}/post/${id}`, config);
      const {
        data: { post },
      } = response;
      dispatach({ type: SET_POST, payload: post });
      localStorage.setItem("currPost", JSON.stringify(post));
      // //console.log(id);
      dispatach({ type: CLOSE_LOADER });
    } catch (error) {
      dispatach({ type: CLOSE_LOADER });
      //console.log(error.message);
    }
  };
};
// Fetching Post using a particular Skill
export const fetchSkillWise = (skill) => {
  return async (dispatach, getState) => {
    const {
      AuthReducer: { token, user },
    } = getState();
    // validating a user using token
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
      token,
    };
    dispatach({ type: SET_LOADER });
    try {
      const allSkills = [];
      allSkills.push(skill);
      // const skills = JSON.stringify(allSkills);
      const response = await axios.post(
        `${path}/skillFetch`,
        { skills: JSON.stringify(allSkills) },
        config
      );
      const data = response.data.posts;
      //console.log(data);//
      dispatach({ type: "SET_POST_SKILL", payload: skill });
      dispatach({ type: "SET_POST_DATA", payload: data });
      dispatach({ type: CLOSE_LOADER });
    } catch (error) {
      dispatach({ type: CLOSE_LOADER });
      //console.log(error.message);
    }
  };
};
// Update Blog
export const updateAction = (editData) => {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_POSTLOADING", payload: true });
    const {
      AuthReducer: { token },
      PostReducer: { newTempPosts },
    } = getState();

    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      dispatch({ type: SET_LOADER });
      const response = await axios.post(`${path}/updatePost`, editData, config);
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: REDIRECT_TRUE });
      if (
        Object.keys(newTempPosts).length > 0 &&
        newTempPosts._id == response.data.post._id
      )
        dispatch({ type: "ADD_NEW_TEMP_POSTS", payload: response.data.post });
      dispatch({ type: "UPDATE_POST", payload: response.data.post });
      dispatch({ type: "SET_POSTLOADING", payload: false });
    } catch (error) {
      const {
        response: {
          data: { errors },
        },
      } = error;
      dispatch({ type: "SET_POSTLOADING", payload: false });
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: SET_UPDATE_ERRORS, payload: errors });
      //console.log(error.response, "ASassa");
    }
  };
};
// Update Image Post
export const updateImageAction = (updateData) => {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_POSTLOADING", payload: true });
    const {
      AuthReducer: { token },
      PostReducer: { newTempPosts },
    } = getState();
    // validating a user using token
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const response = await axios.post(
        `${path}/updateImage`,
        updateData,
        config
      );

      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: REDIRECT_TRUE });
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      if (
        Object.keys(newTempPosts).length > 0 &&
        newTempPosts._id == response.data.post._id
      )
        dispatch({ type: "ADD_NEW_TEMP_POSTS", payload: response.data.post });
      dispatch({ type: "UPDATE_POST", payload: response.data.post });
      dispatch({ type: "SET_POSTLOADING", payload: false });
    } catch (error) {
      const {
        response: {
          data: { errors },
        },
      } = error;
      dispatch({ type: "SET_POSTLOADING", payload: false });
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: UPDATE_IMAGE_ERRORS, payload: errors });
      //console.log(error.response, "UPDATE_IMAGE_ERROS");
    }
  };
};
// Update Video Post
export const updateVideoAction = (updateData) => {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_POSTLOADING", payload: true });
    const {
      AuthReducer: { token },
      PostReducer: { newTempPosts },
    } = getState();
    // validating a user using token
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: SET_LOADER });
    try {
      const response = await axios.post(
        `${path}/updateVideo`,
        updateData,
        config
      );

      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: REDIRECT_TRUE });
      dispatch({ type: SET_MESSAGE, payload: response.data.msg });
      if (
        Object.keys(newTempPosts).length > 0 &&
        newTempPosts._id == response.data.post._id
      )
        dispatch({ type: "ADD_NEW_TEMP_POSTS", payload: response.data.post });
      dispatch({ type: "UPDATE_POST", payload: response.data.post });
      dispatch({ type: "SET_POSTLOADING", payload: false });
    } catch (error) {
      const {
        response: {
          data: { errors },
        },
      } = error;
      dispatch({ type: "SET_POSTLOADING", payload: false });
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: UPDATE_IMAGE_ERRORS, payload: errors });
      //console.log(error.response, "UPDATE_IMAGE_ERROS");
    }
  };
};
// Delete Post
export const deletePost = (id) => {
  return async (dispatch, getState) => {
    // //console.log("Over here");
    // confirming a user to delete
    const confirm = window.confirm("Do you really want to delete this post?");
    if (confirm) {
      dispatch({ type: SET_LOADER });
      try {
        const {
          AuthReducer: { token },
        } = getState();
        // validating a user using token
        const config = {
          host: `${path}`,
          headers: {
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
          },
        };
        const {
          data: { msg },
        } = await axios.get(`${path}/delete/${id}`, config);
        dispatch({ type: REDIRECT_TRUE });
        dispatch({ type: SET_MESSAGE, payload: msg });
      } catch (error) {
        //console.log(error.meaasage);
        dispatch({ type: CLOSE_LOADER });
        //console.log(error);
      }
    }
  };
};
// UnAppricate a pOST
export const unLikePost = (id, type) => {
  return async (dispatch, getState) => {
    //   dispatch({type:SET_LOADER});
    try {
      const {
        AuthReducer: { token, user },
        FetchPosts: { posts },
      } = getState();
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${path}/postunAppriciate/${id}`,
        { user, type },
        config
      );
      const newData = posts.data.map((item) => {
        if (item._id == response.data._id) {
          // //console.log(response.data,"RESULT");
          return response.data;
        } else {
          // //console.log(item,"ITEM");
          return item;
        }
      });
      // //console.log(newData);
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
      //console.log(error);
    }
  };
};
// Appriciate a Post
export const likePost = (id, type) => {
  return async (dispatch, getState) => {
    //   dispatch({type:SET_LOADER});
    //console.log("like post dispatch",dispatch,getState())
    try {
      const {
        AuthReducer: { token, user },
        FetchPosts: { posts },
      } = getState();
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${path}/postAppriciate/${id}`,
        { user, type },
        config
      );
      console.log(posts, "hii", response);
      const newData = posts.data.map((item) => {
        //console.log("item",item,response.data._id)
        // console.log("control is now inside");
        if (item._id === response.data._id) {
          return response.data;
        } else {
          return item;
        }
      });
    } catch (error) {
      //console.log(error.message);
      dispatch({ type: CLOSE_LOADER });
      //console.log(error);
    }
  };
};
// Getting All the Post Data randomly
export const getPostData = () => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADER });
    try {
      const {
        AuthReducer: { token, user },
      } = getState();
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      //console.log("user",user)
      const response = await axios.post(`${path}/homePostData`, user, config);
      //console.log("response",response)

      dispatch({ type: "SET_HOME_PAGE_DATA", payload: response.data.data });
      dispatch({ type: CLOSE_LOADER });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {}
  };
};
// Commenting on  a Post
export const postComment = (comment, post, isPositive) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADER });
    //console.log("data",getState())
    try {
      const {
        AuthReducer: { token, user },
      } = getState();
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      //console.log("FE: " + isPositive);
      const response = await axios.post(
        `${path}/postComment`,
        { comment, user, post, isPositive },
        config
      );
      const { data } = response;
      // const { _id } = data.cmnt;
      // console.log(response);
      // const nestedCmt = await axios.post(
      //   `${path}/nestedComment`,
      //   {
      //     parentId: _id,
      //     comment: data.cmnt.comment,
      //     isPositive: data.cmnt.isPositive,
      //     userId: data.cmnt.userId,
      //   },
      //   config
      // );
      // console.log(nestedCmt);
      if ("errors" in data) {
        //  //console.log(data);
        const { errors } = data;
        dispatch({ type: CLOSE_LOADER });
        dispatch({ type: "CREATE_COMMENT_ERRORS", payload: errors });
      } else {
        dispatch({ type: SET_MESSAGE, payload: data.postt });
        dispatch({ type: CLOSE_LOADER });
      }
      dispatch({ type: CLOSE_LOADER });
      return data.cmnt;
    } catch (error) {
      console.log(error);
    }
  };
};
// getting a comment on the Post
// export const getComments = (id) => {
//   return async (dispatch, getState) => {
//     dispatch({ type: SET_LOADER });
//     try {
//       const {
//         AuthReducer: { token },
//       } = getState();
//       const config = {
//         host: `${path}`,
//         headers: {
//           "Access-Control-Allow-Origin": `${path}`,
//           Authorization: `Bearer ${token}`,
//         },
//       };
//       const response = await axios.get(`${path}/getComments/${id}`, config);
//       console.log(response);
//       dispatch({ type: "SET_POST_COMMENT", payload: response.data.comment });
//       dispatch({ type: CLOSE_LOADER });
//     } catch (error) {
//       //console.log(error);
//     }
//   };
// };

export const getComments = (id, page) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADER });
    try {
      // if(page != 1){
      const {
        AuthReducer: { token },
      } = getState();
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${path}/getComments/${id}?page=${page}`,
        config
      );
      if (response.data.comments && response.data.comments.length > 0) {
        dispatch({
          type: "SET_POST_COMMENT",
          payload: { id, data: response.data.comments, page },
        });
      }
      // }
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      // console.log(error);
    }
  };
};
export const deleteNestedComment = (parentId, cmtID) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADER });
    try {
      const {
        AuthReducer: { token, user },
      } = getState();
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };

      const res = axios.post(
        `${path}/deletenestedComment`,
        { parentId, cmtID },
        config
      );
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const postNestedComment = (parentId, comment, isPositive) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADER });
    try {
      const {
        AuthReducer: { token, user },
      } = getState();
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      console.log(parentId);
      const response = await axios.post(
        `${path}/nestedComment`,
        { parentId, comment, isPositive, userId: user._id },
        config
      );
      const { data } = response;
      console.log(response);
      if ("errors" in data) {
        const { errors } = data;
        dispatch({ type: CLOSE_LOADER });
        dispatch({ type: "CREATE_COMMENT_ERRORS", payload: errors });
      } else {
        dispatch({ type: SET_MESSAGE, payload: data.postt });
        dispatch({ type: CLOSE_LOADER });
      }
      dispatch({ type: CLOSE_LOADER });
      return data.cmt;
    } catch (error) {
      dispatch({ type: CLOSE_LOADER });
    }
  };
};

export const deleteComment = (post, commentId) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADER });
    try {
      const {
        AuthReducer: { token },
      } = getState();
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(
        `${path}/deleteComment`,
        { postId: post._id, commentId },
        config
      );
      dispatch({ type: "DELETE_COMMENT", payload: response.data });
    } catch (error) {
      //console.log(error);
    }
  };
};

export const deleteSkillsFromUser = (userId, name) => {
  //console.log("FE UIDUNAME: "+userId+" "+name);
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADER });
    try {
      const {
        AuthReducer: { token },
      } = getState();
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      console.log("works fine");
      console.log(name);
      console.log(userId);
      console.log("still ok");
      const response = await axios.post(
        `${path}/deleteSkillsFromUser/${userId}`,
        { name },
        config
      );

      console.log("FILT dispatch: " + response.data);
      dispatch({ type: UPDATE_ACCOUNT, payload: response.data });
      dispatch({ type: CLOSE_LOADER });
    } catch (error) {
      console.log(error);
      dispatch({ type: CLOSE_LOADER });
    }
  };
};
