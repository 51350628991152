import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { path } from "../../path";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import VideoPreview from "./VideoPreviewPanel";
import pauseIcon from "./pause-icon.svg";

const SubmissionsList = () => {
  const { submissionId } = useParams();
  const [submission, setSubmission] = useState();
  const [selectedVideo, setSelectedVideo] = useState(null); // State to manage selected video
  const { token } = useSelector((state) => state.AuthReducer);

  const getSubmission = async (submissionId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.get(
        `${path}/contest/getSubmissionsbyId/${submissionId}`,
        config
      );
      const rawData = response?.data?.result;
      console.log("RAW DATA :::", rawData);
      setSubmission(rawData);
    } catch (error) {
      console.error("FETCH SUBMISSION ERROR", error.message);
    }
  };

  useEffect(() => {
    getSubmission(submissionId);
  }, [submissionId]);

  const info = submission?.map((item) => ({
    id: item._id,
    username: item.user ? item.user.username : "User Not found",
    firstName: item.user ? item.user.firstName : "Null",
    lastName: item.user ? item.user.lastName : "404",
    profilePicture: item.user ? item.user.profilePicture : null,
    mediaUrl: item.mediaUrl,
  }));

  return (
    <div className="flex bg-[#F6F5FA] p-8">
      <div className="flex flex-col flex-1  px-6 py-2 w-1/2 ">
        <div className=" items-center my-3 font-bold text-2xl ">
          <div className="flex mx-auto">
            <p className="mr-3">Submissions </p>
            {info?.length && <p>({info.length})</p>}
          </div>
        </div>

        {!info && (
          <div className="flex justify-center items-center">
            <p className="text-3xl text-red-800">No Submissions yet</p>
          </div>
        )}
        <div className="border  border-transparent rounded-xl bg-[#FFFFFF] h-[41rem] p-3 overflow-hidden overflow-y-auto">
          {info?.map((item, i) => (
            <>
              <div
                key={item.id}
                className={`flex justify-between px-3  space-x-2 text-[#707C97] py-3`}>
                <div className="flex space-x-4">
                  {/* <p className="bg-gray-400 text-black font-bold px-3 h-11 flex items-center border rounded-full">
              {i + 1}
            </p> */}
                  <div>
                    {item.profilePicture ? (
                      <img
                        className="h-11 w-11 border rounded-full"
                        src={item.profilePicture}
                        alt="Profile Image"
                      />
                    ) : (
                      <Avatar />
                    )}
                  </div>

                  <div className="flex flex-col px-3">
                    <p>
                      {item.firstName} {item.lastName}
                    </p>
                    <p>{item.username} </p>
                  </div>
                </div>

                <div className="pr-10">
                  {item.mediaUrl === "..." ? (
                    <div>
                      <p className="text-center text-red-700">No file found</p>
                    </div>
                  ) : (
                    <button
                      className="border border-[#008080] px-3 py-1 rounded-full cursor-pointer flex items-center space-x-2"
                      onClick={() => setSelectedVideo(item.mediaUrl)} // Set the selected video on click
                    >
                      <img src={pauseIcon} className="h-4" alt="" />
                      <p className="text-[#008080]">View Recording</p>
                    </button>
                    
                  )}
                  <button onClick={async ()=>{ await axios.post(`${path}/contest/submissionDelete`, {submissionId:item.id})}}>
                      Delete 
                    </button>
                </div>
              </div>
              {/* <div className=" w-full my-2 border rounded-xl border-[#D8D8D8] h-24 px-4">
                <div className="flex flex-col px-4 mr-8 my-2 space-y-2">
                  <input
                    type="text"
                    placeholder="Write a comment"
                    className="ml-3 border border-transparent"
                  />
                  <div className="flex justify-end">
                    <div className="flex w-1/3">
                      <input
                        type="text"
                        placeholder="Write marks out of 10 "
                        className="pl-2 border rounded-s-full text-[#707C97] bg-[#F6F5FA]"
                      />
                      <button className="border rounded-full bg-[#008080] text-white py-2 px-3 -ml-5">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          ))}
        </div>
      </div>

      <VideoPreview video={selectedVideo} />
    </div>
  );
};

export default SubmissionsList;
