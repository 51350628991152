import { useEffect, useState } from "react";
import axios from "axios";
import { path } from "../../path";
import { useSelector } from "react-redux";

//custom hook to fetch data for Home and Feed pages
export default function useInfinitePostScroll(
  skillQueryArray,
  postType,
  page,
  token,
  id,
  noPosts
) {
  const [postLoader, setPostLoader] = useState(true);
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(false);
  const { user } = useSelector((state) => state.AuthReducer);

  //filter posts for community and friend category
  const filterPosts = (posts) => {
    return posts.filter(
      (post) =>
        post.postType.toLowerCase() === "community" ||
        (post.postType.toLowerCase() === "friend" &&
          (post.userId?._id == user._id ||
            user.friends.includes(post.userId?._id)))
    );
  };

  // console.log(filterPosts(posts))

  useEffect(() => {
    async function infData() {
      try {
        if (skillQueryArray.length <= 0) return;

        setPostLoader(true);
        setError(false);

        const config = {
          host: `${path}`,
          headers: {
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
          },
          token,
        };

        let ptype = [];
        if (postType[0]) ptype.push("blog");
        if (postType[1]) ptype.push("photo");
        if (postType[2]) ptype.push("video");

        let res = await axios.post(
          `${path}/infiniteData`,
          {
            skillType: skillQueryArray,
            postType: ptype,
            page: page,
            userId: id,
          },
          config
        );

        // console.log("infinite api back", res.data); //too much time

        if (Object.keys(res.data).length === 2) {
          if (res.data.posts) {
            const filteredPosts = filterPosts(res.data.posts);
            // console.log(filteredPosts, "from infinite scroll");
            setPosts((prev) => {
              return [...prev, ...filteredPosts];
            });
            setHasMore(true);
          }
        } else {
          setHasMore(false);
        }
        setPostLoader(false);
      } catch (e) {
        setError(true);
        setPostLoader(false);
        console.log("infinite api catched", e);
      }
    }
    infData();

    // console.log(posts)
  }, [page, noPosts, user._id]);

  return { postLoader, error, posts, hasMore, setPosts };

  // return { postLoader, error, posts };
}
