import React from "react";
import { CardContent } from "@mui/material";
import D from "./CompetitionUI/D"
import B from "./CompetitionUI/B"
import E from "./CompetitionUI/E"
import Thumbnail from "./Thumbnail.png"
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { path } from "../../path";
import { useHistory, Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PaymentDetailModal from "../Modals/PaymentDetailModal";
import { WebSocketContext } from "../../WebSocket";
import ParticipateUserList from "./ParticipateUserList";
const CompDetails = ({ contestData }) => {
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [text, setText] = useState("Registration Now")
  const [isUpiModalVisible, setIsUpiModalVisible] = useState(false);
  const socket = useContext(WebSocketContext);
  const [userRegister, setuserRegister] = useState(false);
  const [userSubmission, setuserSubmission] = useState(true);
  const [currentDate, setcurrentDate] = useState(new Date());
  const [RegistrationEnd, setRegistrationEnd] = useState();
  const [SubmissionStart, setSubmissionStart] = useState();
  const [SubmissionEnd, setSubmissionEnd] = useState();
  const [ParticipatedUser, setParticipatedUser] = useState();
  const [PartUser, setPartUser] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const config = {
    host: `${path}`,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${path}`,
      Authorization: `Bearer ${token}`,
    },
  };
  const getcontestdetails = async () => {
    try {
      console.log(id)
      const res = await axios.get(`${path}/contest/getContestsbyId/${id}`)
      // setContestData(res?.data?.data)
      console.log(res?.data?.data?.responses?.sections[3].fields[0].response)
      // RegistrationEnd.current.value = res?.data?.data?.responses?.sections[3].fields[0].response;
      setRegistrationEnd(ConvertToDate(res?.data?.data?.responses?.sections[3].fields[0].response));
      setSubmissionEnd(ConvertToDate(res?.data?.data?.responses?.sections[3].fields[3].response))
      setSubmissionStart(ConvertToDate(res?.data?.data?.responses?.sections[3].fields[2].response))
      // console.log(RegistrationEnd.current.toUTCString())
    } catch (err) { console.log(err) }
  }

  const ConvertToDate = (stringDate) => {
    let dateString = stringDate;
    // Extract date and time components
    let [datePart, timePart] = dateString.split(" at ");

    // Extract year, month, and day
    let [year, month, day] = datePart.split("-");

    // Extract hour and minute
    let [hour, minute] = timePart.split(":");

    // Create a new Date object
    let dateObject = new Date(year, month - 1, day, hour, minute);

    return dateObject
  }

  const Register = async () => {

  }

  const createSumbission = async () => {
    if (userSubmission) return;
    history.push(`/submission/${id}`);
  }

  const checkRegistrationStatus = async () => {
    const res = await axios.get(`${path}/contest/hasUserRegister/${id}/${user._id}`, config)
    // console.log(res)
    if (res?.data?.userRegister) { setuserRegister(true); checkSubmissionStatus() }
  }

  const checkSubmissionStatus = async () => {
    const res = await axios.get(`${path}/contest/hasUserContributed/${id}/${user._id}`, config);
    if (res.data.length === 0) setuserSubmission(false);
  }

  useEffect(() => {
    getcontestdetails();
    checkRegistrationStatus();
    // console.log(contestData.responses)
  }, [])

  useEffect(() => {
    const changeDate = () => {
      setcurrentDate(new Date());
    }
    socket.on("submission-start", changeDate);
    socket.on("submission-stop", changeDate);
    socket.on("registration-end", changeDate);
    socket.on("submission-stop", changeDate);

    return () => {
      socket.off("submission-start", changeDate);
      socket.off("submission-stop", changeDate);
      socket.off("registration-end", changeDate);
      socket.off("submission-stop", changeDate);
    }

  }, [socket])
  const location = useLocation()

  useEffect(() => {

    if (contestData.participatedUser.some((par) => par?._id === user?._id)) {
      setText("Participated")
    }
    else {
      setText("Registration Now")
    }
    setPartUser(true)
  }, [contestData, user, location])

  const razorPay = async (orderOptions) => {
    /** Payment Method using razor pay */

    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorizatison: `Bearer ${token}`,
      },
    };

    const res = await axios.post(`${path}/payment/createOrder`, { orderOptions, config }) //create a if else statement here to check the failure or create order api
    var options = {
      "key": "rzp_live_K4n0QIaK6k64oy", // Enter the Key ID generated from the Dashboard
      "amount": orderOptions.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "Feedants",
      "description": "Contest Registration",
      "image": "https://example.com/your_logo",
      "order_id": res.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      // "callback_url": "http://localhost:4000/payment/validatePayment",
      "handler": async function (response) {
        const options = { ...response };
        const registration = {
          user: user,
          contestId: contestData?._id,
        }
        const validatePayment = await axios.post(`${path}/payment/validatePayment`, { options, registration })
        console.log(validatePayment, "validPayment");
        if (validatePayment.status == 201) {
          alert("Registered Successfully")
          setText("Participated")
        }
        else {
          alert("failed")

        }
      },
      "prefill": {
        "name": "Aryan",
        "email": "aryan@example.com",
        "contact": "9000090000"
      },
      "notes": {
        "address": "Razorpay Corporate Office"
      },
      "theme": {
        "color": "#3399cc"
      }
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      //proper handlling is required
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  }



  const registeruser = async () => {
    try{
    const registerUser = await axios.post(
      `${path}/contest/joinnow`,
      {
        contestId: id,
        user: user,
      },
      config
    ); 
    console.log(registerUser)}
    catch(e){console.log(e)}

  };

  return (

    <CardContent >
      {isUpiModalVisible && <PaymentDetailModal setIsVisible={setIsUpiModalVisible} userId={user._id} displayText={'Please share your UPI ID to receive prize money.'} />}
      {!PartUser && <ParticipateUserList userList={contestData?.participatedUser} closeList={setPartUser} />}
      {contestData != undefined && PartUser &&
        <div className="overflow-y-scroll " style={{ scrollbarWidth: "none" }}>
          {/* <div className="rounded md border border-red-500"> */}
          {contestData?.responses?.sections[1].fields[0].response!=null ? 
          
        <img
          loading="lazy"
          src={contestData?.responses?.sections[1].fields[0].response}
          className="w-full aspect-[1.7] rounded-md"
          alt="hi"
        />:
        <img
        loading="lazy"
        src={Thumbnail}
        className="w-full aspect-[1.7] rounded-md"
        alt="hi"
      />

        }
          {/* </div> */}
          <div className="flex justify-between mt-2 ml-2">

            <b>
              <h1 className="text-xl strong"> {contestData?.responses?.sections[2].fields[0].response}</h1>
            </b>
            <b><h1 onClick={() => setPartUser(false)} className="text-lg strong">Partcipants ({contestData?.participatedUser?.length})</h1></b>

          </div>

          {/* <div className="mb-1"></div> */}
          <p className="text-compPrimary ml-2">
            {contestData?.responses?.sections[0].description} • {" "}
            {contestData?.responses?.sections[2].fields[2]?.response} • {contestData?.responses?.sections[2].fields[3]?.response}
          </p>
          {/* <br></br> */}
          {/* Section_D data */}

          <D fields={contestData?.responses?.sections[4].fields}></D>
          {/* Rules and regulations */}

          <E fields={contestData?.responses?.sections[5].fields}></E>
          <div className="flex flex-row w-[60%]">

            {/* <button className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto" onClick={registeruser}>
              <p className="whitespace-nowrap">{text}</p>
            </button> */}
            <div className="p-2">
              {userRegister === true ? (
                <>
                  {currentDate < SubmissionStart ? <button className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center whitespace-nowrap rounded-full w-auto">
                    Registered
                  </button> : (
                    <>
                      {currentDate < SubmissionEnd ? <button onClick={createSumbission} className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap">
                        {userSubmission ? "Already Submitted" : "Create Submission"}
                      </button>
                        :
                        <button className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap">
                          Submission Ended
                        </button>}
                    </>)}
                </>
              ) : (
                <>
                  {currentDate < RegistrationEnd ? (
                    <>
                      <button onClick={registeruser} className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap">
                        {text}
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="bg-[#008080]  mx-2 hover:bg-green-600 text-white font-bold mt-2 mb-1 py-2 px-4 inline-flex items-center rounded-full w-auto whitespace-nowrap" disabled>
                        Registration Closed
                      </button>
                    </>
                  )}
                </>
              )}
            </div>

            {/* <Badge className="ml-4" variant="secondary"> */}
            <p className="text-[#008080] whitespace-nowrap mt-6 mx-2"> &#8377; {contestData?.responses?.sections[2].fields[1].response}</p>
          </div>
          {/* </Badge> */}
          {/* <div className="mb-1"></div> */}
        </div>
      }
    </CardContent>
  )
}
export default CompDetails