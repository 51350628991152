import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import FriendRequest from "../../Components/FriendRequest/FriendRequest";
import { Helmet } from "react-helmet";
import {
  REDIRECT_FALSE,
  REMOVE_MESSAGE,
  REPORTED_POSTS,
} from "../../store/types/postTypes";
import { path } from "../../path";
import Skillmodel from "../../Components/SelectSkills/Skillmodel";
import { useHistory } from "react-router-dom";
import PhotoPosts from "../../Components/Feed/PhotoPosts";
import VideoPost from "../../Components/Feed/VideoPost";
import BlogPosts from "../../Components/Feed/BlogPosts";
import UserComps from "../../Components/SimillarUsers/UserComps";

import SimilarUser from "../../Components/SimillarUsers/SimilarUser";
import MobileFooter from "../../Components/footer/MobileFooter";
import ProffesionalSkillFilter from "../../Components/PostsFilter/ProffesionalSkillFilter";
import "../HomePage/HomePage.css";
import Admin from "../../Components/Admin/Admin";
import CompCard from "../../Components/competition/CompCard";
import Competition from "../../Components/competition/Competition"
import FeedbackModal from "../../Components/FeedbackForm/FeedbackModal";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import useInfinitePostScroll from "../../Components/Infinite Scrolling/useInfinitePostScroll";
import { getPostId } from "../../store/asyncMethods/SearchPostMethod";
import { UPDATE_ACCOUNT } from "./../../store/types/AuthTypes";

import Skeleton from "@mui/material/Skeleton";
import { sendPageView } from "../../analytics/ga";
import Footer from "../../Components/footer/Footer";

const HomePage = () => {
  const history = useHistory();
  const { user, token, logTime, feedFilled, tempSkills } = useSelector(
    (state) => state.AuthReducer
  );
  const { loading, redirect, message, newPost, newTempPosts, ReportedPosts } =
    useSelector((state) => state.PostReducer);

  const [friendRequests, setFriendRequests] = useState([]);
  const getAllFriendRequests = async () => {
    const res = await axios.get(`${path}/friendRequests/${user._id}`, config);
    setFriendRequests(res.data.friendrequests);
  };

  useEffect(() => {
    getAllFriendRequests();
  }, []);

  // Applied Use Memo on ALL SKILLS (before : 21 , After : 2)
  const initialSkills = () => {
    return user?.professionalSkills
      ?.map((skill) => {
        return { name: skill, type: "Polished" };
      })
      .concat(
        user?.unprofessionalSkills?.map((skill) => {
          return { name: skill, type: "Novice" };
        })
      );
  };

  const { allSkills, setAllSkills } = useState(initialSkills);

  const { postLoading } = useSelector((state) => state.NotificationReducer);
  const dispatch = useDispatch();
  const { homePageData } = useSelector((state) => state.FetchPosts);
  const { sharepostId } = useSelector((state) => state.SearchPostReducer);
  const [similarSkillsUsers, setSimilarSkillsUsers] = useState([]);
  const [filterSidebar, setfilterSidebar] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [skillQueryArray, setSkillQueryArray] = useState(initialSkills);
  const [postType, setPostType] = useState(new Array(3).fill(true));
  const [noPosts, setNoPosts] = useState(false);
  const [redirectModal, setRedirectModal] = useState(false);

  useEffect(() => {
    let currentSkills = user?.professionalSkills
      ?.map((skill) => {
        return { name: skill, type: "Polished" };
      })
      .concat(
        user?.unprofessionalSkills?.map((skill) => {
          return { name: skill, type: "Novice" };
        })
      );
    setSkillQueryArray([...currentSkills]);
  }, [user?.skills]);

  useEffect(() => {
    if (!loading && user?.skills.length === 0) {
      setRedirectModal(true);
      console.log(loading);
    }
  }, [loading, user]);

  // Infinite scrolling logic starts
  const [page, setPage] = useState(1);
  const observer = useRef();

  const skillQueryArrayRef = useRef(skillQueryArray);
  skillQueryArrayRef.current = skillQueryArray;

  const { posts, hasMore, postLoader, error, setPosts } = useInfinitePostScroll(
    skillQueryArrayRef.current,
    postType,
    page,
    token,
    user._id,
    noPosts
  );

  const lastPostRef = useCallback(
    (node) => {
      if (postLoader) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prev) => prev + 1);
        }
      });
      setTimeout(() => {
        if (node) observer.current.observe(node);
      }, 3000);
    },
    [postLoader, hasMore]
  );

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (ReportedPosts) {
      setPosts((prev) => prev.filter((post) => post._id != ReportedPosts));
      dispatch({ type: REPORTED_POSTS, payload: null });
    }
  }, [ReportedPosts]);

  const handlePostTypeChange = useCallback(
    (value) => {
      setPostType(value);
      setPosts([]);
      if (page === 1) setNoPosts((prev) => !prev);
      else setPage(1);
    },
    [page]
  );

  useEffect(() => {
    if (user?.isMarketingAdmin) {
      history.push("/marketingadmin");
    }
  }, []);
  const handleSkillQuerryChange = useCallback(
    (value) => {
      if (value.length === 0) setSkillQueryArray([...allSkills]);
      else setSkillQueryArray(value);
      setPosts([]);
      if (page === 1) setNoPosts((prev) => !prev);
      else setPage(1);
    },
    [page]
  );

  // ends

  useEffect(() => {
    if (sharepostId !== "") {
      dispatch(getPostId(""));
      history.push(`post/${sharepostId}`);
    }
  }, [sharepostId]);

  useEffect(() => {
    if (typeof localStorage.getItem("currPost") !== "undefined") {
      localStorage.removeItem("currPost");
    }
    if (redirect) {
      dispatch({ type: REDIRECT_FALSE });
    }
    if (message) {
      alert(message);
      dispatch({ type: REMOVE_MESSAGE });
    }
    if (newPost) {
      homePageData.unshift(newPost);
      dispatch({ type: "UNSET_NEW_POST" });
    }
  }, [message, redirect, loading, newPost]);

  //handling left sidebar
  //finding all the users having similar skillset
  const config = {
    host: `${path}`,
    headers: {
      "Access-Control-Allow-Origin": `${path}`,
      Authorization: `Bearer ${token}`,
    },
    token,
  };
  useEffect(() => {
    console.log("HOME");
    const updateUser = async () => {
      try {
        const res = await axios.get(`${path}/getUserData/${user._id}`, config);
        dispatch({ type: UPDATE_ACCOUNT, payload: res.data });
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    updateUser();

    dispatch({ type: "SET_HEADER" });

    const findSimilarSkillsetUsers = async () => {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
        token,
      };
      const res = await axios.get(
        `${path}/allSimilarSkillsUsers/${user._id}`,
        config
      );
      setSimilarSkillsUsers(res.data.data);
    };
    user.isAdmin === false &&
      !user.isMarketingAdmin &&
      findSimilarSkillsetUsers();
    return () => {
      dispatch({ type: "CLEAR_NEW_TEMP_POSTS" });
    };
  }, []);

  const ref = useRef(null);

  const filterHamburgerMenu = (e) => {
    e.preventDefault();
    if (filterSidebar) {
      setfilterSidebar(false);
    } else {
      setfilterSidebar(true);
    }
  };

  function closeSidebarHandler(e) {
    if (
      filterSidebar &&
      document.getElementById("filterSidebar")?.contains(e.target) === false
    ) {
      setfilterSidebar(false);
    }
  }

  const [currTime, setCurrTime] = useState(new Date().getMinutes());

  setInterval(() => {
    setCurrTime(new Date().getMinutes());
  }, 60000);

  const logTimeRef = useRef(new Date().getMinutes());
  useEffect(() => {
    // console.log(currTime - logTimeRef.current)
    if (
      user.feedbackFilled === false &&
      feedFilled === false &&
      currTime - logTimeRef.current >= 30
    ) {
      console.log("Feeding time");
      setFeedbackModalOpen(true);
      logTimeRef.current = currTime;
    }
  }, [currTime, user.feedbackFilled, feedFilled]);

  useEffect(() => {
    if (newTempPosts?.length > 0)
      setPosts((prev) => [...newTempPosts, ...prev]);
  }, [newTempPosts]);

  // for model background color
  const [close, setClose] = useState(false);
  const modalBackgroundClass =
    user?.theme === "light" ? "bg-[#0000004f]" : "bg-[#ffffff20]";

  return (
    <div className="relative h-[100vh] pt-[37px] sm:pt-[61px]">
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Upload Video" />
      </Helmet>
      {redirectModal && (
        <div
          className={`z-50 fixed flex flex-col justify-center w-[100vw] h-[100vh] items-center ${close ? "" : modalBackgroundClass
            }`}>
          <Skillmodel setClose={setClose}></Skillmodel>
        </div>
      )}
      {user.isAdmin ? (
        <Admin />
      ) : (
        <div
          onClick={closeSidebarHandler}
          ref={ref}
          id="home-Page"
          className={`scrollbar h-full overflow-auto bg-page  opacity-${feedbackModalOpen === true ? 10 : null
            }`}>
          <MobileFooter />
          {/* {redirectModal && (
                <div className="z-50 absolute flex flex-col justify-center  w-[100vw] h-[100vh] bg-transparent">
                  <Skillmodel></Skillmodel>
                </div>
              )} */}
          {/* filter */}
          <div>
            <div
              id="filterSidebar"
              className={`w-72 p-2 shadow-xl  bg-card text-primary sm:hidden transition-all fixed h-[100vh] top-0 ${filterSidebar ? "left-0" : "-left-72"
                } z-20`}>
              <div className="flex justify-between p-2 border-b mt-7 sm:hidden">
                <div className="flex divide-y">
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2">
                    <path
                      d="M7.97044 17.75V12.25H9.34029V14.25H16.6462V15.75H9.34029V17.75H7.97044ZM0.664551 15.75V14.25H5.68735V15.75H0.664551ZM4.31749 11.75V9.75H0.664551V8.25H4.31749V6.25H5.68735V11.75H4.31749ZM7.97044 9.75V8.25H16.6462V9.75H7.97044ZM11.6234 5.75V0.25H12.9932V2.25H16.6462V3.75H12.9932V5.75H11.6234ZM0.664551 3.75V2.25H9.34029V3.75H0.664551Z"
                      fill="#637381"
                    />
                  </svg>
                  <p
                    className="text-[#637381] text-sm"
                    style={{ borderTopWidth: 0 }}>
                    FILTERS
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    filterHamburgerMenu(e);
                  }}>
                  <svg
                    width="21"
                    height="16"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.45801 11.9754L12.8687 8.56468L16.2794 11.9754L17.0832 11.2032L13.6409 7.79251L17.0515 4.38183L16.2794 3.60966L12.8687 7.02034L9.45801 3.60966L8.68584 4.38183L12.0965 7.79251L8.68584 11.2032L9.45801 11.9754ZM0.610474 7.79251L5.17941 1.32558C5.4368 0.939494 5.7745 0.633971 6.1925 0.409012C6.61102 0.183538 7.05605 0.0708008 7.52758 0.0708008H19.0136C19.5284 0.0708008 19.9683 0.258438 20.3333 0.633714C20.6977 1.00899 20.88 1.44346 20.88 1.93714V13.6479C20.88 14.1416 20.6977 14.576 20.3333 14.9513C19.9683 15.3266 19.5284 15.5142 19.0136 15.5142H7.52758C7.03442 15.5142 6.5894 15.3963 6.1925 15.1606C5.7956 14.9243 5.45791 14.6026 5.17941 14.1954L0.610474 7.79251ZM1.92934 7.79251L6.17628 13.7768C6.3266 13.9699 6.50909 14.1253 6.72375 14.2432C6.9379 14.3611 7.17367 14.4201 7.43106 14.4201H19.0136C19.2067 14.4201 19.3892 14.3398 19.5611 14.1791C19.7325 14.018 19.8182 13.8409 19.8182 13.6479V1.93714C19.8182 1.7441 19.7325 1.56701 19.5611 1.40589C19.3892 1.24527 19.2067 1.16497 19.0136 1.16497H7.43106C7.17367 1.16497 6.9325 1.22391 6.70754 1.34179C6.48206 1.45968 6.30498 1.61514 6.17628 1.80819L1.92934 7.79251Z"
                      fill="#637381"
                    />
                  </svg>
                </div>
              </div>
              <ProffesionalSkillFilter
                postLoader={postLoader}
                skillQueryArray={skillQueryArray}
                setSkillQueryArray={handleSkillQuerryChange}
                postType={postType}
                setPostType={handlePostTypeChange}
              />
            </div>
            <div
              className={`fixed w-[100vw] h-[100vh] bg-[black] opacity-30  ${filterSidebar ? "z-10" : "-z-10"
                }`}></div>
          </div>

          <div
            id="home-content"
            className="flex h-full bg-page overflow-auto scrollbar flex-row justify-center w-[100%] lg:mx-auto  overflow-x-hidden">
            <div className="hidden fixed trial w-[27%] sm:mt-1 left-0 xl:left-[0.4%] lg:justify-start md:flex-2 scrollbar-leftsec sm:flex lg:flex-1 flex-row-reverse home-side-left heightOfLeftAndRightSec overflow-y-scroll">
              <div className="w-[80%] flex-col mb-0 md:flex pop">  {/* width change 80 to 70 */}
                <FriendRequest />
                <ProffesionalSkillFilter
                  postLoader={postLoader}
                  skillQueryArray={skillQueryArray}
                  setSkillQueryArray={handleSkillQuerryChange}
                  postType={postType}
                  setPostType={handlePostTypeChange}
                />
              </div>
            </div>

            <div className="spacer"></div>

            <div className="flex test sm:mt-1 md:mx-auto marginCorrector flex-col w-[100%] sm:w-[65%] sm:ml-[25%] md:w-[42%] xl:w-[42%] posts">
              {Object.keys(newTempPosts).length > 0 &&
                (newTempPosts.type === "photo" ? (
                  <PhotoPosts key={newTempPosts._id} post={newTempPosts} />
                ) : newTempPosts.type === "video" ? (
                  <VideoPost key={newTempPosts._id} post={newTempPosts} />
                ) : (
                  <BlogPosts key={newTempPosts._id} post={newTempPosts} />
                ))}

              {posts?.length > 0
                ? posts?.map((p, index) => {
                  if (posts.length === index + 1 && p) {
                    if (p.type === "video" && p.userId !== null) {
                      return (
                        <VideoPost ref={lastPostRef} key={p._id} post={p} />
                      );
                    } else if (p.type === "photo" && p.userId !== null) {
                      return (
                        <PhotoPosts
                          className=""
                          ref={lastPostRef}
                          key={p._id}
                          post={p}
                        />
                      );
                    } else if (p.type === "blog" && p.userId !== null) {
                      return (
                        <BlogPosts ref={lastPostRef} key={p._id} post={p} />
                      );
                    }
                  } else if (p) {
                    if (p.type === "video" && p.userId !== null) {
                      return <VideoPost key={p._id} post={p} />;
                    } else if (p.type === "photo" && p.userId !== null) {
                      return <PhotoPosts className="" key={p._id} post={p} />;
                    } else if (p.type === "blog" && p.userId !== null) {
                      return <BlogPosts key={p._id} post={p} />;
                    }
                  }
                })
                : !postLoader &&
                !postLoading && (
                  <div className="flex items-center justify-center mt-4">
                    <div>
                      <div className="max-w-sm overflow-hidden border rounded-lg shadow-lg bg-card border-viewMore">
                        <div className="px-6 py-4">
                          <div className="mb-2 text-xl font-bold text-primary">
                            No post found
                          </div>
                          <p className="text-base text-message">
                            The skill you entered have not featured in any of
                            the posts.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {(postLoader || postLoading) && (
                <div className="w-[100%] flex flex-col gap-5">
                  <div className="w-[100%] h-full flex flex-col gap-3 items-center">
                    <div className="flex items-center justify-start w-full gap-3">
                      <Skeleton
                        sx={{
                          bgcolor: `${user.theme === "dark"
                              ? "rgba(255, 255, 255, 0.2)"
                              : ""
                            }`,
                        }}
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <div className="w-[80%] flex flex-col gap-1">
                        <Skeleton
                          sx={{
                            bgcolor: `${user.theme === "dark"
                                ? "rgba(255, 255, 255, 0.2)"
                                : ""
                              }`,
                          }}
                          variant="rounded"
                          width={"60%"}
                          height={10}
                        />
                        <Skeleton
                          sx={{
                            bgcolor: `${user.theme === "dark"
                                ? "rgba(255, 255, 255, 0.2)"
                                : ""
                              }`,
                          }}
                          variant="rounded"
                          width={"60%"}
                          height={10}
                        />
                      </div>
                    </div>
                    <Skeleton
                      sx={{
                        bgcolor: `${user.theme === "dark"
                            ? "rgba(255, 255, 255, 0.2)"
                            : ""
                          }`,
                      }}
                      variant="rounded"
                      width={"100%"}
                      height={"60vh"}
                    />
                  </div>
                  <div className="w-[100%] h-full flex flex-col gap-3 items-center">
                    <div className="flex items-center justify-start w-full gap-3">
                      <Skeleton
                        sx={{
                          bgcolor: `${user.theme === "dark"
                              ? "rgba(255, 255, 255, 0.2)"
                              : ""
                            }`,
                        }}
                        variant="circular"
                        width={40}
                        height={40}
                      />
                      <div className="w-[80%] flex flex-col gap-1">
                        <Skeleton
                          sx={{
                            bgcolor: `${user.theme === "dark"
                                ? "rgba(255, 255, 255, 0.2)"
                                : ""
                              }`,
                          }}
                          variant="rounded"
                          width={"60%"}
                          height={10}
                        />
                        <Skeleton
                          sx={{
                            bgcolor: `${user.theme === "dark"
                                ? "rgba(255, 255, 255, 0.2)"
                                : ""
                              }`,
                          }}
                          variant="rounded"
                          width={"60%"}
                          height={10}
                        />
                      </div>
                    </div>
                    <Skeleton
                      sx={{
                        bgcolor: `${user.theme === "dark"
                            ? "rgba(255, 255, 255, 0.2)"
                            : ""
                          }`,
                      }}
                      variant="rounded"
                      width={"100%"}
                      height={"60vh"}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="flex border h-[88vh] md:flex-1 flex-col lg:flex-[1.5] w-[100%] md:w-[45%] lg:w-[45%] xl:w-[45%] posts"></div> */}
            <div className="overflow-y-scroll h-[88vh] sm:mt-1 scrollbar-leftsec hidden fixed right-[7%] w-[21%] lg:justify-start items-start md:flex-2 md:flex lg:flex-1 flex-col  RightSideHome ">
              <div className="w-[100%] md:flex flex-col">
                <SimilarUser />
                <div className="">
                  <h1 className="text-xl text-primary font-gilroy-semibold  my-2">Competitions</h1>
                </div>
                <UserComps />


                <div className="mt-2">
                  <Footer />
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
      <div className="sm:hidden fixed bottom-[10%] right-[1.6rem] z-20 cursor-pointer scale-150">
        <div className="relative">
          <TuneRoundedIcon
            className="bg-[#008080] text-white rounded-full p-1 tune"
            sx={{ fontWeight: "bold", width: "20px" }}
            onClick={(e) => {
              filterHamburgerMenu(e);
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        {feedbackModalOpen && (
          <FeedbackModal setFeedbackModalOpen={setFeedbackModalOpen} />
        )}
      </div>
      {/* {redirectModal && <RedirectToSkills />} */}
    </div>
  );
};
export default React.memo(HomePage);
