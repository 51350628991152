import React from 'react'

function E({ fields }) {
    return (
        <>
            {fields?.map((field) => {
                return <div className="flex text-primary flex-col p-3 ">
                    <h1 className="font-gilroy-medium text-compPrimary text-base">{field.title}</h1>
                    <ul className="font-gilroy-regular pl-3 text-compPrimary text-xs leading-4 list-decimal">
                        {field.response.map((text, index) => {
                            return <li key={index} className="font-gilroy-regular text-xs leading-4 pt-2 md:pt-4">{text}</li>
                        })}
                    </ul>
                </div>
            })}
        </>
    )
}

export default E