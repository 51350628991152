import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { RESET_UPDATE_IMAGE_ERRORS } from "../../store/types/postTypes";
import { useSelector, useDispatch } from "react-redux";
import { updateImageAction } from "../../store/asyncMethods/PostMethods";
import { CLOSE_LOADER } from "../../store/types/AuthTypes";
const EditImage = () => {
  const post = JSON.parse(localStorage.getItem("currPost"));
  // console.log("RAJ: "+post);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { updateImageErrors, loading } = useSelector(
    (state) => state.UpdateImage
  );
  const { user } = useSelector((state) => state.AuthReducer);
  const { redirect } = useSelector((state) => state.PostReducer);
  const [state, setState] = useState({
    title: post.imageCaption,
    image: post.image,
    category: post.category,
  });
  useEffect(() => {
    if (loading) {
      dispatch({ type: CLOSE_LOADER });
    }
    if (redirect) {
      push("/home");
    }
  }, [redirect, loading]);

  useEffect(() => {
    if (updateImageErrors.length !== 0) {
      updateImageErrors.map((err) => alert(err.msg));
      dispatch({ type: RESET_UPDATE_IMAGE_ERRORS });
    }
  }, [updateImageErrors]);

  const updateImage = (e) => {
    console.log("s", state.title);
    // e.preventDefault();
    // const formData = new FormData();
    // formData.append("id", post._id);
    // formData.append("title", state.title);
    // formData.append("category", state.category);
    dispatch(
      updateImageAction({
        id: post._id,
        caption: state.title,
        category: state.category,
      })
    );
  };
  const inputHandle = (e) => {
    // console.log(e.target/.value);
    setState({ ...state, [e.target.name]: e.target.value });
  };
  return (
    <div className="h-[100vh] bg-[#354064] flex justify-center items-center text-[#354064] px-4 z-20">
      <div className="flex">
        <div
          className="bg-card
             py-2 px-8">
          <div>
            <h3 className="text-center font-bold text-xl">Update Post</h3>
            <Toaster
              position="top-right"
              reverseOrder={false}
              toastOptions={{ style: { marginTop: "50px" } }}
            />

            <form onSubmit={(e) => updateImage(e)}>
              <div>
                <label htmlFor="caption" className="text-base">
                  Image Caption
                </label>
                <input
                  type="text"
                  name="title"
                  id="caption"
                  className="bg-[#354064] text-white p-2 outline-none"
                  onChange={inputHandle}
                  value={state.title}
                />
              </div>
              <div>
                <label htmlFor="currentImage" className="text-base">
                  Current Image
                </label>
                <img src={state.image} alt="" id="currentImage" />
              </div>
              <div>
                <label htmlFor="setCategory" className="text-base">
                  Select Category
                </label>
                <select
                  name="category"
                  value={state.category}
                  className="text-white p-2 w-[52vw] md:w-[18vw]"
                  style={{ background: "#354064", outline: "none" }}
                  onChange={inputHandle}
                  id="setCategory">
                  <option>{post.category}</option>
                  {user.skills.map((skill) =>
                    skill !== post.category ? (
                      <option value={skill}>{skill}</option>
                    ) : (
                      ""
                    )
                  )}
                </select>
              </div>
              <div>
                <input
                  type="submit"
                  value="Updatess Post"
                  className="text-white bg-[#4950F6] rounded-lg px-4 py-2 mt-4"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditImage;
