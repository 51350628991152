import axios from "axios";
import React, { useEffect, useState } from "react";
import { path } from "../../../path";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useSelector } from "react-redux";

function CloseAccounts() {
  const { token, user } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = useState(false);
  const [closeAccounts, setcloseAccounts] = useState([]);

  useEffect(() => {
    const getAllClosedAccount = async () => {
      try {
        const config = {
          host: `${path}`,
          headers: {
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
          },
        };
        setLoading(true);
        const res = await axios.get(`${path}/admin/allclosedaccounts`, config);
        console.log(res);
        setcloseAccounts(res.data);
        setLoading(false);
      } catch (error) {
        alert("Some error in getting close accounts.");
      }
    };

    getAllClosedAccount();
  }, [token]);

  const deleteAccountHandler = async (
    closeAccountId,
    userId,
    userEmail,
    delPost
  ) => {
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      const currPassword = prompt("Enter Admin password");
      const res = await axios.post(
        `${path}/admin/deleteclosedaccount`,
        {
          closeAccountId: closeAccountId,
          userId: userId,
          userEmail: userEmail,
          currPassword: currPassword,
          AdminId: user._id,
          delPost: delPost,
        },
        config
      );
      console.log(res);
      res?.data.success === true
        ? alert("Account deleted successfully.")
        : alert(res.data.msg);

      if (res?.data.success == true) {
        setcloseAccounts(
          closeAccounts.filter((cs) => cs._id !== closeAccountId)
        );
      }
    } catch (error) {
      alert("Error in deleting account.");
      console.log(error);
    }
  };

  const rejectCloseReq = async (closeAccountId) => {
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `${path}/admin/rejectclosedaccount`,
        {
          closeAccountId: closeAccountId,
        },
        config
      );
      res.data.success === true
        ? alert("Request rejected successfully.")
        : alert("Error in rejecting request.");
      setcloseAccounts(closeAccounts.filter((cs) => cs._id !== closeAccountId));
    } catch (error) {
      alert("Some error in rejecting request.");
    }
  };

  return (
    <div>
      <h1 className="text-center font-bold text-[2rem] my-2">
        Account Closing Requests
      </h1>
      {loading === true ? (
        <p className="text-primary">Loading...</p>
      ) : closeAccounts.length > 0 ? (
        <table className="m-auto border">
          <thead>
            <tr className="border-b">
              <th className="px-2 border-r">S.No</th>
              <th className="px-2 border-r">Username</th>
              <th className="px-2 border-r">Reason</th>
              <th className="px-2 border-r">Comment</th>
              <th className="px-2 border-r">Request Time</th>
              <th className="px-2 border-r">Action</th>
            </tr>
          </thead>
          <tbody>
            {closeAccounts.map((cs, index) => (
              <tr className="border-b">
                <td className="px-2 border-r">{index + 1}</td>
                <td className="px-2 border-r">{cs?.username}</td>
                <td className="px-2 border-r">{cs?.reason}</td>
                <td className="px-2 border-r">{cs?.comment}</td>
                <td className="px-2 border-r">{cs?.createdAt.slice(0, 10)}</td>
                <td className="flex px-2 space-x-2">
                  <CheckCircleRoundedIcon
                    onClick={() =>
                      deleteAccountHandler(
                        cs?._id,
                        cs?.userId?._id,
                        cs?.userId?.email,
                        cs?.delPost
                      )
                    }
                    sx={{ cursor: "pointer", color: "#008080" }}
                  />
                  <CancelRoundedIcon
                    onClick={() => rejectCloseReq(cs._id)}
                    sx={{ cursor: "pointer", color: "red" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No new data</p>
      )}
    </div>
  );
}

export default CloseAccounts;
