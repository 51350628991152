import axios from "axios";
import {
  SET_POST,
  POST_LOADER,
  CLOSE_POST_LOADER,
  SET_SHARE_POST,
  UNSET_POST,
  SET_SHARE_POST_ID,
} from "../types/SearchPostTypes";
import { path } from "./../../path";

// Fetch all the posts created by a user using his ID.
export const FetchPosts = (id) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token, user },
    } = getState();
    // validating a user using token
    const config = {
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: POST_LOADER });
    try {
      const response = await axios.get(`${path}/posts/${id}`, config);
      dispatch({ type: POST_LOADER });
      const { data } = response;
      // const {posts} = data;
      // console.log(posts,"hr");
      // console.log(data,"hr");
      dispatch({ type: CLOSE_POST_LOADER });
      dispatch({ type: SET_POST, payload: data.data });
    } catch (error) {
      dispatch({ type: CLOSE_POST_LOADER });
      console.log(error);
    }
  };
};

export const fetchPost = (id) => {
  return async (dispatch, getState) => {
    const {
      AuthReducer: { token, user },
    } = getState();
    const { FetchPost } = getState();
    // console.log("user",id)/
    // const {_id} = user
    // validating a user using token
    const config = {
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
      token,
    };
    try {
      dispatch({ type: POST_LOADER });
      const response = await axios.get(`${path}/post/${id}`, config);
      console.log("Resp: ", response.data);
      const post = response.data.post;
      if (post === null) dispatch({ type: SET_SHARE_POST, payload: {} });
      else dispatch({ type: SET_SHARE_POST, payload: post });
      dispatch({ type: CLOSE_POST_LOADER });
    } catch (error) {
      dispatch({ type: CLOSE_POST_LOADER });
      console.log(error.message);
    }
  };
};

export const getPostId = (id) => {
  return (dispatch) => {
    dispatch({ type: SET_SHARE_POST_ID, payload: id });
  };
};
