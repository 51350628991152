import React, { useEffect, useContext } from "react";
import axios from "axios";
import { path } from "../../path";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../WebSocket";

const SocketNotification = () => {
    const { user, token } = useSelector(
        (state) => state.AuthReducer
    );

    const dispatch = useDispatch();

    const config = {
        headers: {
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
        },
    };

    // useEffect(() => {
    //     // Fetch notifications when the component mounts
    //     dispatch(getNotification()); // Assuming this dispatches the async action that updates the store
    //   }, [dispatch]);

    const getNotification = async () => {
        try {
            const response = await axios.get(
                `${path}/getNotification/${user._id}`,
                config
            );
            // console.log("data full")
            // console.log(response.data);
            let filteredNoti = response.data.data.filter((noti) =>
                noti.fromUserId !== null &&
                noti.type !== "Not Improve" &&
                noti.type !== "Unappreciate" &&
                noti.postId !== null
            );

            // console.log(filteredNoti)
            // filteredNoti = filteredNoti.map((noti)=>{
            //     return(
            //       {...noti, postId: {...noti.postId, body: ""}}
            //     )
            //   })

            // filteredNoti = [filteredNoti[5]]
            dispatch({ type: "UPDATE_NOTIFICATION", payload: filteredNoti });
            // console.log("hello 5")

        } catch (error) {
            console.log(error);
        }
    };

    const socket = useContext(WebSocketContext);

    useEffect(() => {
        getNotification();
        // if (window.location.hash !== "#/allNotifications") {
        socket.on("notification received", (payload) => {
            console.log("Socket page");
            getNotification();

        });
        socket.on("connected", () => {
            // setSocketConnected(true);
            // console.log("Socket page");

            console.log("Socket connected")
        });
        socket.on("profile update", (payload) => {
            getNotification();
        });

        socket.on("contest-registered", (payload) => {
            getNotification();
        });

        socket.on("contest-submitted", (payload) => {
            getNotification();
        });

        socket.on("contest-received",(data)=>{
            console.log(data,"Mai header hu",);
            getNotification();
          })
          
          socket.on("result-received",(data)=>{
            console.log(data,"Mai result declared by new api",);
            getNotification();
          })

          socket.on("result-created",(data)=>{
            console.log(data,"Mai result declare by new socket",);
            getNotification();
          })

          socket.on("submission-start",(data)=>{
            console.log(data,"Mai submission start by new socket",);
            getNotification();
          })

          socket.on("submission-end",(data)=>{
            console.log("mai contest submission end before hu");
            getNotification();
          })
          
        socket.on("friend received", (payload) => {
            // console.log("Socket page");
            getNotification();

        });

        socket.on("post notification recieved", (data) => {
            getNotification();
        });

        socket.on("name update", (data) => {

            getNotification();

        });

        socket.on("pull cancel", (payload) => {
            // console.log("Socket page");
            getNotification();
        });

        // Added but not tested yet
        socket.on("accept request", (data) => {
            getNotification();
        });

        socket.on("removed friend", (remover) => {
            getNotification();
        });
        // }

        socket.on("uni-message-socket-recieved", (data) => {
            getNotification();
        });

        socket.on("contest-created",(data)=>{
            console.log("new contest");
            getNotification();
          })
          
    }, []);

    return (
        <div></div>
    )
}

export default SocketNotification
