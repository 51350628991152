import React, { useState, useEffect } from 'react';

const Timer = ({ timeLimit, defaultText }) => {
    const [seconds, setSeconds] = useState(timeLimit ? timeLimit / 1000 : 0);
    const [humanTime, setHumanTime] = useState(defaultText || '0:00');

    useEffect(() => {
        const timer = setInterval(() => {
            setSeconds(prevSeconds => {
                const nextSeconds = timeLimit ? prevSeconds - 1 : prevSeconds + 1;
                setHumanTime(getHumanTime(nextSeconds));
                return nextSeconds;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLimit]);

    const pad = unit => {
        var str = '' + unit;
        var pad = '00';
        return pad.substring(0, pad.length - str.length) + str;
    };

    const getHumanTime = seconds => {
        const minutes = Math.floor(seconds / 60);
        return minutes !== 0 ? `${minutes}:${pad(seconds - minutes * 60)}` : `${seconds - minutes * 60}s`;
    };

    return (
        <div className="absolute bg-black rounded-lg py-2 px-3 top-[20px] right-[20px] ">
            {/* <div className="w-4 h-4 bg-red-500 rounded-full inline-block mr-2"></div> */}
            <p className='font-gilroy-semibold text-sm'>{humanTime}</p>

        </div>
    );
};

export default Timer;
