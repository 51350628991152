import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { path } from "../../../path";
import { Avatar } from "@mui/material";

function UsersCount() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.AuthReducer);
  const [allUsersStats, setAllUsersStats] = useState([]);

  const getAllSkills = async () => {
    const config = {
      host: `${path}`,
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.get(`${path}/admin/getuserstats`, config);
    console.log(res.data);
    setAllUsersStats(res.data.users);
  };

  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
    getAllSkills();
  }, []);

  return (
    <div className="text-primary bg-page min-h-[100vh] px-2  py-[3rem] overflow-x-none overflow-y-auto">
      <h1 className="text-center font-bold">
        User count related to specific skills
      </h1>
      <table className="border-collapse border-2 m-4">
        <thead className="border-b-2">
          <tr>
            <th className="border-r-2 px-2">S.No</th>
            <th className="border-r-2 px-2">Skill</th>
            <th className="border-r-2 px-2">Total users</th>
          </tr>
        </thead>
        <tbody>
          {allUsersStats.map((u, index) => (
            <tr>
              <td className="border-r-2 px-2 text-[0.8rem] text-center">
                {index + 1}
              </td>
              <td className="border-r-2 px-2 text-[0.8rem] text-center">
                {u.name}
              </td>
              <td className="border-r-2 px-2 text-[0.8rem] text-center">
                {u.totalUsers}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UsersCount;
