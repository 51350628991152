import axios from "axios";
import React, { useState } from "react";
import { path } from "../../path";
import logo from "../../Components/LandingPage/logo/Logo-design.png";
import logoDark from "../../Components/LandingPage/logo/Tranparent_dark_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { deleteSkillsFromUser } from "../../store/asyncMethods/PostMethods";
import { useEffect, useRef } from "react";
import {Link, useHistory} from "react-router-dom"
import "./skillmodel.css"
import CloseIcon from "@mui/icons-material/Close";

function Skillmodel({setClose}) {
    const history = useHistory();
    
 
 
  const { token, user } = useSelector((state) => state.AuthReducer);
 
  const consoless = () =>{
    console.log("Hell")
  }
  const handleClose = ()=>{
    const close = document.querySelector(".close");
  if (close) {
    close.style.display = "none";
    setClose(true)
    history.push("/timeline");

  }
  }
 

  return (
  //   <div className="z-100 close self-center h-[100%] w-[100%] flex flex-col justify-center">
  //   <div className="border-2 border-viewMore shadow-lg self-center rounded-lg py-4 px-6 w-[90%] md:w-[60%] xl:w-[30%] flex flex-col justify-between gap-4 bg-primary text-card">
  //     <div className="w-full flex justify-between">
  //       <div></div>
  //       <img src={user.theme === "light" ? logo : logoDark} className="w-[40%]" alt="Logo" />
  //       <button className=" top-0 right-2" onClick={handleClose}>
  //       <CloseIcon className="text-red-500 border-2 border-red-700 cursor-pointer rounded-2xl" />
  //       </button>
  //     </div>
  //     <h3 className="font-semibold text-lg text-center">
  //       Fill your skills to get started 🔰
  //     </h3>
  //     <div className="button-container">

  //     <Link to="/editPSkills" ><button className="Polished">Polished Skills</button></Link>
  //     <Link to="/editUSkills">
  //       <button className="Novice">Novice Skills</button>
  //     </Link>
  //     </div>
  //     <ToastContainer />
  //   </div>
  // </div>

  <div className="z-100 close self-center flex flex-col justify-center">
  <div className="border-2 border-viewMore shadow-lg self-center rounded-lg py-4 px-6 w-full md:w-[70%] xl:w-[40%] flex flex-col justify-between gap-4 bg-primary text-card">
    <div className="w-full flex justify-between">
      <div></div>
      <img src={user.theme === "light" ? logo : logoDark} className="w-[40%] md:w-[30%]" alt="Logo" />
      <button className="top-0 right-2 h-0" onClick={handleClose}>
        <CloseIcon className="text-red-500 border-2 border-red-700 cursor-pointer rounded-2xl" />
      </button>
    </div>
    <h3 className="font-semibold text-lg text-center">
      Fill your skills to get started 🔰
    </h3>
    <div className="button-container">
      <Link to="/editPSkills">
        <button className="Polished">Polished Skills</button>
      </Link>
      <Link to="/editUSkills">
        <button className="Novice">Novice Skills</button>
      </Link>
    </div>
    <ToastContainer />
  </div>
</div>

  );
}

export default Skillmodel;