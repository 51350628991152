import { lazy, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { Link, useParams, useHistory } from "react-router-dom";

import {
  REDIRECT_FALSE,
  REMOVE_MESSAGE,
  SET_LOADER,
  CLOSE_LOADER,
  SET_MESSAGE,
  POST_RESET,
  RESET_UPDATE_ERRORS,
} from "../../store/types/postTypes";
// import ReactQuill from "react-quill";

import {
  fetchPosts,
  fetchPost,
  updateAction,
  updatePost,
} from "../../store/asyncMethods/PostMethods";
import { BsPencil, BsArchive, BsImage } from "react-icons/bs";
import axios from "axios";

const moment = lazy(() => import("moment"));

const Edit = () => {
  const post = JSON.parse(localStorage.getItem("currPost"));
  if (!post) {
    window.location.reload();
  }
  // console.log(post);
  const { user } = useSelector((state) => state.AuthReducer);
  const { push } = useHistory();
  const { editErrors } = useSelector((state) => state.updatePost);
  const [state, setState] = useState({
    title: post.title,
    body: post.blog,
    category: post.category,
  });
  // console.log(state.body);
  const dispatch = useDispatch();
  const { loading, redirect } = useSelector((state) => state.PostReducer);
  useEffect(() => {
    if (editErrors.length !== 0) {
      editErrors.map((error) => alert(error.msg));
      dispatch({ type: RESET_UPDATE_ERRORS });
    }
  }, [editErrors]);
  useEffect(() => {
    if (redirect) {
      push("/home");
      dispatch({ type: REDIRECT_FALSE });
      localStorage.removeItem("currPost");
    }
  }, [redirect]);

  const handleChange = (e) => {
    // console.log(e.target.value);
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };
  const updatePost = (e) => {
    e.preventDefault();
    dispatch(
      updateAction({
        title: state.title,
        body: state.body,
        category: state.category,
        id: post._id,
      })
    );
  };
  return (
    <>
      <div className="h-[100vh] bg-[#354064] flex justify-center items-center text-[#354064] px-4">
        <Helmet>
          <title>Create New Blog</title>
          <meta name="description" content="Create a new Blog" />
        </Helmet>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="px-8 py-2 bg-card">
          <form onSubmit={updatePost}>
            <div>
              <div>
                <div>
                  <h3 className="text-xl font-bold text-center">
                    Create a new Post
                  </h3>
                  <div>
                    <label htmlFor="title" className="text-base">
                      Blog Title
                    </label>
                    <input
                      type="text"
                      value={state.title}
                      onChange={handleChange}
                      name="title"
                      id="title"
                      className="bg-[#354064] text-white p-2 outline-none"
                      placeholder="Post Title"
                    />
                  </div>
                  <Toaster
                    position="top-right"
                    reverseOrder={false}
                    toastOptions={{ style: { marginTop: "50px" } }}
                  />
                  <div>
                    <label htmlFor="body" className="text-base">
                      Post body{" "}
                    </label>
                    <textarea
                      type="text"
                      maxLength="2000"
                      name="body"
                      id="body"
                      className="w-[86vw] border-2 border-viewMore h-[56vh] outline-none mt-2 p-2 rounded-lg lg:h-[46vh]"
                      style={{ background: "none" }}
                      onChange={handleChange}
                      value={state.body}
                    />
                  </div>

                  <div>
                    <label htmlFor="setCategory" className="text-base">
                      Select Category
                    </label>
                    <select
                      name="category"
                      className="text-white p-2 w-[52vw] md:w-[18vw]"
                      style={{ background: "#354064", outline: "none" }}
                      value={state.category}
                      onChange={handleChange}
                      id="setCategory">
                      <option value={state.category}>{post.category}</option>
                      {user.skills.map((skill) =>
                        skill !== post.category ? (
                          <option value={skill} key={skill}>
                            {skill}
                          </option>
                        ) : (
                          ""
                        )
                      )}
                    </select>
                  </div>
                  <div className="group">
                    <input
                      type="submit"
                      value="Edit Post"
                      className="text-white bg-[#4950F6] rounded-lg px-4 py-2 mt-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Edit;
