// Function to send a pageview event to Google Analytics
import ReactGA from "react-ga4";
export function sendPageView(location) {
  try {
    // window.gtag("config", "G-BNHNPEGZRZ", {
    //   page_path: location,
    // });
    ReactGA.send({
      hitType: "pageview",
      page: location,
      title: location.split("/")[1],
    });
    console.log("working gtag", location.split("/")[1]);
  } catch (err) {
    console.log("error in gtag", err);
  }
}

// this funtion is only used to send high priority events
export const SendHGoogleEvent = (action, label) => {
  ReactGA.event({
    category: "High Priorty",
    action: action,
    label: label, // optional
  });
};
