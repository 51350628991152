import React, { useState, useEffect, Suspense, useRef, useContext } from "react";
import MobileFooter from "../footer/MobileFooter";
// import "./competition.css";
import { path } from "../../path";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import ParticipateUserList from "./ParticipateUserList";
import { WebSocketContext } from "../../WebSocket";

import { useParams } from "react-router-dom";
import B from "./CompetitionUI/B";
import C from "./CompetitionUI/C";
import D from "./CompetitionUI/D";
import E from "./CompetitionUI/E";
import F from "./CompetitionUI/F";
import G from "./CompetitionUI/G";
import A from "./CompetitionUI/A";

import Winner from "./CompetitionUI/Winner";

const CompetitionMobile = () => {

    const socket = useContext(WebSocketContext);



    const { user, token } = useSelector((state) => state.AuthReducer);
    const [contestData, setContestData] = useState(null);
    const [userRegister, setuserRegister] = useState(false);
    const [userSubmission, setuserSubmission] = useState(true);
    const [currentDate, setcurrentDate] = useState(new Date());
    const [RegistrationEnd, setRegistrationEnd] = useState();
    const [SubmissionStart, setSubmissionStart] = useState();
    const [SubmissionEnd, setSubmissionEnd] = useState();
    const [ParticipatedUser, setParticipatedUser] = useState(true);
    const history = useHistory();
    const { id } = useParams();
    const config = {
        host: `${path}`,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
        },
    };
    const getcontestdetails = async () => {
        try {
            console.log(id)
            const res = await axios.get(`${path}/contest/getContestsbyId/${id}`)
            setContestData(res?.data?.data)
            console.log(res?.data?.data?.responses?.sections[3].fields[0].response)
            // RegistrationEnd.current.value = res?.data?.data?.responses?.sections[3].fields[0].response;
            setRegistrationEnd(ConvertToDate(res?.data?.data?.responses?.sections[3].fields[0].response));
            setSubmissionEnd(ConvertToDate(res?.data?.data?.responses?.sections[3].fields[3].response))
            setSubmissionStart(ConvertToDate(res?.data?.data?.responses?.sections[3].fields[2].response))
            // console.log(RegistrationEnd.current.toUTCString())
        } catch (err) { console.log(err) }
    }

    const ConvertToDate = (stringDate) => {
        let dateString = stringDate;
        // Extract date and time components
        let [datePart, timePart] = dateString.split(" at ");

        // Extract year, month, and day
        let [year, month, day] = datePart.split("-");

        // Extract hour and minute
        let [hour, minute] = timePart.split(":");

        // Create a new Date object
        let dateObject = new Date(year, month - 1, day, hour, minute);

        return dateObject
    }

    const Register = async () => {

    }

    const createSumbission = async () => {
        if (userSubmission) return;
        history.push(`/submission/${id}`);
    }

    const checkRegistrationStatus = async () => {
        const res = await axios.get(`${path}/contest/hasUserRegister/${id}/${user._id}`, config)
        // console.log(res)
        if (res?.data?.userRegister) { setuserRegister(true); checkSubmissionStatus() }
    }

    const checkSubmissionStatus = async () => {
        const res = await axios.get(`${path}/contest/hasUserContributed/${id}/${user._id}`, config);
        if (res.data.length === 0) setuserSubmission(false);
    }

    useEffect(() => {
        getcontestdetails();
        checkRegistrationStatus();
        // console.log(contestData.responses)
    }, [])

    const register = async () => {
        const config = {
            host: `${path}`,
            headers: {
                "Access-Control-Allow-Origin": `${path}`,
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await axios.post(
            `${path}/contest/joinnow`,
            {
                user: user,
                contestId: contestData?._id,
            },
            config
        );
    }

    useEffect(() => {
        const changeDate = () => {
            setcurrentDate(new Date());
        }
        socket.on("submission-start", changeDate);
        socket.on("submission-stop", changeDate);
        socket.on("registration-end", changeDate);
        socket.on("submission-stop", changeDate);

        return () => {
            socket.off("submission-start", changeDate);
            socket.off("submission-stop", changeDate);
            socket.off("registration-end", changeDate);
            socket.off("submission-stop", changeDate);
        }

    }, [socket])

    const componentMap = {
        A, B, C, D, E, F, G, Winner
    };

    const DynamicComponent = ({ componentName, ...props }) => {
        const Component = componentMap[componentName];
        if (!Component) {
            return <div>Component not found</div>;
        }
        return <Component {...props} />;
    };


    // console.log(contest.responses)
    // console.log(contest.responses.sections[1])
    return (
        <>
            {contestData !== null && <div className="flex pb-14 flex-col mx-auto overflow-auto rounded-3xl bg-compBgSec w-full h-[100vh]">

                {ParticipatedUser ?
                    <>
                        <div className="flex gap-5 justify-between self-center px-5 mt-12 w-full font-semibold text-primary max-w-[392px]">
                            <h1 onClick={() => history.push("/competition")} className="text-base cursor-pointer font-gilroy-semibold text-compSec">Competitions</h1>
                            <h1 onClick={() => setParticipatedUser(false)} className="text-sm text-right  font-gilroy-semibold cursor-pointer text-compSec ">Participants ({contestData?.participatedUser?.length})</h1>
                        </div>
                        <div className="flex flex-col gap-2 self-center pt-px pb-4 mt-4 w-full bg-compBg rounded-lg max-w-[362px]">
                            {/* Section A*/}
                            {contestData.responses.sections.map((section, index) => {
                                if (index === 0) return <></>
                                let SectionComponent = section.title.split("_")[1];
                                if (SectionComponent === "H") return "";
                                // Check for contestData.result and use Winner component if applicable
                                if (SectionComponent === "F" && contestData.result && contestData.result.length > 0) {
                                    return <DynamicComponent componentName="Winner" contestId={contestData._id} />;
                                }
                                return <DynamicComponent componentName={SectionComponent} fields={section.fields} />
                            })}
                            <div className="p-2">
                                {userRegister === true ? (
                                    <>
                                        {currentDate < SubmissionStart ? <button className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                            Registrated
                                        </button> : (
                                            <>
                                                {currentDate < SubmissionEnd ? <button onClick={createSumbission} className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                    {userSubmission ? "Already Submitted" : "Create Submission"}
                                                </button>
                                                    :
                                                    <button className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                        Sumission Ended
                                                    </button>}
                                            </>)}
                                    </>
                                ) : (
                                    <>
                                        {currentDate < RegistrationEnd ? (
                                            <>
                                                <button onClick={register} className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                    Registration Started
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button className="bg-[#008080] rounded-lg w-full font-gilroy-semibold text-white p-4">
                                                    Registation Closed
                                                </button>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>

                        </div>
                    </>
                    :
                    <>
                        <ParticipateUserList userList={contestData?.participatedUser} closeList={setParticipatedUser} />
                    </>}
                <MobileFooter />
            </div>}
        </>
    );
}


export default CompetitionMobile;