// import React from 'react'
import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { path } from "../../path";
import axios from "axios";
import UserSelection from "./UserSelection";
import { SKILLS_NAME } from "../../skills";

function EditContest({ props, setEditable, setSelectedCompetition }) {
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [view, setView] = useState(false);
  const [image, setImage] = useState(null);
  const [updateText,setUpdateText] = useState("Update")
 

  //   const handleImageUpload = () => {
  //     // Implement logic to upload image to backend
  //     if (image) {
  //       const formData = new FormData();
  //       formData.append("image", image);

  //       // Make a fetch request to your backend endpoint
  //     } else {
  //       console.error("No image selected");
  //       // Handle error state or display error message to the user
  //     }
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setView(false);
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    const formData = {};
    const judgeSend = judges.map((judge) => judge._id);
    const organizerSend = organizers.map((organizer) => organizer._id);
    console.log(judgeSend);
    if(recordinglen<videolen){
      alert("Recording length cannot be less than video length");
      return;
    }
    if(title.trim()===""){
       alert("Please give a title");
       return;
    }
    formData.title = title;
    formData.fomat = format;
    formData.videolen=videolen;
    formData.recordinglen=recordinglen;
    formData.space=space;
    formData.slotsize=slotsize;
    formData.regFee=regFee;
    formData.studiorating=studiorating;
    formData.ageGrp=ageGrp;
    formData.description = description;
    formData.registrationCloseDate = registrationCloseDate;
    formData.registrationStartDate = registrationStartDate;
    formData.submissionStartDate = submissionStartDate;
    formData.submissionClosingDate = submissionClosingDate;
    formData.resultTime = resultTime;
    formData.organizers = organizerSend;
    formData.judges = judgeSend;
    formData.creator = creator?._id;
    formData.ruleAndRegulations = ruleAndRegulations;
    formData.winningPrize = [first, second, third];
    formData.mode = mode;
    formData.skill = skill;
    formData.venue = venue;
    formData.status = status;
    // Handle form submission here, you can send formData to your backend API or perform any other actions
    console.log({creator: formData.creator});
    try {
      setUpdateText("Updating..")
      const response = await axios.post(
        `${path}/contest/update`,
        { id: props._id, ...formData },
        config
      );
      if (response.data.success) {
        // alert("Updated")
        window.location.reload();
      } else {
        alert("Failed to Update");
      }
    } catch (error) {
      alert("Please fill all the fields");
    }
  };
  const [slotsize,setslotsize] = useState(props.slotsize)
  const [ageGrp, setageGrp] = useState(props.ageGrp)
  const [format,setFormat] = useState(props.format);
  const [regFee,setRegFee] = useState(props.regFee);
  const [space,setSpace]=useState(props.space);
  const [studiorating,setStudioRating] = useState(props.studiorating)
  const [videolen,setVideolen] = useState(props.videolen)
  const [recordinglen,setRecordinglen]=useState(props.recordinglen)

  const [title, setTitle] = useState(props.title);
  const [description, setDescription] = useState(props.description);
  const [skill, setSkill] = useState(props.skill);
  const [status, setStatus] = useState(props.status);
  const [mode, setMode] = useState(props.mode);
  const [venue, setVenue] = useState(props.venue);
  const [registrationStartDate, setRegistrationStartDate] = useState(
    props.registrationStartDate.slice(0, 16)
  );
  const [registrationCloseDate, setRegistrationCloseDate] = useState(
    props.registrationCloseDate.slice(0, 16)
  );
  const [submissionStartDate, setSubmissionStartDate] = useState(
    props.submissionStartDate.slice(0, 16)
  );
  const [submissionClosingDate, setSubmissionClosingDate] = useState(
    props.submissionClosingDate.slice(0, 16)
  );
  const [resultTime, setResultTime] = useState(props.resultTime.slice(0, 16));
  const [ruleAndRegulations, setRuleAndRegulations] = useState(
    props.ruleAndRegulations
  );
  const [rule, setRule] = useState("");

  const [first, setFirst] = useState(props?.winningPrize[0]);
  const [second, setSecond] = useState(props?.winningPrize[1]);
  const [third, setThird] = useState(props?.winningPrize[2]);

  const [judges, setJudges] = useState(props.judges);
  const [judgeModel, setJudgeModel] = useState(false);

  const [creator, setCreator] = useState(props.creator);
  const [creatorModel, setCreatorModel] = useState(false);

  const [organizers, setOrganizers] = useState(props.organizers);
  const [organizersModel, setOrganizersModel] = useState(false);

  function removeJudges(judgeToRemove) {
    setJudges((prevJudges) =>
      prevJudges.filter((judge) => judge._id !== judgeToRemove._id)
    );
  }
  function removeOrganizer(OrganizerToRemove) {
    setOrganizers((prevOrg) =>
      prevOrg.filter((org) => org._id !== OrganizerToRemove._id)
    );
  }

  function removeRule(deleteRule) {
    setRuleAndRegulations((rules) =>
      rules.filter((rule) => rule != deleteRule)
    );
  }

  return (
    <main className="p-3 main-container overflow-y-auto w-full">
      <div className="main-title">
        <h3>DASHBOARD</h3>
      </div>
      <div className="w-full mx-auto bg-card">
        <h1 className="text-2xl font-bold my-4">Create Contest</h1>
        <form className="my-4" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-primary font-bold mb-2">
              Title:
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-primary font-bold mb-2">
              Description:
            </label>
            <textarea
              id="description"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
              rows="4"></textarea>
          </div>

          <div className="mb-4">
            <label
              htmlFor="skill"
              className="block text-primary font-bold mb-2">
              Skill:
            </label>
            <select
              type="text"
              id="skill"
              name="skill"
              value={skill}
              onChange={(e) => setSkill(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700">
              {SKILLS_NAME.map((skill) => (
                <option>{skill}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label for="status" className="block text-primary font-bold mb-2">
              Status:
            </label>
            <select
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700">
              <option value="upcomming"> Upcoming</option>
              <option value="ongoing"> Ongoing</option>
              <option value="ended"> Ended</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-primary font-bold mb-2">Mode:</label>
            <div className="flex items-center mb-2 bg-neutral text-primary">
              <input
                type="radio"
                id="online"
                name="mode"
                value="online"
                checked={mode === "online"}
                onChange={(e) => setResultTime(e.target.value)}
                className="mr-2"
              />
              <label htmlFor="online" className="mr-2">
                Online
              </label>
              <input
                type="radio"
                id="offline"
                name="mode"
                value="offline"
                checked={mode === "offline"}
                onChange={(e) => setMode(e.target.value)}
                className="mr-2"
              />
              <label htmlFor="Both">Offline</label>
              <input
                type="radio"
                id="Both"
                name="mode"
                value="Both"
                checked={mode === "Both"}
                onChange={(e) => setMode(e.target.value)}
                className="mr-2"
              />
              <label htmlFor="Both">Both</label>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="ageGrp"
              className="block text-primary font-bold mb-2">
              Age Group:
            </label>
            <input
              type="text"
              id="ageGrp"
              name="ageGrp"
              value={ageGrp}
              onChange={(e)=>setageGrp(e.target.value)}
              className="w-[10vw] p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div><div className="mb-4">
            <label
              htmlFor="slotsize"
              className="block text-primary font-bold mb-2">
              Slot size
            </label>
            <input
              type="numeric"
              id="slotsize"
              name="slotsize"
              value={slotsize}
              onChange={(e)=>setslotsize(e.target.value)}
              className="w-[10vw] p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div><div className="mb-4">
            <label
              htmlFor="regFee"
              className="block text-primary font-bold mb-2">
              Registration fee (in rupees):
            </label>
            <input
              type="numeric"
              id="regFee"
              name="regFee"
              value={regFee}
              onChange={(e)=>setRegFee(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <div className="mb-4">
            <label className="block text-primary font-bold mb-2">Format:</label>
            <div className="flex items-center mb-2 bg-neutral text-primary">
              <input
                type="radio"
                id="Solo"
                name="format"
                value="Solo"
                checked={format === "Solo"}
                onChange={(e) => setFormat(e.target.value)}
                
                className="mr-2"
              />
              <label htmlFor="solo" className="mr-2">
                Solo
              </label>
              <input
                type="radio"
                id="group"
                name="format"
                value="group"
                checked={format === "group"}
                onChange={(e) => setFormat(e.target.value)}
                className="mr-2"
              />
              <label htmlFor="group">group</label>
             
            </div>
          </div>
          {(mode==="offline" || mode==="Both") && 
          <div>  
          <div className="mb-4">
            <label
              htmlFor="venue"
              className="block text-gray-700 font-bold mb-2">
              Venue:
            </label>
            <input
              type="text"
              id="venue"
              name="venue"
              value={venue}
              onChange={(e) => setVenue(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <div className="mb-4">
          <label
            htmlFor="space"
            className="block text-gray-700 font-bold mb-2">
            Space size:
          </label>
          <input
            type="text"
            id="space"
            name="space"
            value={space}
            onChange={(e)=>setSpace(e.target.value)}
            className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
          />
        </div>
        <div className="mb-4">
            <label
              htmlFor="studiorating"
              className="block text-gray-700 font-bold mb-2">
              Studio rating(out of 10):
            </label>
            <input
              type="numeric"
              id="studiorating"
              name="studiorating"
              value={studiorating}
              onChange={(e)=>setStudioRating(e.target.value)}
              className="w-[3vw] p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          </div>
          
          }
          <div className="mb-4">
            <label
              htmlFor="videolen"
              className="block text-gray-700 font-bold mb-2">
              Video Length (in minutes):
            </label>
            <input
              type="text"
              id="videolen"
              name="videolen"
              value={videolen}
              onChange={(e)=>setVideolen(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="recordinglen"
              className="block text-gray-700 font-bold mb-2">
              Recording length(in minutes):
            </label>
            <input
              type="text"
              id="recordinglen"
              name="recordinglen"
              value={recordinglen}
              onChange={(e)=>setRecordinglen(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <div className="mb-4">
            <label for="coverPhoto" className="w-40 font-bold text-primary">
              Cover photo:{" "}
            </label>
            {props.coverPhoto && (
              <img width={100} height={100} src={props.coverPhoto} />
            )}
          </div>
          {/* <div className="mb-4">
            <label
              htmlFor="registrationStartDate"
              className="block text-gray-700 font-bold mb-2">
              Registration Start:
            </label>
            <input
              type="datetime-local"
              id="registrationStartDate"
              name="registrationStartDate"
              value={registrationStartDate}
              onChange={(e) => setRegistrationStartDate(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div> */}

          <div className="mb-4 ">
            <label
              htmlFor="regClose"
              className="block text-gray-700 font-bold mb-2">
              Registration Close:
            </label>
            <input
              type="datetime-local"
              id="regClose"
              name="registrationCloseDate"
              value={registrationCloseDate}
              onChange={(e) => setRegistrationCloseDate(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="regClose"
              className="block text-gray-700 font-bold mb-2">
              Submission Start:
            </label>
            <input
              type="datetime-local"
              id="regClose"
              name="submissionStartDate"
              value={submissionStartDate}
              onChange={(e) => setSubmissionStartDate(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="regClose"
              className="block text-gray-700 font-bold mb-2">
              Submission Close:
            </label>
            <input
              type="datetime-local"
              id="regClose"
              name="submissionClosingDate"
              value={submissionClosingDate}
              onChange={(e) => setSubmissionClosingDate(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="regClose"
              className="block text-gray-700 font-bold mb-2">
              Result:
            </label>
            <input
              type="datetime-local"
              id="resultTime"
              name="resultTime"
              value={resultTime}
              onChange={(e) => setResultTime(e.target.value)}
              className="w-full p-2 border mb-1 rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <div className="mb-4">
            <p>Rules:</p>
            <div className="flex  justify-evenly">
              <input
                value={rule}
                onChange={(e) => setRule(e.target.value)}
                placeholder="Add a new Rule"
                className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
              />
              <div
                className="bg-teal-500 px-2 py-1 mx-1 rounded-md text-primary text-sm  cursor-pointer"
                onClick={() => {
                  setRuleAndRegulations((rules) => [...rules, rule]);
                  setRule("");
                }}>
                Add Rule
              </div>
            </div>
            {ruleAndRegulations.length > 0 && (
              <div className="bg-secondary mt-2 rounded-md h-[12rem] overflow-y-scroll px-1">
                {ruleAndRegulations.map((rule, key) => (
                  <div
                    key={key}
                    onClick={() => removeRule(rule)}
                    style={{ wordWrap: "break-word" }}
                    className="px-1 bg-card my-1 rounded-md cursor-pointer hover:bg-gray-100 ">
                    {rule}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="mb-4">
            <p>Judges:</p>
            <div
              onClick={() => setJudgeModel(true)}
              className="bg-teal-500 text-white px-4 py-2 rounded-md cursor-pointer">
              Select judges
            </div>
            <div className="flex flex-row my-1">
              {judges.length > 0 &&
                judges?.map((judge) => (
                  <div
                    key={judge._id}
                    onClick={() => removeJudges(judge)}
                    className="bg-secondary mx-1 p-1 rounded-md cursor-pointer hover:bg-gray-300">
                    {judge.username}
                  </div>
                ))}
            </div>
            {judgeModel && (
              <div className=" w-full h-full bg-white">
                <UserSelection
                  title={"Judges"}
                  setJudgeModel={setJudgeModel}
                  setJudges={setJudges}
                />
              </div>
            )}
          </div>
          <div className="mb-4">
            <p>Organizers:</p>
            <div
              onClick={() => setOrganizersModel(true)}
              className="bg-teal-500 text-white px-4 py-2 rounded-md cursor-pointer">
              Select Organizers
            </div>
            <div className="flex flex-row my-1">
              {organizers.length > 0 &&
                organizers?.map((organizer) => (
                  <div
                    key={organizer._id}
                    onClick={() => removeOrganizer(organizer)}
                    className="bg-secondary mx-1 p-1 rounded-md cursor-pointer hover:bg-gray-300">
                    {organizer.username}
                  </div>
                ))}
            </div>
            {organizersModel && (
              <div className=" w-full h-full bg-white">
                <UserSelection
                  title={"Organizers"}
                  setJudgeModel={setOrganizersModel}
                  setJudges={setOrganizers}
                />
              </div>
            )}
          </div>
          <div className="mb-4">
            <p>Creator:</p>
            <div
              onClick={() => setCreatorModel(true)}
              className="bg-teal-500 text-white px-4 py-2 rounded-md cursor-pointer mb-2">
              Select Creator
            </div>
            {creator && (
              <div className="flex flex-row my-1">
                <div
                  onClick={() => {
                    setCreator();
                  }}
                  className="bg-secondary mx-1 p-1 rounded-md cursor-pointer hover:bg-gray-300">
                  {creator?.username}
                </div>
              </div>
            )}
            {creatorModel && (
              <div className=" w-full h-full bg-white">
                <UserSelection
                  title={"Creator"}
                  setJudgeModel={setCreatorModel}
                  setJudges={setCreator}
                />
              </div>
            )}
          </div>
          <div className="mb-4">
            <p>Winning Prize:</p>
            <label htmlFor="1" className="block text-gray-700 font-bold mb-2">
              1st
            </label>
            <input
              type="numeric"
              placeholder="Enter winning prize amount in rupees"
              id="1"
              name="winningPrize"
              value={first}
              onChange={(e) => setFirst(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
            <label htmlFor="1" className="block text-gray-700 font-bold mb-2">
              2nd
            </label>
            <input
              type="numeric"
              placeholder="Enter winning prize amount in rupees"
              id="2"
              name="winningPrize"
              value={second}
              onChange={(e) => setSecond(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
            <label htmlFor="1" className="block text-gray-700 font-bold mb-2">
              3rd
            </label>
            <input
              type="numeric"
              placeholder="Enter winning prize amount in rupees"
              id="3"
              name="winningPrize"
              value={third}
              onChange={(e) => setThird(e.target.value)}
              className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
            />
          </div>
          <button
            type="submit"
            className="bg-teal-500 text-white px-4 py-2 rounded-md">
           {updateText}
          </button>
        </form>
      </div>
    </main>
  );
}

export default EditContest;
