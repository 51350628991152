import React, { useState, useEffect } from "react";
import axios from "axios";
import { path } from "../../path";
import { useDispatch, useSelector } from "react-redux";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { reportPost } from "../../store/asyncMethods/AuthMethods";

const ReportModal = ({ id, type, setreportModal }) => {
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [report, setreport] = useState(false);

  const [descriptionError, setDescriptionError] = useState("");
  const [reasonError, setReasonError] = useState("");
  const handleCancel = () => {
    setreportModal(false);
  };

  const postId = id;
  console.log("postid: ", postId);
  const dispatch = useDispatch();
  const complaint = {
    A: "It's spam",
    B: "I just dont like it",
    C: "Nudity or sexual activity",
    D: "Hate speech or symbols",
    E: "Violence or dangerous organizations",
    F: "False information",
    G: "Bullying or harassment",
    H: "Scam or fraud",
    I: "Intellctual property violation",
    J: "Suicide or self-injury",
    K: "Sale of illegal or regulated goods",
    L: "Eating disorders",
    M: "Something Else",
  };
  const [state, setState] = useState({
    report: complaint["-1"],
    description: "",
  });
  const reportChnage = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: complaint[e.target.value] });
    // console.log("State: ",state)
  };
  const descriptionChange = (e) => {
    e.preventDefault();
    setState({ ...state, description: e.target.value });
  };
  // useEffect(()=>{
  //     if(redirect){
  //         alert("we will surely look into your problem");
  //         push("/")
  //         dispatch({type:'REDIRECT_FALSE'});
  //     }
  // },[redirect])
  const reportClick = async (e) => {
    e.preventDefault();
    // console.log(state, postId);
    try {
      if (state.report === complaint["-1"]) {
        setReasonError("Please Select a Valid Reason");
        return;
      }
      setReasonError("");

      if (state.description?.trim()?.length === 0) {
        setDescriptionError("Please Enter a Description.");
        return;
      }

      if (state.description?.trim()?.length >= 300) {
        setDescriptionError(
          "Description length should be less than 300 characters."
        );
        return;
      }

      setDescriptionError("");
      //
      setreport(true);
      setTimeout(() => {
        dispatch(reportPost(state, postId));
      }, 2000);
      // this was commented out because it causes memory leak
      // setTimeout(() => {
      //   handleCancel();
      // }, 6000);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="absolute top-0 left-0 z-50 w-[100%] h-[100%] flex justify-center items-center">
      <div
        className="z-[-1] fixed top-0 left-0 h-[100%] w-[100%] bg-black opacity-50"
        onClick={() => setreportModal((prev) => !prev)}></div>

      <div
        className="w-[80%] sm:w-[30%]   mx-auto p-5 border-1  shadow-lg rounded-2xl text-primary bg-card"
        id="delete-modal">
        <div className="flex flex-row items-center justify-between py-2">
          <h3 className="text-2xl font-bold text-red-600">Report post</h3>
          <button onClick={handleCancel}>
            <CloseOutlinedIcon />
          </button>
        </div>
        <hr className="border-viewMore" />
        {!report ? (
          <div className="my-4 z-[7]">
            <form onSubmit={reportClick}>
              <div className="">
                <h2 className="my-2 font-semibold text-primary ">
                  {" "}
                  Why are you reporting the post?
                </h2>
                <select
                  name="report"
                  onChange={reportChnage}
                  className=" w-full
                  outline-none focus:ring-2 ring-teal-700 border-1 border-viewMore
bg-neutral
                                                px-3
                                                py-1.5
                                                font-normal
                                                text-message
                                               bg-clip-padding bg-no-repeat
                                                border-solid border-gray-300
                                                rounded 
                                                transition
                                                ease-in-out
                                                
                                                m-0
                                              focus:text-message focus:bg-card"
                  style={{ background: "", outline: "none" }}>
                  <option value="-1">Select reason</option>
                  <option value="A">It's spam</option>
                  <option value="B">I just dont like it</option>
                  <option value="C">Nudity or sexual activity</option>
                  <option value="D">Hate speech or symbols</option>
                  <option value="E">Violence or dangerous organizations</option>
                  <option value="F">False information</option>
                  <option value="G">Bullying or harassment</option>
                  <option value="H">Scam or fraud</option>
                  <option value="I">Intellctual property violation</option>
                  <option value="J">Suicide or self-injury</option>
                  <option value="K">Sale of illegal or regulated goods</option>
                  <option value="L">Eating disorders</option>
                  <option value="M">Something Else</option>
                </select>
              </div>
              {reasonError != "" && (
                <p className="text-red-600">{reasonError}</p>
              )}
              <div className="text-slate-300">
                <h2 className="my-2 font-semibold text-primary ">
                  {" "}
                  Description<span className="text-red-600 "> *</span>{" "}
                </h2>
                <input
                  onChange={descriptionChange}
                  value={state.description}
                  type="text"
                  name="description"
                  placeholder="Please elaborate about the reason"
                  className=" w-full dark:text-primary
                  outline-none focus:ring-2 ring-teal-700 border-1 border-viewMore bg-neutral
                                                    px-3
                                                    py-1.5
                                                    font-normal
                                                    text-message
                                                     bg-clip-padding bg-no-repeat
                                                     border-solid border-gray-300
                                                    rounded 
                                                    transition
                                                    ease-in-out
                                                    m-0
                                                    focus:text-message focus:bg-primary"
                />
                {descriptionError != "" && (
                  <p className="text-red-600">{descriptionError}</p>
                )}
              </div>
              <div className="mt-3">
                <input
                  type="submit"
                  value="Report"
                  className="px-4 text-white bg-red-600 rounded-lg cursor-pointer border-1 md:px-8 md:py-1"
                />
              </div>
            </form>
          </div>
        ) : (
          <div>
            <h2 className="my-2 font-semibold text-primary">
              We have successfully reported this post . <br />
              Thanks!!{" "}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportModal;
