import React, { useState, useEffect } from "react";

const Countdown = ({ countdownTime }) => {
  const [number, setNumber] = useState(countdownTime / 1000);

  useEffect(() => {
    const updateNumber = () => {
      const nextNumber = number - 1;
      setNumber(nextNumber);
      if (nextNumber !== 0) {
        setTimeout(updateNumber, 1000);
      }
    };

    const timeout = setTimeout(updateNumber, 1000);

    return () => clearTimeout(timeout);
  }, [number]);

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center font-mono text-6xl text-shadow">
      {number !== 0 ? number : null}
    </div>
  );
};

export default Countdown;
