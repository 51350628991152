import React, { useEffect, useState } from "react";
import MobileFooter from "../footer/MobileFooter";

import SingleCompetition from "./SingleCompetition";
import Subcompetition from "./Subcompetition";
import "./competition.css";
import { useDispatch, useSelector } from "react-redux";
import Home from "./Home";
import { path } from "../../path";
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import axios from "axios";
import LogoutModal from "../Modals/LogoutModal";
import { useContext } from "react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { WebSocketContext } from "../../WebSocket";
import EditContest from "./EditContest";
import SubmissionsList from "./SubmissionsList";
const Competition = () => {
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [contestData, setContestData] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState();
  const [isRightSectionOpen, setIsRightSectionOpen] = useState(true);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const dispatch = useDispatch();
  const handleCompetitionClick = (competitionData) => {
    setSelectedCompetition(competitionData);
    setContestCreateScreen(false);
    setIsRightSectionOpen(true);
  };
  const socket = useContext(WebSocketContext);
  function logoutSocketEvent() {
    socket.emit("disconnectt", user._id);
  }


  const handleRightSectionClose = () => {
    setIsRightSectionOpen(false);
  };
  const [contestCreateScreen, setContestCreateScreen] = useState(true);
  const [editable, setEditable] = useState(false);
  const [editableProp, setEditableProp] = useState({});

  const [ongoingContests, setOngoingContests] = useState([]);
  const [upcommingContests, setUpcommingContests] = useState([]);
  const [endedContest, setEndedContests] = useState([]);
  const [eventId, setEventId] = useState([]);
  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
  }, []);

  async function getContest() {
    const config = {
      host: `${path}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(`${path}/contest`, config);
      // console.log(response, "resposnse");
      setContestData(response.data);
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    getContest();
    console.log(contestData);

    // setUpcommingContests(
    //   contestData?.filter(
    //     (contest) =>
    //       (contest?.status === "upcoming" || contest?.status === "upcomming" || contest.status === "") && (new Date(contest?.registrationStartDate)>new Date())
    //   )
    // );
  }, []);
  useEffect(() => {
    setOngoingContests(
      contestData?.filter(
        (contest) => new Date() < new Date(contest.submissionClosingDate)
      )
    );
    setEndedContests(
      contestData?.filter(
        (contest) => new Date(contest.submissionClosingDate) < new Date()
      )
    );
  }, [contestData]);

  useEffect(() => {
    getContest();
  }, [editable]);

  return (
    <>
      <div className="relative h-[100vh]  bg-primary ">
        <div className={`fixed left-0 z-30`}>
          <LogoutModal
            showLogoutModal={showLogoutModal}
            setShowLogoutModal={setShowLogoutModal}
            logoutSocketEvent={logoutSocketEvent}
          />
        </div>
        <div className="main-title">
          <a onClick={() => setShowLogoutModal(true)}>
            <li className="flex  my-[1%] items-center cursor-pointer">
              <LogoutOutlinedIcon
                fontSize="medium"
                className="text-slate-500 mx-[2%] mr-[5%] mt-[2%]"
              />{" "}
              <span className="mt-[2%] text-[#767676] ml-[3px]">Logout </span>{" "}
            </li>
          </a>

        </div>
        <div className="flex flex-col md:h-[96vh] justify-around items-center">
          <div className="inner-container flex justify-center h-full sm:h-full bg-card text-primary pt-0 md:pt-[1rem] relative w-full md:w-[75vw] md:justify-start md:pl-[0.5rem] md:rounded-[0.56rem] md:border md:border-viewMore z-0 overflow-hidden">
            <div className="left-section  w-[100vw] md:w-[40vw]">
              <div className="flex w-full items-center justify-center mb-2">
                <button
                  onClick={() => {
                    setContestCreateScreen(true);
                    setSelectedCompetition();
                  }}
                  className="bg-teal-500 text-white px-3 py-1 rounded-md w-full hover:bg-teal-600">
                  Create Contest
                </button>
              </div>
              <h1 className="text-center text-primary font-bold">
                Competitions
              </h1>
              <div class="h-[90vh] overflow-y-scroll pb-12">
                {/* <h1 className="text-xl font-semibold">Upcoming</h1>
                {upcommingContests.map((contest, index) => (
                  <div className="cursor-pointer">
                    <SingleCompetition
                      key={index}
                      data={contest}
                      onClick={handleCompetitionClick}
                    />
                  </div>
                ))} */}
                <h1 className="text-xl font-semibold mb-4">
                  Ongoing Competitions
                </h1>
                {contestData.map((contest, index) => (
                  <div className="cursor-pointer" key={index}>
                    <SingleCompetition
                      data={contest.responses.sections}
                      id={contest._id}
                    />
                    <Link to={`/editcontest/${contest._id}`} className="no-underline">
                      Edit
                    </Link>
                    <Link to={`/rewards/${contest._id}`} className="no-underline mx-4">Payout</Link>
                  </div>
                ))}

                <h1 className="text-lg font-semibold">Ended</h1>
                {endedContest.map((contest, index) => (
                  <div className="cursor-pointer" key={index}>
                    <Link to={`/editcontest/${contest._id}`} className="no-underline">
                      <SingleCompetition
                        data={contest.responses.sections}
                        id={contest._id}
                      />
                    </Link>
                  </div>
                ))}

              </div>
            </div>
            {!contestCreateScreen && (
              <div className="w-full">
                {!editable ? (
                  <div className="overflow-y-auto hidden text-[#1c4980] w-full text-center h-full m-auto text-[1.5rem] md:flex justify-center items-start">
                    <Subcompetition
                      setEditable={setEditable}
                      setEditableProp={setEditableProp}
                      data={selectedCompetition}
                    />
                  </div>
                ) : (
                  <div className=" w-full overflow-y-auto h-full">
                    <div
                      className="bg-teal-500 cursor-pointer px-2 py-1 rounded-md text-white"
                      onClick={() => {
                        setEditableProp({});
                        setEditable(false);
                      }}>
                      Cancel
                    </div>
                    <EditContest
                      setSelectedCompetition={setSelectedCompetition}
                      setEditable={setEditable}
                      props={editableProp}
                    />
                  </div>
                )}
              </div>
            )}
            {isRightSectionOpen &&
              (!contestCreateScreen ? (
                <div className="right-section-overlap md:hidden absolute top-0 left-0  h-full bg-white z-10">
                  <div className="flex justify-end">
                    <button
                      className="text-red-500"
                      onClick={handleRightSectionClose}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  {!editable ? (
                    <Subcompetition
                      setEditable={setEditable}
                      setEditableProp={setEditableProp}
                      data={selectedCompetition}
                    />
                  ) : (
                    <EditContest
                      setEditable={setEditable}
                      props={editableProp}
                    />
                  )}
                </div>
              ) : (
                <div className="flex justify-center w-1/2 p-3">
                  <h1 className="text-center font-bold text-3xl">
                    New Contest Flow
                  </h1>
                </div>
              ))}
          </div>
        </div>
        <MobileFooter />
      </div>
    </>
  );
};

export default Competition;
