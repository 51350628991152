import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UNSET_MESSAGE } from "../../store/types/AuthTypes";
import AlertInvalidFormat from "../Alert/AlertInvalidFormat";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import "./ProfSkills.css";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

const ProffesionalSkillFilter = ({
  postLoader,
  skillQueryArray,
  setSkillQueryArray,
  postType,
  setPostType,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, message, token } = useSelector((state) => state.AuthReducer);
  const [profskills, setprofSkills] = useState([]);
  const [unprofskills, setunprofSkills] = useState([]);
  const [postTypeAlert, setpostTypeAlert] = useState("");

  useEffect(() => {
    setprofSkills(user.professionalSkills.map((skill) => skill));
    setunprofSkills(user.unprofessionalSkills.map((skill) => skill));
  }, [user.professionalSkills, user.unprofessionalSkills]);

  useEffect(() => {
    if (message) {
      history.push("home");
      // dispatch({ type: UNSET_MESSAGE });
    }
  }, [message]);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  // console.log(user);

  useEffect(() => {
    setCheckedState(() =>
      new Array(
        user?.professionalSkills?.length + user?.unprofessionalSkills?.length
      ).fill(true)
    );
  }, [user]);

  const [checkedState, setCheckedState] = useState([]);

  const includes = (obj) => {
    for (let skill of skillQueryArray) {
      if (skill.name === obj.name && skill.type === obj.type) return true;
    }
    return false;
  };

  const handleSkills = async (index, name, type) => {
    if (postLoader) return;
    if (!checkedState[index] && includes({ name, type })) {
      skillQueryArray.splice(0, skillQueryArray.length);
    }
    checkedState[index] = !checkedState[index];
    setCheckedState(checkedState);

    if (!checkedState[index]) {
      setSkillQueryArray(
        skillQueryArray.filter((sk) => sk.name !== name || sk.type !== type)
      );
    } else {
      setSkillQueryArray([...skillQueryArray, { name, type }]);
    }
  };
  const handlePostType = (index, name) => {
    if (postLoader) return;
    var cnt = 0;
    for (var i = 0; i < postType.length; i++) {
      if (postType[i] === true) {
        cnt++;
        if (cnt > 1) {
          break;
        }
      }
    }
    if (cnt == 1 && postType[index] === true) {
      setpostTypeAlert("You need to select atleast one Post type");
      return;
    }
    postType[index] = !postType[index];
    setPostType(postType);
  };

  const [hoverid, sethoverid] = useState("");
  const onHover = (skillid) => {
    sethoverid(skillid);
  };
  const onLeave = () => {
    sethoverid("");
  };

  return (
    <>
      <div className="mt-4 md:mt-6">
        {postTypeAlert.length > 0 && (
          <AlertInvalidFormat msg={postTypeAlert} toggle={setpostTypeAlert} />
        )}
        <div className="flex flex-col justify-center px-3 py-1 ml-1 border rounded-lg bg-card text-primary border-viewMore ">
          <div className="p-3">
            <h3 className="text-sm font-semibold text-primary lg:text-lg sizeof">
            Select profesional Skill
            </h3>
          </div>
          <hr className="border-viewMore" />
          <div className=" polish my-2">
          {profskills.length===0 && <div className="mt-2">
                <Button
                  component={Link}
                  to={{ pathname: `/editPSkills` }}
                  style={{ backgroundColor: '#008080', color: 'white', width: "100%" }} // Adjust colors as needed
                >
                 Add Polished Skills
                </Button>
              </div>}
            <ul className="text-lg text-grayish">
              {profskills.map((skill, index) => {
                return (
                  <li
                    onMouseEnter={() => {
                      onHover(skill);
                    }}
                    onMouseLeave={onLeave}
                    className={`flex items-center space-x-2 px-2 pt-1 myitem`}
                    key={skill + "" + index}>
                    {checkedState[index] === true ? (
                      <CheckBoxOutlinedIcon
                        sx={{
                          ":hover": {
                            cursor:
                              postLoader === true ? "not-allowed" : "pointer",
                          },
                          // color: "teal",
                          // fontSize: 15,
                          fontSize: 20, // Increase the size of the checkbox
                        }}
                        onClick={() => {
                          handleSkills(index, skill, "Polished");
                        }}
                      />
                    ) : (
                      <CheckBoxOutlineBlankOutlinedIcon
                        sx={{
                          ":hover": {
                            cursor:
                              postLoader === true ? "not-allowed" : "pointer",
                          },
                          color: "#637381",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          handleSkills(index, skill, "Polished");
                        }}
                      />
                    )}
                    <span
                      title={Capitalize(skill.split("_").join(" "))}
                      className="text-sm lg:text-lg">
                      {" "}
                      {/* {Capitalize(skill)} */}
                      {hoverid === skill ? (
                        <p>{Capitalize(skill.split("_").join(" "))}</p>
                      ) : (
                        <p>{Capitalize(skill.split("_").join(" "))}</p>
                      )}
                    </span>
                  </li>
                );
                // }
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-2 md:mt-6">
        <div className="flex flex-col justify-center px-3 py-2 ml-1 border rounded-lg border-viewMore bg-card text-primary ">
          <div className="p-3">
            <h3 className="text-sm font-semibold text-primary lg:text-lg sizeof">
            Select Non-profesional Skill
            </h3>
          </div>
          <hr className="border-viewMore" />
          <div className=" polish my-2">
            {unprofskills.length===0 && <div className="mt-2">
                <Button
                  component={Link}
                  to={{ pathname: `/editUSkills` }}
                  style={{ backgroundColor: '#e6b800', color: 'white', width: "100%" }} // Adjust colors as needed
                >
                 Add Novice Skills
                </Button>
              </div>}
            <ul className="text-lg text-grayish">
              {unprofskills.map((skill, index) => {
                return (
                  <li
                    onMouseEnter={() => {
                      onHover(skill);
                    }}
                    onMouseLeave={onLeave}
                    className="flex items-center px-2 pt-1 space-x-2"
                    key={skill + "" + index}>
                    {checkedState[index + profskills.length] === true ? (
                      <CheckBoxOutlinedIcon
                        sx={{
                          ":hover": {
                            cursor:
                              postLoader === true ? "not-allowed" : "pointer",
                          },
                          // color: "teal",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          handleSkills(
                            index + profskills.length,
                            skill,
                            "Novice"
                          );
                        }}
                      />
                    ) : (
                      <CheckBoxOutlineBlankOutlinedIcon
                        sx={{
                          ":hover": {
                            cursor:
                              postLoader === true ? "not-allowed" : "pointer",
                          },
                          color: "#637381",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          handleSkills(
                            index + profskills.length,
                            skill,
                            "Novice"
                          );
                        }}
                      />
                    )}
                    <span
                      className="text-sm lg:text-lg"
                      title={Capitalize(skill.split("_").join(" "))}>
                      {" "}
                      {hoverid === skill ? (
                        <p>{Capitalize(skill.split("_").join(" "))}</p>
                      ) : (
                        <p>{Capitalize(skill.split("_").join(" "))}</p>
                      )}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-2 md:mt-6">
        <div className="flex flex-col justify-center px-3 py-2 mb-2 ml-1 border rounded-lg border-viewMore bg-card text-primary">
          <div className="p-3 ">
            <h3 className="text-sm font-semibold text-primary lg:text-lg sizeof">
              Select Post Type
            </h3>
          </div>
          <hr className="border-viewMore" />
          <div className=" polish my-2">
            <ul className="text-lg text-grayish">
              <li className="flex items-center px-2 pt-1 space-x-2">
                {postType[0] === true ? (
                  <CheckBoxOutlinedIcon
                    sx={{
                      ":hover": {
                        cursor: postLoader === true ? "not-allowed" : "pointer",
                      },
                      // color: "teal",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      handlePostType(0, "blog");
                    }}
                  />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon
                    sx={{
                      ":hover": {
                        cursor: postLoader === true ? "not-allowed" : "pointer",
                      },
                      color: "#637381",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      handlePostType(0, "blog");
                    }}
                  />
                )}
                <h3 className="text-sm lg:text-lg">Content</h3>
              </li>
              <li className="flex items-center px-2 pt-1 space-x-2">
                {postType[1] === true ? (
                  <CheckBoxOutlinedIcon
                    sx={{
                      ":hover": {
                        cursor: postLoader === true ? "not-allowed" : "pointer",
                      },
                      // color: "teal",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      handlePostType(1, "photo");
                    }}
                  />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon
                    sx={{
                      ":hover": {
                        cursor: postLoader === true ? "not-allowed" : "pointer",
                      },
                      color: "#637381",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      handlePostType(1, "photo");
                    }}
                  />
                )}
                <h3 className="text-sm lg:text-lg">Image</h3>
              </li>
              <li className="flex items-center px-2 pt-1 space-x-2">
                {postType[2] === true ? (
                  <CheckBoxOutlinedIcon
                    sx={{
                      ":hover": {
                        cursor: postLoader === true ? "not-allowed" : "pointer",
                      },
                      // color: "teal",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      handlePostType(2, "video");
                    }}
                  />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon
                    sx={{
                      ":hover": {
                        cursor: postLoader === true ? "not-allowed" : "pointer",
                      },
                      color: "#637381",
                      fontSize: 15,
                    }}
                    onClick={() => {
                      handlePostType(2, "video");
                    }}
                  />
                )}
                <h3 className="text-sm lg:text-lg">Video</h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(ProffesionalSkillFilter);
