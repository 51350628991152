import React from 'react';
import Thumbnail from "../Thumbnail.png";

function A({ fields }) {
    const imgSrc = fields[0]?.response || Thumbnail; // Use Thumbnail if fields[0].response is empty

    return (
        <div>
            <img 
                loading="lazy" 
                alt="cover img" 
                src={imgSrc} 
                className="w-full md:h-auto rounded-t-lg aspect-[2.27] object-cover" 
            />
        </div>
    );
}

export default A;
