import React from 'react'

function C({ fields }) {
    function formatDate(dateString) {
        // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec",
        ];
        const dateParts = dateString.split("-");
        const year = dateParts[0].slice(2);
        const month = months[parseInt(dateParts[1]) - 1];
        const day = parseInt(dateParts[2].split(" ")[0]);
        const time = dateParts[2].split(" ")[2];
        const hour = parseInt(time.split(":")[0]);
        const minute = parseInt(time.split(":")[1]);
        const period = hour >= 12 ? "pm" : "am";
        const hour12 = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour;

        return `${day} ${month} ${year}, ${hour12}:${minute < 10 ? '0' + minute : minute} ${period}`;
    }
    return (
        <>
            <div className="md:hidden grid grid-cols-2 md:grid-cols-1 gap-y-4 p-3 pt-0">
                {fields.map((field, index) => {
                    return (<div key={index}>
                        {<div className="border-t-[0.5px] border-[#E4E4E4] dark:border-[#919EAB52] border-opacity-35 col-span-2"></div>}
                        <div className="flex gap-2 items-start justify-start mt-3">
                            <div className='pt-[0.1rem]'>
                                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/3620a2f7d7715589ab912a69f9b3c186f3e2034a5f15bec4ad3ff36536a5c1f3?apiKey=3fb58597d6194b3d87a8416bcc508a07&" alt="" />
                            </div>
                            <div className='text-primary'>
                                <p className="text-[0.7rem] xl:text-sm lg:text-xs font-gilroy-medium text-compPrimary">{field.title}</p>
                                {field.fieldType === "date" ? <p className="text-xs text-compSec xl:text-sm lg:text-xs font-gilroy-semibold ">{formatDate(field.response)}</p> : <h3 className="text-xs text-compSec md:text-sm font-gilroy-semibold ">{field.response}</h3>}
                            </div>
                        </div>
                    </div>)
                })}
            </div>

            <div className="hidden md:grid grid-cols-2 md:grid-cols-1 gap-4 p-1 mr-2 overflow-x-none">
                {fields.map((field) => {
                    return (<div className="flex gap-2 items-start justify-start mr-8">
                        <div className='mt-0.5'>
                            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/3620a2f7d7715589ab912a69f9b3c186f3e2034a5f15bec4ad3ff36536a5c1f3?apiKey=3fb58597d6194b3d87a8416bcc508a07&" alt="" />
                        </div>
                        <div className='text-primary '>
                            <p className="text-[0.5rem]  font-gilroy  dark:text-[#9C9C9D] text-compPrimary md:text-xs  whitespace-nowrap">{field.title}</p>
                            {field.fieldType === "date" ? <h3 className="text-xs text-secondary xl:text-sm font-gilroy whitespace-nowrap">{formatDate(field.response)}</h3> : <h3 className="text-xs xl:text-sm text-secondary font-gilroy-semibold ">{field.response}</h3>}
                        </div>
                    </div>)
                })}
            </div>

        </>
    )
}

export default C