import React, { useEffect, useState } from "react";
import axios from "axios";
import { path } from "../../path";
import { useSelector } from "react-redux";
const UserSelection = ({ title, setJudgeModel, setJudges,judges }) => {
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [searchedUser, setSearchedUser] = useState("");
  const [users, setUsers] = useState([]);
  async function findUser() {
    if (searchedUser) {
      const config = {
        host: `${path}`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${path}/specificUser?search=${searchedUser}`,
        config
      );
      setUsers(response.data.user);
    }
  }
  useEffect(() => {
    findUser();
  }, [searchedUser]);
  return (
    <div className="bg-secondary p-2 rounded-md">
      <div className="top-2 flex justify-between w-full ">
        <div>Select {title}</div>
        <div
          onClick={() => setJudgeModel(false)}
          className="bg-teal-500 text-white px-2 py-1 rounded-md cursor-pointer">
          Done
        </div>
      </div>
      <input
        value={searchedUser}
        onChange={(e) => setSearchedUser(e.target.value)}
        className="w-full rounded-md p-2 my-2 outline-none"
        placeholder="Search User"
      />
      <div className="h-[20rem] overflow-y-scroll ">
        {users?.map((user) => (
          <div
            key={user._id}
            className="w-full p-2 bg-card my-1 rounded-md cursor-pointer hover:bg-gray-100">
            {title === "Judges" || title === "Organizers" ? (
              <div
                onClick={() => {
                  if (!judges.some(judge => judge._id === user._id)) {
                    setJudges((prevJudges) => [...prevJudges, user]);
                }
                }}>
                {user.username}
              </div>
            ) : (
              <div
                onClick={() => {
                  setJudges(user);
                }}>
                {user.username}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserSelection;
