import React, { useCallback, useEffect, useRef, useState, lazy } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; // Import any desired effect CSS
import blurImg from "../../img/Rectangle 9.png";
import "./VideoPost.css";
import { Skeleton } from "@mui/material";
import {
  FetchPhotoPosts,
  updateImageAction,
  getComments,
} from "../../store/asyncMethods/PostMethods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import Appriciate from "../Appriciate/Appriciate";
import PostComment from "../Comments/PostComment";
import { Link, useHistory } from "react-router-dom";
import Improve from "../Improve/Improve";
import SharePost from "../Share/SharePost";
import axios from "axios";
import { path } from "../../path";
import DeletePostModal from "../Modals/DeletePostModal";
import ReportModal from "../Modals/ReportModal";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import Alert from "../Alert/Alert";
import Cancel from "./img/cancel.svg";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CloseIcon from "@mui/icons-material/Close";
import Man from "../Header/img/Man.svg";
import Female from "../Header/img/Female.svg";
import Others from "../Header/img/Others.svg";
import logoAnt from "../Header/img/Ant.png";
import logoAntDark from "../Header/img/Ant_Dark.png";

import "./PhotoPost.css";
import SingleComment from "./SingleComment";

const PhotoPosts = React.forwardRef(({ post }, ref) => {
  useCallback(() => { }, [post]);
  // useEffect(() => {
  //   console.log("This is a photo post");
  // });

  const { newTempPosts } = useSelector((state) => state?.PostReducer);
  const { comments } = useSelector((state) => state.PostReducer);

  const history = useHistory();
  const { user, token } = useSelector((state) => state?.AuthReducer);
  const dispatch = useDispatch();

  const [viewMoreCmt, setviewMoreCmt] = useState(false);
  const isSuper = user?.isSuperUser;
  // const [allComments, setAllComments] = useState(post?.comments.reverse());
  const [allComments, setAllComments] = useState([]);

  const [slideNumber, setslideNumber] = useState(1);
  const [toggleReport, settoggleReport] = useState(false);
  const [toggleImageCarouselView, settoggleImageCarouselView] = useState(false);
  const [replymodel, setreplymodel] = useState(false);
  const [parentCmt, setparentCmt] = useState();
  const [showreply, setshowreply] = useState(false);
  const [showNreply, setshowNreply] = useState(false);
  const [page, setPage] = useState(1);

  const handleToggleImageCarousel = () => {
    settoggleImageCarouselView(!toggleImageCarouselView);
  };

  // to update appreciate and improve
  const [update, setUpdate] = useState(post?.appreciate?.includes(user?._id));
  const [improveUpdate, setImproveUpdate] = useState(
    post?.improve?.includes(user?._id)
  );
  const [loading, setLoading] = useState(true);

  const handleImageLoaded = () => {
    setLoading(false);
  };
  const _id = user?._id;
  const myInputRef = useRef(null);

  const [deleteModal, setdeleteModal] = useState(false);
  const [reportModal, setreportModal] = useState(false);
  const createdAt = new Date(post?.createdAt);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const changeShowreply = () => {
    setshowreply((perv) => !perv);
  };
  const changeShowNreply = () => {
    setshowNreply((perv) => !perv);
  };

  useEffect(() => {
    if (comments[post._id]) {
      setAllComments(comments[post._id]);
    }
  }, [comments]);

  useEffect(() => {
    // if(page != 1) {
    dispatch(getComments(post?._id, page));
    // }
  }, [page]);

  // console.log("hiiiiiii",post._id,allComments,page,comments)

  const closeRef = useRef();

  const moreClose = useRef();
  const formatTime = (date) => {
    let hours = date?.getHours();
    let minutes = date?.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;

    return strTime;
  };

  const [profilePictureColor, setProfilePictureColor] = useState("");

  //SETS THE STATE TO STORE IMPROVE COUNT
  const [improveLikeCount, setImprovelikeCount] = useState(
    (post?.improve && post?.improve.length) || 0
  );
  //SETS THE STATE TO STORE APPRICIATE COUNT
  const [appriciateLikeCount, setAppriciateLikeCount] = useState(
    (post?.appreciate && post?.appreciate.length) || 0
  );

  //changing colors randomly for name image
  // useEffect(() => {
  //   let color = ["Orange", "Red", "Yellow", "Blue", "Green"];
  //   let i = Math.floor(Math.random() * 5);
  //   setProfilePictureColor(color[i]);
  // }, [profilePictureColor]);

  // console?.log("Count on photo feed:",count);
  const handleViewMoreComments = () => {
    setviewMoreCmt(!viewMoreCmt);
    setPage((prev) => prev + 1);
  };

  // useEffect(() => {
  //   if (redirect) {
  //     dispatch({ type: REDIRECT_FALSE });
  //   }
  //   dispatch(FetchPhotoPosts(_id, count));
  //   setPhotos(photoposts);
  // }, [redirect, photoposts, count]);

  const DeletePost = async (id) => {
    try {
      let conf = window.confirm("Are you sure to delete the post?");
      if (conf) {
        dispatch({ type: "SET_POSTLOADING", payload: true });
        const config = {
          host: `${path}`,
          headers: {
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.delete(`${path}/delete/${id}`, config);
        if (newTempPosts._id == id) dispatch({ type: "CLEAR_NEW_TEMP_POSTS" });
        toast.success("Your post has been deleted successfully!", toastOption);
        dispatch({ type: "SET_POSTLOADING", payload: false });
        // setTrigger((prev) => !prev);
      }
    } catch (error) {
      toast.error("Already deleted!", toastOption);
      dispatch({ type: "SET_POSTLOADING", payload: false });
    }
  };

  const [editImage, setEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [likeImproveRatio, setLikeImproveRatio] = useState([]);

  const [postUploaded, setpostUploaded] = useState(false);
  const [editState, setEditState] = useState({
    image: post?.image,
    caption: post?.caption,
    category: post?.category,
    postType: post?.postType,
    skillType: post?.skillType,
    uniqueId: post?.uniqueId,
  });

  const getPostDetails = async () => {
    const getPost = post;
    editState.caption = getPost?.imageCaption;
    editState.category = getPost?.category;
    editState.postType = getPost?.postType;
    editState.skillType = getPost?.skillType;
    setEditState(editState);
    setImagePreview(getPost.image);
  };
  useEffect(() => {
    getPostDetails();
  }, [post]);
  useEffect(() => {
    const progress = Math.round(
      (appriciateLikeCount / (appriciateLikeCount + improveLikeCount)) * 100
    );
    setLikeImproveRatio(progress);
  }, [appriciateLikeCount, improveLikeCount]);
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: user.theme === "dark" ? "colored" : "light",
  };
  const handleInput = (e) => {
    setEditState({
      ...editState,
      [e.target.name]: e.target.value,
    });
  };
  // Handle Carousel Slider Number
  const handleClickNext = () => {
    if (slideNumber < imagePreview?.length) {
      setslideNumber(slideNumber + 1);
    }
  };
  const handleClickPrev = () => {
    if (slideNumber > 1) {
      setslideNumber(slideNumber - 1);
    }
  };
  const Capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };
  const handleEditImage = async () => {
    settoggleReport(!toggleReport);
    setEditImage(true);
    // document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };
  const handleEditPostForm = async (e) => {
    e?.preventDefault();
    // const formData = new FormData();

    // formData?.append("caption", editState?.caption);
    // formData?.append("category", editState?.category);
    // formData?.append("id", post?._id);
    // formData?.append("postType", editState?.postType);
    // formData?.append("skillType", editState?.skillType);
    // formData?.append("uniqueId", editState?.uniqueId);
    try {
      if (editState?.caption.length > 200) {
        toast("Please limit the caption to 200 character", toastOption);
        return;
      }
      dispatch(
        updateImageAction({
          uniqueId: editState?.uniqueId,
          id: post?._id,
          caption: editState?.caption,
          category: editState?.category,
          skillType: editState?.skillType,
        })
      );

      toast.success("Post has been Successfully updated", toastOption);

      setTimeout(() => {
        setEditImage(false);
      }, 2000);
    } catch (e) {
      console?.log(e);
    }
  };

  useEffect(() => {
    var closeHandler = (e) => {
      if (!closeRef?.current?.contains(e.target)) {
        setEditImage(false);
      }
    };
    document.addEventListener("mousedown", closeHandler);

    return () => {
      document.removeEventListener("mousedown", closeHandler);
    };
  });

  const commentDeleteHandler = async (commentId, postId) => {
    if (window.confirm("Do you want to delete this comment?") === true) {
      setAllComments(allComments?.filter((comm) => comm?.postId !== postId));
      setAllComments(allComments?.filter((comm) => comm?._id !== commentId));
      let res = await axios.post(`${path}/deleteComment`, {
        postId: postId,
        commentId,
      });
    }
  };

  const nestcommentDeleteHandler = async (parentId, cmtID) => {
    const newCmt = allComments.map((comment) => ({
      ...comment,
      child: comment.child.filter((child) => child._id !== cmtID),
    }));

    setAllComments(newCmt);

    const res = await axios.post(`${path}/deletenestedComment`, {
      parentId,
      cmtID,
    });

    // console.log(res);
  };

  const setfocus = () => {
    myInputRef.current.focus();
  };

  const handleCmtClick = (cmt) => {
    // check whether the url in the browser search bar has 'userDetails' or not
    // if not then push the url to the browser search bar

    if (!history.location.pathname.includes("userDetails")) {
      cmt?.userId !== null && cmt?.userId?._id === user?._id
        ? history.push("/profile")
        : history.push(`userDetails/${cmt?.userId?._id}`);
    } else {
      cmt?.userId !== null && cmt?.userId?._id === user?._id
        ? history.push("/profile")
        : history.push(`/${cmt?.userId?._id}`);
    }
  };

  // useEffect(() => {
  //   console.log(parentCmt);
  // }, [parentCmt]);

  // useEffect(() => {
  // }, [post]);
  return (
    <div className="">
      {
        // Multiple image carousel

        toggleImageCarouselView && (
          <div
            className="fixed top-0 left-0 text-primary bg-primary z-30 w-[100%] h-[100%]"
            style={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
            <div className="w-[100%] h-[100%] flex flex-row justify-center items-center">
              <div className="flex flex-col justify-center items-center w-[100%] md:p-4 md:w-[80%] md:h-[70%] z-100 ">
                <div className="w-[100%] h-[70vh] md:h-[80vh] md:w-[100%]">
                  <CarouselProvider
                    naturalSlideWidth={200}
                    naturalSlideHeight={155}
                    orientation="horizontal"
                    totalSlides={post?.image?.length}
                    className="w-[100%] h-[100%] md:w-[100%] md:h-[70vh%] relative">
                    <div className="absolute top-0 right-0 z-10 flex justify-start "></div>
                    <Slider className="w-[100%] h-[100%] md:w-[100%] md:h-[100%]">
                      {Object?.entries(post?.image)?.map((i, k) => {
                        return (
                          <Slide index={i} key={i + "" + k}>
                            <img
                              key={i[1]}
                              src={i[1]}
                              alt="imcagewewq "
                              className="h-[70vh] md:h-[80vh] ml z-100 mx-auto rounded-xl"
                            />{" "}
                          </Slide>
                        );
                      })}
                    </Slider>
                    {post?.image?.length > 1 && slideNumber > 1 && (
                      <ButtonBack className="absolute top-0 left-0 h-full text-white">
                        <ArrowBackIosNewRoundedIcon onClick={handleClickPrev} />
                      </ButtonBack>
                    )}
                    {post?.image?.length > 1 &&
                      slideNumber < post?.image?.length && (
                        <ButtonNext className="absolute top-0 right-0 h-full text-white">
                          <ArrowForwardIosRoundedIcon
                            onClick={handleClickNext}
                          />
                        </ButtonNext>
                      )}
                  </CarouselProvider>
                </div>
                <CloseIcon
                  className="fixed text-red-500 border-2 border-red-500 cursor-pointer top-8 right-8 rounded-2xl"
                  onClick={() => settoggleImageCarouselView((perv) => !perv)}
                />
              </div>
            </div>
          </div>
        )
      }
      {/* edit image */}
      {editImage ? (
        <div className="z-[6]">
          <div
            className="fixed z-[6] top-0 left-0 h-[100%] w-[100%] bg-black opacity-50"
            onClick={() => setreportModal((prev) => !prev)}></div>

          <div
            id="editImage"
            ref={closeRef}
            className="text-primary absolute top-[15vh] left-[5%] w-[90%] sm:left-[25%] sm:right-[25%] sm:w-[50%] lg:left-[30%] lg:right-[30%] lg:w-[40%] xl:left-[35%] xl:right-[35%] xl:w-[30%]  mx-auto p-5 border-1 border-viewMore  shadow-lg rounded-2xl bg-card z-[7]">
            <div>
              <Toaster />
            </div>
            <Helmet>
              <title>Post a Image</title>
              <meta name="description" content="Create a new Blog" />
            </Helmet>
            {!postUploaded ? (
              <form onSubmit={(e) => handleEditPostForm(e)}>
                {/* <div className="text-primary">
                  <h2 className="my-2 text-xl font-bold">Post an Image</h2>
              
                </div> */}

                <div className="flex items-center justify-between text-primary ">
                  <h3 className="my-2 text-xl font-bold ">Edit Post</h3>
                  <div>
                    <Avatar
                      src={Cancel}
                      className="hidden md:block md:cursor-pointer"
                      sx={{ width: 20, height: 20 }}
                      onClick={() => {
                        setEditImage(false);
                      }}
                    />
                  </div>
                </div>
                <div>
                  {!isSuper && (
                    <>
                      <div className="flex flex-row justify-between">
                        <div className="text-primary w-[48%]">
                          <h2 className="font">
                            Select Skill Type
                            <span className="font-bold text-red-600">*</span>
                          </h2>
                          <select
                            required
                            name="skillType"
                            value={editState?.skillType}
                            onChange={(e) => handleInput(e)}
                            className="form-select 
                        block
                        w-full
                        px-3
                        py-1?.5
                        text-base
                        font-normal
                        text-message
                        bg-neutral bg-clip-padding bg-no-repeat
                        border-1 border-viewMore
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-message focus:ring-2 ring-teal-700 outline-none"
                            aria-label="Default select example">
                            <option value="Polished">Polished Skill</option>
                            <option value="Novice">Novice Skill</option>
                          </select>
                        </div>
                        <div className="w-[50%] text-primary">
                          <h2 className="font">
                            Select Type{" "}
                            <span className="font-bold text-red-600">*</span>
                          </h2>
                          <select
                            value={editState?.postType}
                            name="postType"
                            required
                            className="form-select 
                        block
                        w-full
                        px-3
                        py-1?.5
                        text-base
                        font-normal
                        text-message
                        bg-neutral bg-clip-padding bg-no-repeat
                        border-1 border-viewMore
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-message focus:ring-2 ring-teal-700 outline-none"
                            aria-label="Default select example"
                            // value={editState?.category}
                            onChange={(e) => handleInput(e)}
                            id="setPostType">
                            <option value={"community"} key={"community"}>
                              Community
                            </option>
                            <option value={"friend"} key={"friend"}>
                              Peer
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="my-2 text-primary">
                        <h2 className="font">
                          Select Skill{" "}
                          <span className="font-bold text-red-600">*</span>
                        </h2>
                        <select
                          required
                          name="category"
                          value={editState?.category}
                          onChange={(e) => handleInput(e)}
                          id="category"
                          className="form-select 
                            block
                            w-full
                            px-3
                            py-1?.5
                            text-base
                            font-normal
                            text-message
                            bg-neutral bg-clip-padding bg-no-repeat
                            border-1 border-viewMore
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-message focus:ring-2 ring-teal-700 outline-none"
                          aria-label="Default select example">
                          {editState?.skillType === "Polished" &&
                            user?.professionalSkills?.map((s) => {
                              return (
                                <option value={s} key={s}>
                                  {s?.includes("_")
                                    ? Capitalize(s?.split("_")?.join(" "))
                                    : Capitalize(s)}
                                </option>
                              );
                            })}
                          {editState?.skillType === "Novice" &&
                            user?.unprofessionalSkills?.map((s) => {
                              return (
                                <option value={s} key={s}>
                                  {s?.includes("_")
                                    ? Capitalize(s?.split("_")?.join(" "))
                                    : Capitalize(s)}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </>
                  )}
                  <div className="my-3">
                    <input
                      type="text"
                      placeholder="Caption*"
                      required
                      onChange={(e) => handleInput(e)}
                      name="caption"
                      value={editState?.caption}
                      maxLength={200}
                      className=" w-full
                        px-3
                        py-1?.5
                        text-base
                        font-normal
                        text-message
                        bg-neutral bg-clip-padding bg-no-repeat
                        border-1 border-viewMore
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-message focus:ring-2 ring-teal-700 outline-none"
                    />
                  </div>
                  {/* <div
                        //   ref={imgPreview}
                        //   className="w-[100%]"
                        //   style={{ height }}
                        >
                          <img
                            src={imagePreview[count1.current]}
                            alt="imcagewewq "
                            // className="object-contain w-full h-full"
                            style={{ objectFit: "contain" }}
                          />
                        </div> */}

                  {/* {imagePreview?.length > 0 && (
                    <div className="mt-3 rounded-lg border-viewMore border-1">
                      <div className="flex flex-row m-2 justify-center">
                        <div className=" h-[5%] relative">
                          <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={125}
                            totalSlides={imagePreview?.length}
                            className="w-40 h-32 border-2 ">
                            <Slider>
                              {Object?.entries(imagePreview)?.map((i, k) => {
                                return (
                                  <Slide index={i} key={i + "" + k}>
                                    {" "}
                                    <img
                                      key={i[1]}
                                      src={i[1]}
                                      alt="imcagewewq "
                                      className="w-40 h-[65%]"
                          
                                    />{" "}
                                  </Slide>
                                );
                              })}
                            </Slider>
                          </CarouselProvider>
                        </div>
                      </div>
                    </div>
                  )} */}

                  {imagePreview?.length > 0 && (
                    <div className="mt-3 rounded-lg border-viewMore border-1">
                      <div className="flex flex-row m-2 justify-center  h-[15rem]">
                        {imagePreview.map((imageSrc, index) => (
                          <div key={index} className="border-2 m-1 ">
                            <img
                              src={imageSrc}
                              alt={`image_${index}`}
                              className="w-full h-full object-cover rounded-lg"
                            // style={{ maxWidth: '50%', maxHeight: '50%' }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex justify-end">
                  <input
                    type="submit"
                    className="flex items-center justify-center p-2 px-2 m-1 text-white transition duration-200 bg-black border-solid cursor-pointer text-l rounded-3xl hover:bg-teal-600"
                    value="Update"
                  />
                </div>
              </form>
            ) : (
              <div>
                <Alert
                  msg={"Your post has been uploaded Successfully!!"}
                  type="success"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* ############################# */}
      <div ref={ref} id="photoPost" className=" z-[] mb-8">
        {toggleReport && (
          <div className="fixed z-[6] top-0 left-0 flex justify-center h-[100vh] w-[100vw] border">
            <div
              className="fixed z-[6] top-0 left-0 h-[100%] w-[100%] bg-black opacity-50"
              onClick={() => settoggleReport((prev) => !prev)}></div>
            <div className=" z-[7] my-auto border-1 border-viewMore w-[60vw] sm:w-[20vw] h-auto shadow-sm bg-card text-primary rounded-lg">
              {user?._id === post?.userId?._id ? (
                <ul className="">
                  <li
                    onClick={() => handleEditImage()}
                    className="flex rounded justify-center items-center text-primary h-[7vh] bg-compBg hover:bg-neutral rounded-t-md transition duration-200 px-3 py-1 font-semibold  cursor-pointer text-grayish">
                    Edit{" "}
                  </li>
                  <hr className="border-viewMore" />
                  <li
                    className="flex rounded-lg justify-center items-center text-primary h-[7vh] bg-compBg hover:bg-neutral rounded-b-md transition duration-200 px-3 py-1 cursor-pointer font-semibold text-grayish"
                    onClick={() => {
                      settoggleReport(false);
                      // setdeleteModal(true);
                      DeletePost(post?._id);
                    }}>
                    {" "}
                    Delete post{" "}
                  </li>
                </ul>
              ) : (
                <ul>
                  <li
                    onClick={() => {
                      setreportModal(true);
                      settoggleReport(false);
                    }}
                    className="flex rounded-lg justify-center items-center text-primary h-[7vh] bg-compBg hover:bg-neutral rounded-b-md transition duration-200 px-3 py-1 cursor-pointer font-semibold text-grayish">
                    Report
                  </li>
                </ul>
              )}
            </div>
          </div>
        )}
        <div
          className={`z-[0] bg-compBg w-[100%] p-3 sm:w-[100%] md:ml-[0%] md:w-[100%] md:rounded-lg md:border md:border-viewMore`}>
          {deleteModal && (
            <div className="fixed z-[6] top-0 left-0 flex justify-center h-[100vh] w-[100vw] border">
              <div
                className="fixed z-[6] top-0 left-0 h-[100%] w-[100%] bg-black opacity-20"
                onClick={() => setreportModal((prev) => !prev)}></div>
              <div className="z-[7]">
                <DeletePostModal
                  id={post?._id}
                  type="blog"
                  setdeleteModal={setdeleteModal}
                />
              </div>
            </div>
          )}
          {reportModal && (
            // <div className="fixed z-50 top-0 left-0 flex justify-center h-[100vh] w-[100vw] border">
            // <div className="">
            <ReportModal
              id={post?._id}
              type="blog"
              setreportModal={setreportModal}
            />
            // </div>

            // </div>
            //   <ReportModal
            //   id={post?._id}
            //   type="blog"
            //   className="z-[100]"
            //   setreportModal={setreportModal}
            // />
          )}

          <div className="flex flex-row justify-between">
            <div className="flex flex-row justify-start w-[78%]">
              <div className="flex-shrink mx-2">
                {loading && (
                  <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{
                      bgcolor: `${user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                        }`,
                    }}
                  />
                )}
                <div
                  className=""
                  style={{ display: loading ? "none" : "block" }}>
                  {/* {post?.userId?.online && (
                    <div className="w-3 h-3 rounded-full bg-[green] absolute bottom-0 right-0 bord
                    er-[2px] border-white z-10"></div>
                  )} */}
                  {(post.userId !== null && post.userId?.profilePicture) ||
                    (post?.userId?._id == user._id && user.profilePicture) ? (
                    <Link
                      to={
                        post?.userId?._id == user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar
                        src={
                          post.userId?._id == user?._id
                            ? user.profilePicture
                            : post.userId.profilePicture
                        }
                      />
                    </Link>
                  ) : post?.userId?.gender === "female" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={Female} sx={{ width: 40, height: 40 }} />
                    </Link>
                  ) : post?.userId?.gender === "male" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={Man} sx={{ width: 40, height: 40 }} />
                    </Link>
                  ) : post?.userId?.gender === "others" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={Others} sx={{ width: 40, height: 40 }} />
                    </Link>
                  ) : post?.userId?.theme === "dark" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar
                        src={logoAntDark}
                        sx={{ width: 40, height: 40 }}
                      />
                    </Link>
                  ) : (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={logoAnt} sx={{ width: 40, height: 40 }} />
                    </Link>
                  )}
                </div>
              </div>
              <div className="">
                <div className="flex items-center justify-center">
                  <Link
                    to={
                      post.userId?._id === user._id
                        ? "/profile"
                        : `/userDetails/:${post.userId?._id}`
                    }>
                    <h2 className="flex flex-row font-gilroy-semibold text-lg  text-primary w-fit">
                      {post.userName}
                      &nbsp; &nbsp;
                      {/* {editState.postType === "friend" && (
                        <img
                          src={Peerss}
                          alt="Peer"
                          width={25}
                          title="Peer only"></img>
                      )} */}
                    </h2>
                  </Link>
                  <p className="bg-[#D9D9D9] rounded-full w-1 h-1 inline-block text-center"></p>
                  <h2
                    className={`text-xs font-gilroy-semibold ml-2
                      ${post.skillType === "Polished"
                        ? "text-[#008080]"
                        : "text-[#FFC107]"
                      }
                      
                      `}>
                    {/* {createdAt.getDate() +
                    " " +
                    months[createdAt.getMonth()] +
                    " at " +
                    formatTime(createdAt)}{" "} */}
                    {post.uniqueId}
                  </h2>
                </div>

                {post.category !== "master" && (
                  <div className="flex flex-row">
                    <h2
                      // text-${
                      //     post.skillType === "Polished"
                      //       ? "[#008080]"
                      //       : "[#FFC107]"
                      //   }
                      className={`text-xs font-gilroy-semibold text-compPrimary `}>
                      {post.skillType} |{" "}
                      {post.category.includes("_")
                        ? Capitalize(post.category.split("_").join(" "))
                        : Capitalize(post.category)}{" "}
                    </h2>
                    {/* <h2 className="text-xs text-grayish"></h2> */}
                  </div>
                )}
                {/* <h2
                  className={`text-xs font-gilroy-regular
                      ${
                        post.skillType === "Polished"
                          ? "text-[#008080]"
                          : "text-[#FFC107]"
                      }
                      
                      `}>
                  {createdAt.getDate() +
                    " " +
                    months[createdAt.getMonth()] +
                    " at " +
                    formatTime(createdAt)}{" "}
                  {post.uniqueId}
                </h2> */}
              </div>
            </div>
            <div className="flex justify-end  w-[20%]">
              <div className="flex justify-end item-center">
                <button
                  onClick={(e) => {
                    settoggleReport(!toggleReport);
                  }}
                  className={` h-[25%] rounded-full  flex  items-center hover:${post?.skillType === "Polished"
                      ? "border-[#008080]"
                      : "border-yellow-500"
                    } active:bg-hover transition duration-200`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 fill-svg opacity-80"
                    viewBox="0 0 448 512">
                    <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* <hr className="mt-4 mb-0 border-viewMore" /> */}
          {loading && <br></br>}
          {loading && (
            <Skeleton
              sx={{
                bgcolor: `${user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                  }`,
              }}
              variant="rounded"
              width={"60%"}
              height={10}
            />
          )}
          {loading && <br></br>}
          {loading && (
            <Skeleton
              sx={{
                bgcolor: `${user.theme === "dark" ? "rgba(255, 255, 255, 0.2)" : ""
                  }`,
              }}
              variant="rounded"
              width={"60%"}
              height={10}
            />
          )}
          <div className="flex flex-col w-[100%] mt-2 text-primary break-words">
            <p
              className="font-gilroy-medium "
              style={{ display: loading ? "none" : "block" }}>
              {post?.imageCaption}
            </p>
            {/* {!longPost ? post?.imageCaption.slice(0, 500) + "...": post?.imageCaption}
            {(post?.imageCaption.length > 500 && !longPost ) ? (
              <p className="text-[#027575] cursor-pointer font-semibold hover:underline" onClick={()=>setLongPost(prev => !prev)}>read more</p>
            ) : ""} */}
          </div>
          <div className="flex flex-row justify-around mt-2 text-[#37474F]">
            {post?.description}
          </div>
          <h2
            className={`text-xs font-gilroy-regular  text-${post.skillType === "Polished" ? "[#008080]" : "[#FFC107]"
              }`}
            style={{ display: loading ? "none" : "block" }}>
            {createdAt.getDate() +
              " " +
              months[createdAt.getMonth()] +
              " at " +
              formatTime(createdAt)}{" "}
          </h2>
          <div
            className="flex my-2 flex-row sm:w-[100%] md:w-[100%] max-h-[80vh] bg-blue"
            onClick={handleToggleImageCarousel}>
            {post?.image.length <= 2 &&
              post?.image.map((img, index) => {
                return (
                  <div
                    className="flex justify-center mx-1 w-[100%]"
                    key={"img" + index + ""}>
                    {loading && (
                      <Skeleton
                        sx={{
                          bgcolor: `${user.theme === "dark"
                              ? "rgba(255, 255, 255, 0.2)"
                              : ""
                            }`,
                        }}
                        variant="rounded"
                        width={"100%"}
                        height={"60vh"}
                      />
                    )}
                    <img
                      src={img}
                      alt="images"
                      className="aspect-auto  rounded-lg w-[100%] object-contain"
                      onLoad={handleImageLoaded}
                      style={{ display: loading ? "none" : "block" }}
                    />
                    {/* <LazyLoadImage
                      alt={""}
                      className="md:w-[100%] md:h-[100%]"
                      // height={img.height}
                      src={img} // use normal <img> attributes as props
                      placeholderSrc={blurImg}
                      effect="opacity"
                    /> */}
                  </div>
                );
              })}
            {post?.image.length > 2 && (
              <div
                className="flex flex-row justify-evenly"
                onClick={handleToggleImageCarousel}>
                <div className="h-[100%] mx-2">
                  <div className="flex h-[100%]">
                    <LazyLoadImage
                      src={post.image[0]}
                      alt=""
                      className="  md:w-[100%] md:h-[100%]"
                      placeholderSrc={blurImg}
                      effect="opacity"
                    />
                  </div>
                </div>
                <div className="flex flex-col mx-2">
                  <div className="flex mx-1 mb-1 h-[50%]">
                    <img
                      src={post.image[1]}
                      alt=""
                      className="  md:w-[100%] md:h-[100%]"
                    />
                  </div>
                  <div
                    className={`flex mx-1 mt-1  h-[50%] ${post?.image.length > 3 ? "relative" : ""
                      } `}>
                    <img
                      src={post.image[2]}
                      alt=""
                      className={`md:w-[100%] md:h-[100%]  ${post?.image.length > 3 ? "opacity-75" : ""
                        } `}
                    />
                    {post?.image.length > 3 && (
                      <h1 className="text-3xl absolute text-white left-[45%] top-[40%] opacity-100">
                        {" "}
                        +{" "}
                      </h1>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <hr className="mt-3 border-viewMore" />
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center w-full p-1">
              <Appriciate
                post={post}
                appriciateLikeCount={appriciateLikeCount}
                update={update}
                setUpdate={(val) => setUpdate(val)}
                improveUpdate={improveUpdate}
                improveLikeCount={improveLikeCount}
                setImproveUpdate={(val) => setImproveUpdate(val)}
                setImprovelikeCount={(val) => setImprovelikeCount(val)}
                setAppriciateLikeCount={(val) => setAppriciateLikeCount(val)}
              />
            </div>
            <div className="flex flex-row items-center w-full p-1">
              <Improve
                post={post}
                improveLikeCount={improveLikeCount}
                update={update}
                setUpdate={(val) => setUpdate(val)}
                improveUpdate={improveUpdate}
                setImproveUpdate={(val) => setImproveUpdate(val)}
                setImprovelikeCount={(val) => setImprovelikeCount(val)}
                setAppriciateLikeCount={(val) => setAppriciateLikeCount(val)}
                appriciateLikeCount={appriciateLikeCount}
              />
            </div>
            <div className="flex items-center justify-end">
              <SharePost post={post} />
            </div>
          </div>
          <div className="flex w-[100%] h-[2px] mt-2 bg-neutral">
            <div
              className={`bg-green-400 h-[2px]`}
              style={{
                width: `${appriciateLikeCount !== 0 ? likeImproveRatio : 0}%`,
              }}></div>
            <div
              className={`bg-yellow-400 h-[2px]`}
              style={{
                width: `${improveLikeCount !== 0 ? 100 - likeImproveRatio : 0
                  }%`,
              }}></div>
          </div>
          {/* <hr className="w-full mt-2 border-viewMore" /> */}
          <PostComment
            post={post}
            allComments={allComments}
            setAllComments={setAllComments}
            parentCmt={parentCmt}
            myInputRef={myInputRef}
            replymodel={replymodel}
            setreply={setreplymodel}
          />
          {/* <hr className="border-viewMore" /> */}

          <div>
            <ul>
              {!viewMoreCmt &&
                allComments &&
                allComments
                  .slice(0)
                  .slice(0, 2)
                  .map((cmt, index) => {
                    if (cmt?.userId !== null) {
                      return (
                        <SingleComment
                          cmt={cmt}
                          index={index}
                          setparentCmt={setparentCmt}
                          setfocus={setfocus}
                          setreplymodel={setreplymodel}
                          post={post}
                          commentDeleteHandler={commentDeleteHandler}
                          nestcommentDeleteHandler={nestcommentDeleteHandler}
                        />
                      );
                    }
                  })}
            </ul>
          </div>
          <div className="flex flex-col justify-center my-1">
            {viewMoreCmt && (
              <>
                <div className="h-[100%] ">
                  <div className="flex-1 w-[100%]">
                    <ul className="w-[100%] scrollDiv self-center">
                      {allComments &&
                        allComments.slice(0).map((cmt, index) => {
                          if (cmt.userId === null) {
                            console.log("User id null");
                          } else {
                            return (
                              <SingleComment
                                cmt={cmt}
                                index={index}
                                setparentCmt={setparentCmt}
                                setfocus={setfocus}
                                setreplymodel={setreplymodel}
                                post={post}
                                commentDeleteHandler={commentDeleteHandler}
                                nestcommentDeleteHandler={
                                  nestcommentDeleteHandler
                                }
                              />
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
              </>
              // <Suspense>
              //   <MoreComments
              //     allComments={allComments}
              //     user={user}
              //     commentDeleteHandler={commentDeleteHandler}
              //     profilePictureColor={profilePictureColor}
              //     post={post}
              //     setparentCmt={setparentCmt}
              //     setfocus={setfocus}
              //     setreplymodel={setreplymodel}
              //     nestcommentDeleteHandler={nestcommentDeleteHandler}
              //     showreply={showreply}
              //     changeShowreply={changeShowreply}
              //   />
              // </Suspense>
            )}
            {!viewMoreCmt && allComments && allComments?.length > 2 ? (
              <button
                className="p-2 px-3 transition duration-200 rounded-3xl bg-viewMore text-viewMore border-1 border-viewMore hover:bg-neutral"
                onClick={handleViewMoreComments}
                data-testid="viewmore">
                <h2 className="">
                  View {allComments?.length - 2} More{" "}
                  {allComments?.length > 3 ? "Comments" : "Comment"}
                </h2>
              </button>
            ) : (
              ""
            )}
            {viewMoreCmt &&
              allComments?.length !== 0 &&
              allComments?.length > 2 ? (
              <button
                className="p-2 px-3 rounded-3xl bg-viewMore hover:bg-neutral text-viewMore border-1 border-viewMore"
                onClick={handleViewMoreComments}>
                <h2 className="">Show less comments</h2>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div />
      </div>
    </div>
  );
});

export default React.memo(PhotoPosts);
