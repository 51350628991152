import axios from "axios";
import { dispatch } from "react-redux";
import {
  SET_SEARCH,
  SET_SEARCH_LOADER,
  CLOSE_SEARCH_LOADER,
} from "../types/SearchTypes";
import { path } from "./../../path";

export const searchUser = (userName, userId) => {
  return async (dispatch) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: SET_SEARCH_LOADER });
    try {
      dispatch({ type: SET_SEARCH_LOADER });
      const response = await axios.post(
        `${path}/specificUser?search=${userName}`,
        config
      );
      const { data } = response;
      console.log("search data ", response);
      dispatch({ type: SET_SEARCH, payload: data.user });
      if ("user" in data) {
        dispatch({ type: SET_SEARCH, payload: data.user });
        // localStorage.setItem("searchedUser",JSON.stringify(data.user));
        dispatch({ type: CLOSE_SEARCH_LOADER });
      } else {
        //    dispatch({type:SET_MESSAGE,payload:data.msg})
        dispatch({ type: CLOSE_SEARCH_LOADER });
      }
    } catch (error) {
      dispatch({ type: "CLOSE_SEARCH_LOADER" });
      console.log(error);
    }
  };
};
