import React, { useState } from "react";
import { Link } from "react-router-dom";

const SingleComponent = ({ data, id, onClick }) => {
  return (
    <div className="inner mb-2 shadow-md rounded-lg bg-card border border-[#329999]">
      <div className="max-w-sm mx-auto">
        <div className="flex items-start justify-between">
          <div className="flex items-center">
            <div className="ml-4">
              <Link to={`/submissionList/${id}`}>
                <h3 className="text-lg font-semibold text-[#329999]">
                  {data[2].fields[0].response}
                </h3>
              </Link>
            </div>
          </div>
          {/* Add more icons or elements here if needed */}
        </div>
      </div>
    </div>
  );
};

export default SingleComponent;
