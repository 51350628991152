import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import {
  FetchPhotoPosts,
  updateAction,
  getComments,
} from "../../store/asyncMethods/PostMethods";
import { useDispatch, useSelector } from "react-redux";
import Cancel from "./img/cancel.svg";
import "./VideoPost.css";
import Peerss from "./img/Peer.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./BlogPost.css";
import { REDIRECT_FALSE } from "../../store/types/postTypes";
import { Avatar } from "@mui/material";
import Appriciate from "../Appriciate/Appriciate";
import Improve from "../Improve/Improve";

import { Link, useHistory } from "react-router-dom";
import SharePost from "../Share/SharePost";
import PostComment from "../Comments/PostComment";
import axios from "axios";
import { path } from "../../path";
import Alert from "../Alert/Alert";
import DeletePostModal from "../Modals/DeletePostModal";
import ReportModal from "../Modals/ReportModal";
import ReactQuill from "react-quill";
import { Helmet } from "react-helmet";
import Man from "../Header/img/Man.svg";
import Female from "../Header/img/Female.svg";
import Others from "../Header/img/Others.svg";
import logoAnt from "../Header/img/Ant.png";
import logoAntDark from "../Header/img/Ant_Dark.png";
import SingleComment from "./SingleComment";

const BlogPosts = React.forwardRef(({ post }, ref) => {
  const { redirect, ReportedPosts, newTempPosts } = useSelector(
    (state) => state.PostReducer
  );
  const { user, token } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const { photoposts } = useSelector((state) => state.FetchPosts);
  const { comments } = useSelector((state) => state.PostReducer);
  // console.log("blogposts : ", post);
  const isSuper = user.isSuperUser;
  const [count, setCount] = useState(0);
  const [totlal, setToalL] = useState(500);
  const [viewMoreCmt, setviewMoreCmt] = useState(false);
  const _id = user._id;
  const [photos, setPhotos] = useState([]);
  const [newCmt, setnewCmt] = useState([]);
  // const [allComments, setAllComments] = useState(post.comments.reverse());
  const [allComments, setAllComments] = useState([]);
  const [paging, setPaging] = useState(1);
  // to update appreciate and improve

  const [replymodel, setreplymodel] = useState(false);
  const [parentCmt, setparentCmt] = useState();

  const myInputRef = useRef(null);

  const [page, setPage] = useState(1);
  const [widths, setWidths] = useState(20);

  const [update, setUpdate] = useState(post.appreciate.includes(user._id));
  const [improveUpdate, setImproveUpdate] = useState(
    post.improve.includes(user._id)
  );
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: user.theme === "dark" ? "colored" : "light",
  };
  const [deleteOptions, setdeleteOptions] = useState(false);
  const [deletecommentid, setdeletecommentOptions] = useState(false);

  const [deleteModal, setdeleteModal] = useState(false);
  const [reportModal, setreportModal] = useState(false);
  const isProfilePage = window.location.href.endsWith("profile");
  const closeRef = useRef();
  const moreClose = useRef();
  const createdAt = new Date(post?.createdAt);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleReadMoreClick = (e) => {
    e.preventDefault();
    setToalL(post.body.length);
  };

  const nestcommentDeleteHandler = async (parentId, cmtID) => {
    const newCmt = allComments.map((comment) => ({
      ...comment,
      child: comment.child.filter((child) => child._id !== cmtID),
    }));

    // console.log(newCmt);
    setAllComments(newCmt);

    const res = await axios.post(`${path}/deletenestedComment`, {
      parentId,
      cmtID,
    });

    // console.log(res);
  };

  const setfocus = () => {
    myInputRef.current.focus();
  };

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;

    return strTime;
  };

  const [toggleReport, settoggleReport] = useState(false);
  const [profilePictureColor, setProfilePictureColor] = useState("");
  const [improveLikeCount, setImprovelikeCount] = useState(
    (post?.improve && post?.improve.length) || 0
  );

  const [appriciateLikeCount, setAppriciateLikeCount] = useState(
    (post?.appreciate && post?.appreciate.length) || 0
  );

  const [likeImproveRatio, setLikeImproveRatio] = useState([]);

  useEffect(() => {
    const progress = Math.round(
      (appriciateLikeCount / (appriciateLikeCount + improveLikeCount)) * 100
    );
    setLikeImproveRatio(progress);
  }, [appriciateLikeCount, improveLikeCount]);
  // useEffect(() => {
  //   console.log("This is blog post");
  // }, []);
  //changing colors randomly for name image
  // useEffect(() => {
  //   let color = ["Orange", "Red", "Yellow", "Blue", "Green"];
  //   let i = Math.floor(Math.random() * 5);
  //   setProfilePictureColor(color[i]);
  // }, [profilePictureColor]);
  // console.log("Count on photo feed:",count);
  const handleViewMoreComments = () => {
    setviewMoreCmt(!viewMoreCmt);
    setPaging((prev) => prev + 1);
  };

  // Delete post using ID
  const DeletePost = async (id) => {
    try {
      let conf = window.confirm("Are you sure to delete the post?");
      if (conf) {
        dispatch({ type: "SET_POSTLOADING", payload: true });
        const config = {
          host: `${path}`,
          headers: {
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.delete(`${path}/deletePost/${id}`, config);
        // alert("Your post has been deleted successfully!!");
        if (newTempPosts._id == id) dispatch({ type: "CLEAR_NEW_TEMP_POSTS" });
        toast.success("Your post has been deleted successfully!", toastOption);
        dispatch({ type: "SET_POSTLOADING", payload: false });
      }
    } catch (error) {
      toast.error("Already deleted!", toastOption);
      dispatch({ type: "SET_POSTLOADING", payload: false });
      console.log(error);
    }
  };

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  useEffect(() => {
    if (redirect) {
      dispatch({ type: REDIRECT_FALSE });
    }
    dispatch(FetchPhotoPosts(_id, count));
    setPhotos(photoposts);
    // setPhotos([...photos, photoposts[0]]);
  }, [redirect, photoposts, count]);
  useEffect(() => { }, [newCmt]);

  // infinite comment logic ------->
  useEffect(() => {
    if (comments[post._id]) {
      setAllComments(comments[post._id]);
    }
  }, [comments]);

  useEffect(() => {
    // if(page != 1){
    dispatch(getComments(post?._id, paging));
    // }
  }, [paging]);

  // ####################################
  const [editBlog, setEditBlog] = useState(false);
  const [value, setValue] = useState(post.body);

  const [editState, setEditState] = useState({
    title: post.title,
    body: post.body,
    // image: post.,
    postType: post.postType,
    skillType: post.skillType,
    category: post.category,
  });
  const [postUploaded, setpostUploaded] = useState(false);
  var toolbarOptions = [["bold", "italic", "underline", "strike"], ["link"]];
  var cleanedText = editState?.body?.replace(/<\/?[^>]+(>|$)/g, "").trim();
  const [charcount, setCharCount] = useState(cleanedText?.length);
  const handleInput = (e) => {
    if (e.target.name === "category") {
      editState.skillType = user.professionalSkills.includes(e.target.value)
        ? "Polished"
        : "Novice";
      setEditState({
        ...editState,
        skillType: user.professionalSkills.includes(e.target.value)
          ? "Polished"
          : "Novice",
      });
    }
    setEditState({
      ...editState,
      [e.target.name]: e.target.value,
    });
  };
  function hasValidText(text) {
    var cleanedText = text?.replace(/<\/?[^>]+(>|$)/g, "").trim();

    var pattern = /[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    return pattern.test(cleanedText);
  }
  const nextPage = (e) => {
    e.preventDefault();

    setWidths(50);
    setPage(2);
  };
  const prevPage = (e) => {
    e.preventDefault();
    setWidths(10);
    setPage(1);
  };
  const handleBlogEdit = () => {
    settoggleReport(!toggleReport);
    setEditBlog(true);
  };

  const commentDeleteHandler = async (commentId, postId) => {
    if (window.confirm("Do you want to delete this comment?") === true) {
      setAllComments(allComments.filter((comm) => comm.postId !== postId));
      setAllComments(allComments.filter((comm) => comm._id !== commentId));
      let res = await axios.post(`${path}/deleteComment`, {
        postId: postId,
        commentId,
      });
    }
  };
  function replaces(text) {
    text = text.replace('<a href="http://', '<a href="');
    text = text.replace('<a href="', '<a href="http://');
    console.log(text);

    return text;
  }
  function hasValidText(text) {
    var cleanedText = text?.replace(/<\/?[^>]+(>|$)/g, "").trim();

    var pattern = /[A-Za-z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

    return pattern.test(cleanedText);
  }
  function removeExtraHtmlTags(input) {
    const regex = /<[^>]+>/g;
    const output = input?.replace(regex, "");
    return output?.replace(/^\s+|\n\s+|\n\s+$/gm, "");
  }

  const handleBlogForm = (e) => {
    e.preventDefault();

    if (!hasValidText(value)) {
      alert("Please write something in your blog");
      return;
    }
    if (editState.title.trim().length < 5) {
      alert("Please write a title of atleast 5 characters");
      return;
    }

    if (charcount > 1500) {
      alert("You can't post more than 1500 characters");
      return;
    }
    dispatch(
      updateAction({
        title: editState.title.trim(),
        body: replaces(value),
        category: editState.category,
        id: post._id,
        postType: editState.postType,
        skillType: editState.skillType,
      })
    );
    setTimeout(() => {
      setEditBlog(false);
      toast.success("Post has been Successfully updated", toastOption);
    }, 2000);
  };

  const handleEditorChange = (content, _, __, editor) => {
    const strippedContent = content?.replace(/(<([^>]+)>)/gi, "");

    const charCount = strippedContent.trim().length;

    setValue(content);

    setCharCount(charCount);
  };

  useEffect(() => {
    var closeHandler = (e) => {
      if (!closeRef?.current?.contains(e.target)) {
        setEditBlog(false);

        setEditState({
          title: post.title,
          body: post.body,
          // image: post.,
          postType: post.postType,
          skillType: post.skillType,
          category: post.category,
        });
      }
    };
    document.addEventListener("mousedown", closeHandler);

    return () => {
      document.removeEventListener("mousedown", closeHandler);
    };
  });

  return (
    <>
      {editBlog ? (
        <div className="z-[6]">
          <div
            className="text-primary fixed top-0 left-0 w-[100vw] h-[100vh] bg-card opacity-80 z-[6]"
            onClick={() => setEditBlog(false)}></div>
          <div
            ref={closeRef}
            className={`text-primary absolute top-20 left-[5%] sparkss ${widths === 20 ? "w-[60%]" : "w-[80%]"
              } sm:left-[25%] sm:right-[25%] sm:${widths === 20 ? "w-[60%]" : "w-[80%]"
              } lg:left-[30%] lg:right-[30%] lg:${widths === 20 ? "w-[40%]" : "w-[40%]"
              } xl:left-[15%] xl:right-[15%] xl:${widths === 20 ? "w-[30%]" : "w-[40%]"
              } mx-auto p-5 border rounded-2xl okk bg-card z-[7] `}>
            <Helmet>
              <title>Create New Blog</title>
              <meta name="description" content="Create a new Blog" />
            </Helmet>
            {!postUploaded ? (
              <form onSubmit={(e) => handleBlogForm(e)}>
                {/* <div>
                  <h2 className="my-2 text-xl font-bold">Edit Content</h2>
                  
                </div> */}
                <div className="flex items-center justify-between text-primary ">
                  <h3 className="my-2 text-xl font-bold ">Edit Content</h3>
                  <div>
                    <Avatar
                      src={Cancel}
                      className="hidden md:block md:cursor-pointer"
                      sx={{ width: 20, height: 20 }}
                      onClick={() => {
                        setEditBlog(false);
                      }}
                    />
                  </div>
                </div>
                <div>
                  {!isSuper && (
                    <>
                      {page === 1 && (
                        <div className="firsts text-primary ">
                          <div className="w-[50%] skillof ">
                            <h2 className="text-primary font">
                              Select Skill{" "}
                              <span className="font-bold text-red-600">*</span>{" "}
                            </h2>
                            <select
                              name="category"
                              value={editState.category}
                              required
                              onChange={(e) => handleInput(e)}
                              id="category"
                              className="form-select 
                              block
                              w-full
                              px-3
                              py-1.5
                              text-[0.7rem]
                              md:text-base
                            font-normal
                            text-message
                            bg-card
                            bg-clip-padding bg-no-repeat
                            border border-viewMore border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-message focus:bg-card focus:border-blue-600 focus:outline-none"
                              aria-label="Default select example">
                              {/* <option>Select Skill </option> */}
                              <optgroup label="Polished Skills">
                                {user?.professionalSkills?.map((allSkills) => {
                                  return (
                                    <option value={allSkills} key={allSkills}>
                                      {allSkills.includes("_")
                                        ? Capitalize(
                                          allSkills.split("_").join(" ")
                                        )
                                        : Capitalize(allSkills)}
                                    </option>
                                  );
                                })}
                              </optgroup>
                              <optgroup label="Novice skills">
                                {user?.unprofessionalSkills?.map(
                                  (allSkills) => {
                                    return (
                                      <option value={allSkills} key={allSkills}>
                                        {allSkills.includes("_")
                                          ? Capitalize(
                                            allSkills.split("_").join(" ")
                                          )
                                          : Capitalize(allSkills)}
                                      </option>
                                    );
                                  }
                                )}
                              </optgroup>
                            </select>
                          </div>
                          <div className="statesss"></div>
                          <div className="w-[50%] text-primary skillof">
                            <h2 className="font">
                              Select Type{" "}
                              <span className="font-bold text-red-600">*</span>
                            </h2>
                            <select
                              required
                              className="form-select 
                            block
                            w-full
                            px-3
                            py-1.5
                            text-[0.7rem]
                            md:text-base
                            font-normal
                            text-message
                            bg-card
                            bg-clip-padding bg-no-repeat
                            border border-viewMore border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-message focus:bg-card focus:border-blue-600 focus:outline-none"
                              aria-label="Default select example"
                              name="postType"
                              value={editState.postType}
                              onChange={(e) => handleInput(e)}
                              id="PostType">
                              {/* <option>Select Post Type</option> */}
                              <option value={"community"} key={"community"}>
                                Community
                              </option>
                              <option value={"friend"} key={"friend"}>
                                Peer
                              </option>
                            </select>
                          </div>
                        </div>
                      )}
                      {page === 1 && (
                        <div className="my-4 ">
                          <input
                            disabled
                            placeholder="Skill-type *"
                            className="w-full p-2 border rounded bg-accountInfo text-grayish skilltype border-viewMore"
                            value={editState.skillType}></input>

                          <div className="flex justify-center ">
                            <input
                              onClick={(e) => nextPage(e)}
                              type="button"
                              className="border-solid cursor-pointer contentbtn text-xl my-2 py-1 px-4 rounded-md bg-black border border-viewMore text-white flex items-center justify-center w-[50%] "
                              value="Edit ">
                              {/* Post{" "} */}
                            </input>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {page === 2 && (
                    <div className="relative my-3 Title">
                      <input
                        type="text"
                        required
                        placeholder="Title*"
                        maxLength={100}
                        onChange={(e) => handleInput(e)}
                        name="title"
                        value={editState.title}
                        className=" w-full 
            px-3
            py-1.5
            text-[1.7rem]
            md:text-base
            font-normal
            text-message
            bg-card bg-clip-padding bg-no-repeat
            border border-viewMore border-gray-300
            rounded 
            transition
            ease-in-out
            m-0
            focus:text-message focus:bg-gray
            iput
            "
                      />
                      <div className="bottom-0 right-1">
                        <h3 className="text-xs">
                          {editState.title.length}/100
                        </h3>
                      </div>
                    </div>
                  )}
                </div>
                {page === 2 && (
                  // React Quill
                  <div className="border-t rounded text-message bg-card border-t-solid border-viewMore ">
                    <ReactQuill
                      className="scrollbar testBlog bg-card flex flex-col-reverse md:h-[50vh] h-[53vh] overflow-hidden  dialogs"
                      theme="snow"
                      modules={{ toolbar: toolbarOptions }}
                      value={value}
                      onChange={handleEditorChange}
                      placeholder="Write your Blog here"
                    />

                    {/* <TextEditorModal  value={value} setValue={setValue}></TextEditorModal> */}
                    {charcount < 1500 ? (
                      <div className="right-0 bottom">{charcount}/1500</div>
                    ) : (
                      <div className="right-0 bottom charc">
                        {charcount}/1500
                      </div>
                    )}

                    <button
                      onClick={(e) => prevPage(e)}
                      className="flex items-center cbc justify-center px-4  text-white  border border-solid rounded-md cursor-pointer border-viewMore ">
                      Back
                    </button>
                  </div>
                )}
                {page === 2 && (
                  <div className="flex justify-end ">
                    <input
                      // onClick={createPost}
                      type="submit"
                      className="flex items-center cbc justify-center px-4 py-1 my-2 text-xl text-white  border border-solid rounded-md cursor-pointer border-viewMore "
                      value="Update">
                      {/* Post{" "} */}
                    </input>
                  </div>
                )}
              </form>
            ) : (
              <div>
                <Alert
                  msg={"Your post has been uploaded Succesfully!!"}
                  type="success"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div ref={ref} id="blogPost" className="z-[] mb-8">
        <div
          className={`z-[0] bg-compBg w-[100%] p-3 sm:w-[100%] md:ml-[0%] md:w-[100%] md:rounded-lg md:border md:border-viewMore`}>
          {deleteModal && (
            <div className="fixed z-[6] top-0 left-0 flex justify-center h-[100vh] w-[100vw] border">
              <div
                className="fixed z-[6] top-0 left-0 h-[100%] w-[100%] bg-black opacity-20"
                onClick={() => setreportModal((prev) => !prev)}></div>
              <div className="z-[7]">
                <DeletePostModal
                  id={post?._id}
                  type="blog"
                  setdeleteModal={setdeleteModal}
                />
              </div>
            </div>
          )}
          {reportModal && (
            // <div className="fixed z-50 top-0 left-0 flex justify-center h-[100vh] w-[100vw] border">
            // <div className="">
            <ReportModal
              id={post?._id}
              type="blog"
              setreportModal={setreportModal}
            />
            // </div>

            // </div>
            //   <ReportModal
            //   id={post?._id}
            //   type="blog"
            //   className="z-[100]"
            //   setreportModal={setreportModal}
            // />
          )}
          <div className="flex flex-row justify-between">
            <div className="flex flex-row justify-start w-[78%]">
              <div className="flex-shrink mx-2">
                <div className="">
                  {/* {post?.userId?.online && (
                    <div className="w-3 h-3 rounded-full bg-[green] absolute bottom-0 right-0 border-[2px] border-white z-10"></div>
                  )} */}
                  {(post.userId !== null && post.userId?.profilePicture) ||
                    (post?.userId?._id == user._id && user.profilePicture) ? (
                    <Link
                      to={
                        post?.userId?._id == user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar
                        src={
                          post.userId?._id == user?._id
                            ? user.profilePicture
                            : post.userId.profilePicture
                        }
                      />
                    </Link>
                  ) : post?.userId?.gender === "female" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={Female} sx={{ width: 40, height: 40 }} />
                    </Link>
                  ) : post?.userId?.gender === "male" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={Man} sx={{ width: 40, height: 40 }} />
                    </Link>
                  ) : post?.userId?.gender === "others" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={Others} sx={{ width: 40, height: 40 }} />
                    </Link>
                  ) : post?.userId?.theme === "dark" ? (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar
                        src={logoAntDark}
                        sx={{ width: 40, height: 40 }}
                      />
                    </Link>
                  ) : (
                    <Link
                      to={
                        post?.userId?._id === user?._id
                          ? "/profile"
                          : `/userDetails/${post?.userId?._id}`
                      }>
                      <Avatar src={logoAnt} sx={{ width: 40, height: 40 }} />
                    </Link>
                  )}
                </div>
              </div>
              <div className="">
                <div className="flex items-center justify-center">
                  <Link
                    to={
                      post.userId?._id === user._id
                        ? "/profile"
                        : `/userDetails/:${post.userId?._id}`
                    }>
                    <h2 className="flex flex-row font-gilroy-semibold text-lg  text-primary w-fit">
                      {post.userName}
                      &nbsp; &nbsp;
                      {editState.postType === "friend" && (
                        <img
                          src={Peerss}
                          alt="Peer"
                          width={25}
                          title="Peer only"></img>
                      )}
                    </h2>
                  </Link>
                  <p className="bg-[#D9D9D9] rounded-full w-1 h-1 inline-block text-center"></p>
                  <h2
                    className={`text-xs font-gilroy-semibold ml-2
                      ${post.skillType === "Polished"
                        ? "text-[#008080]"
                        : "text-[#FFC107]"
                      }
                      
                      `}>
                    {/* {createdAt.getDate() +
                    " " +
                    months[createdAt.getMonth()] +
                    " at " +
                    formatTime(createdAt)}{" "} */}
                    {post.uniqueId}
                  </h2>
                </div>

                {post.category !== "master" && (
                  <div className="flex flex-row">
                    <h2
                      // text-${
                      //     post.skillType === "Polished"
                      //       ? "[#008080]"
                      //       : "[#FFC107]"
                      //   }
                      className={`text-xs font-gilroy-semibold text-compPrimary `}>
                      {post.skillType} |{" "}
                      {post.category.includes("_")
                        ? Capitalize(post.category.split("_").join(" "))
                        : Capitalize(post.category)}{" "}
                    </h2>
                    {/* <h2 className="text-xs text-grayish"></h2> */}
                  </div>
                )}
                {/* <h2
                  className={`text-xs font-gilroy-regular
                      ${
                        post.skillType === "Polished"
                          ? "text-[#008080]"
                          : "text-[#FFC107]"
                      }
                      
                      `}>
                  {createdAt.getDate() +
                    " " +
                    months[createdAt.getMonth()] +
                    " at " +
                    formatTime(createdAt)}{" "}
                  {post.uniqueId}
                </h2> */}
              </div>
            </div>
            <div className="flex justify-end w-[20%]">
              <div className="flex justify-end item-center">
                {/* <button
                  ref={moreClose}
                  onClick={() => {
                    settoggleReport(!toggleReport);
                  }}
                  className={`border-slate-300 border-2 rounde-full flex items-center hover:${
                    post.skillType === "Polished"
                      ? "border-[#008080]"
                      : "border-yellow-500"
                  }`}>
                  <MoreHorizIcon
                    className={`text-grayish hover:${
                      post.skillType === "Polished"
                        ? "text-[#008080]"
                        : "text-yellow-500"
                    }`}
                  />
                </button> */}
                <button
                  onClick={(e) => {
                    settoggleReport(!toggleReport);
                  }}
                  className=" h-[50%] rounded-3xl  flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 fill-svg"
                    viewBox="0 0 448 512">
                    <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" />
                  </svg>
                </button>
                {toggleReport && (
                  <div className="fixed z-[6] top-0 left-0 flex justify-center h-[100vh] w-[100vw] border">
                    <div
                      className="fixed z-[6] top-0 left-0 h-[100%] w-[100%] bg-black opacity-50"
                      onClick={() => settoggleReport((prev) => !prev)}></div>
                    <div className=" z-[7] my-auto border-1 border-viewMore w-[60vw] sm:w-[20vw] h-auto shadow-sm bg-card text-primary rounded-lg">
                      {user._id === post.userId?._id ? (
                        <ul className="">
                          <li
                            onClick={() => handleBlogEdit()}
                            className="flex rounded justify-center items-center text-primary h-[7vh] bg-compBg hover:bg-neutral rounded-t-md transition duration-200 px-3 py-1 font-semibold  cursor-pointer text-grayish">
                            Edit{" "}
                          </li>
                          <hr />
                          <li
                            className="flex rounded-lg justify-center items-center text-primary h-[7vh] bg-compBg hover:bg-neutral rounded-b-md transition duration-200 px-3 py-1 cursor-pointer font-semibold text-grayish"
                            onClick={() => {
                              settoggleReport(false);
                              // setdeleteModal(true);
                              DeletePost(post._id);
                            }}>
                            {" "}
                            Delete post{" "}
                          </li>
                        </ul>
                      ) : (
                        <ul>
                          <li
                            className="flex rounded-lg justify-center items-center text-primary h-[7vh] bg-compBg hover:bg-neutral rounded-b-md transition duration-200 px-3 py-1 cursor-pointer font-semibold text-grayish"
                            onClick={() => {
                              setreportModal(true);

                              settoggleReport(false);
                            }}>
                            {" "}
                            Report{" "}
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <hr className="border-viewMore" /> */}
          <div className="flex flex-col my-2 pppp">
            <h2 className="mb-2 font-gilroy-bold Tt text-secondary dark:text-primary mycontents ">
              {post.title}
            </h2>
            <div className="text-secondary font-gilroy-regular dark:text-primary pppp">
              <p
                className="mycontents "
                dangerouslySetInnerHTML={{
                  __html: post.body?.trim().slice(0, totlal),
                }}></p>
              {post.body.length > 500 && totlal < 501 && (
                <a
                  href="#"
                  style={{ color: "teal", textDecoration: "none" }}
                  onClick={(e) => handleReadMoreClick(e)}>
                  Read More...
                </a>
              )}
            </div>
            <h2 className={`text-xs font-gilroy-regular text-compPrimary`}>
              {createdAt.getDate() +
                " " +
                months[createdAt.getMonth()] +
                " at " +
                formatTime(createdAt)}{" "}
            </h2>
          </div>
          <hr className="border-viewMore" />
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center w-2/5 p-1">
              {/* <Appriciate
                post={post}
                update={update}
                setUpdate={setUpdate}
                improveUpdate={improveUpdate}
                setImproveUpdate={setImproveUpdate}
              /> */}
              <Appriciate
                post={post}
                appriciateLikeCount={appriciateLikeCount}
                update={update}
                setUpdate={(val) => setUpdate(val)}
                improveUpdate={improveUpdate}
                improveLikeCount={improveLikeCount}
                setImproveUpdate={(val) => setImproveUpdate(val)}
                setImprovelikeCount={(val) => setImprovelikeCount(val)}
                setAppriciateLikeCount={(val) => setAppriciateLikeCount(val)}
              />
            </div>
            <div className="flex flex-row items-center w-2/5 p-1">
              {/* <Improve
                post={post}
                update={update}
                setUpdate={setUpdate}
                improveUpdate={improveUpdate}
                setImproveUpdate={setImproveUpdate}
              /> */}
              <Improve
                post={post}
                improveLikeCount={improveLikeCount}
                update={update}
                setUpdate={(val) => setUpdate(val)}
                improveUpdate={improveUpdate}
                setImproveUpdate={(val) => setImproveUpdate(val)}
                setImprovelikeCount={(val) => setImprovelikeCount(val)}
                setAppriciateLikeCount={(val) => setAppriciateLikeCount(val)}
                appriciateLikeCount={appriciateLikeCount}
              />
            </div>
            <div className="flex items-center justify-end w-1/5">
              <SharePost post={post} />
            </div>
          </div>
          <div className="flex w-[100%] h-[2px] mt-2 bg-neutral">
            <div
              className={`bg-green-400 h-[2px]`}
              style={{
                width: `${appriciateLikeCount !== 0 ? likeImproveRatio : 0}%`,
              }}></div>
            <div
              className={`bg-yellow-400 h-[2px]`}
              style={{
                width: `${improveLikeCount !== 0 ? 100 - likeImproveRatio : 0
                  }%`,
              }}></div>
          </div>
          {/* <hr className="border-viewMore" /> */}
          <PostComment
            post={post}
            allComments={allComments}
            setAllComments={setAllComments}
            parentCmt={parentCmt}
            myInputRef={myInputRef}
            replymodel={replymodel}
            setreply={setreplymodel}
          />
          <hr className="border-viewMore" />
          <div className="w-[100%]">
            <ul>
              {!viewMoreCmt &&
                allComments &&
                allComments
                  .slice(0)
                  .reverse()
                  ?.slice(0, 2)
                  .map((cmt, index) => {
                    if (cmt?.userId !== null) {
                      return (
                        <SingleComment
                          cmt={cmt}
                          index={index}
                          key={index}
                          profilePictureColor={profilePictureColor}
                          setparentCmt={setparentCmt}
                          setfocus={setfocus}
                          setreplymodel={setreplymodel}
                          post={post}
                          commentDeleteHandler={commentDeleteHandler}
                          nestcommentDeleteHandler={nestcommentDeleteHandler}
                        />
                      );
                    }
                  })}
            </ul>
          </div>
          <div className="flex flex-col justify-center my-1">
            {viewMoreCmt && (
              <>
                <div className="h-[100%] ">
                  <div className="flex-1 w-[100%]">
                    <ul className=" w-[100%] scrollDiv self-center">
                      {allComments &&
                        allComments.slice(0).map((cmt, index) => {
                          if (cmt.userId === null) {
                            console.log("User id null");
                          } else {
                            return (
                              <SingleComment
                                cmt={cmt}
                                index={index}
                                setparentCmt={setparentCmt}
                                setfocus={setfocus}
                                setreplymodel={setreplymodel}
                                post={post}
                                commentDeleteHandler={commentDeleteHandler}
                                nestcommentDeleteHandler={
                                  nestcommentDeleteHandler
                                }
                              />
                            );
                          }
                        })}
                    </ul>
                  </div>
                </div>
              </>
            )}
            {!viewMoreCmt && allComments && allComments.length > 2 ? (
              <button
                className="p-2 px-3 rounded-3xl bg-viewMore hover:bg-neutral text-viewMore border-1 border-viewMore "
                onClick={handleViewMoreComments}>
                <h2 className="">
                  View {allComments?.length - 2}{" "}
                  {allComments?.length > 3 ? "More Comments" : "More Comment"}
                </h2>
              </button>
            ) : (
              ""
            )}
            {viewMoreCmt ? (
              <button
                className="p-2 px-3 rounded-3xl bg-viewMore hover:bg-neutral text-viewMore border-1 border-viewMore "
                onClick={handleViewMoreComments}>
                <h2 className="">Show less comments</h2>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div />
      </div>
    </>
  );
});

export default React.memo(BlogPosts);
