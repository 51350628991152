import React, { useRef, useState, useEffect } from "react";
// import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from "react-video-recorder";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import getBlobDuration from 'get-blob-duration'
import { toast } from "react-toastify";
import startAudio from "../../../audio/Start.mp3"
import endAudio from "../../../audio/End.mp3"
import Timer from "./Timer";
import Countdown from "./Countdown";

const Actions = ({
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  countdownTime,
  timeLimit,
  onStartRecording,
  onStopRecording,
}) => {
  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null
    }

    // if (isReplayingVideo) {
    //   return (
    //     <button
    //       type='button'
    //       onClick={onStopReplaying}
    //       data-qa='start-replaying'
    //     >
    //       'Use another video'
    //     </button>
    //   )
    // }

    if (isRecording) {
      return (
        <button
          type='button'
          onClick={onStopRecording}
          className="bg-[#008080] p-2 w-[90%] lg:w-[30%] rounded-lg font-gilroy-semibold text-base "
        >
          Stop Recording
        </button>
      )
    }

    if (isCameraOn && streamIsReady) {
      return (
        <button
          type='button'
          onClick={onStartRecording}
          className="bg-[#008080] p-2 w-[90%] lg:w-[30%] rounded-lg font-gilroy-semibold text-base "
        > Start Recording
        </button>
      )
    }
  }

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <div className="absolute left-0 right-0 top-[90%] flex items-center justify-center">{renderContent()}</div>
    </div>
  )
}

const FromVideoRecorder = ({ videoLength, recordingLength, contestId }) => {

  const history = useHistory();
  const audio = new Audio(startAudio);
  const audioEnd = new Audio(endAudio);
  const recorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    console.log(videoLength)
  })

  // useEffect(() => {
  //   if (isRecording) {
  //     // Start a timer to stop recording after 2 minutes
  //     const timer = setTimeout(() => {
  //       stopRecording();
  //     }, 1 * 63 * 1000); // 2 minutes

  //     // Cleanup function to clear the timer if component unmounts or recording stops
  //     return () => clearTimeout(timer);
  //   }
  // }, [isRecording]);

  const stopRecording = () => {
    if (recorderRef.current) {
      // recorderRef.current.turnOffCamera();
      // console.log(recorderRef.current)
      // setIsRecording(false);
    }
  };

  return (
    <VideoRecorder
      isFlipped={false}
      // isOnInitially
      timeLimit={recordingLength * 60 * 1000}
      ref={recorderRef}
      countdownTime={3000}
      isOnInitially={true}
      classname="border-2 rounded-full w-full h-full"
      mimeType="video/webm;codecs=vp8,opus"
      constraints={{
        audio: true,
        video: true
      }}
      onStartRecording={() => {
        audio.play();
        // setIsRecording(true)
      }}

      onStopRecording={
        () => {
          audioEnd.play();
          // stopRecording();
        }
      }
      onRecordingComplete={async (videoBlob) => {
        // Do something with the video...
        // console.log("videoBlob", videoBlob);

        const duration = await getBlobDuration(videoBlob)
        // console.log(duration + ' seconds')

        // history.push("/videoPreview", { videoBlob });

        history.push({
          pathname: '/videoPreview',
          state: { videoBlob, contestId, duration, videoLength }
        });

        // Setpreview(videoBlob)
      }}
      renderActions={Actions}
    /* renderActions={({
      onStartRecording,
      onStopRecording,
      isCameraOn,
      streamIsReady,
      isVideoInputSupported,
      isInlineRecordingSupported,
      thereWasAnError,
      isConnecting,
      isRunningCountdown,
      isReplayingVideo
    }) => {
      console.log({ isReplayingVideo });
      if (
        (!isInlineRecordingSupported && !isVideoInputSupported) ||
        thereWasAnError ||
        isConnecting ||
        isRunningCountdown ||
        isReplayingVideo
      ) {
        return null;
      }
 
      return (
        <div style={{ position: "absolute", bottom: "10%" }}>
          <button
            onClick={() => {
              if (isCameraOn && streamIsReady) {
                onStartRecording();
              }
            }}
          >
            Record
          </button>
          <button onClick={onStopRecording}>Stop</button>
        </div>
      );
    }} */
    />
  );
};

export default FromVideoRecorder;