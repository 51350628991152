// Authentication types
export const SET_LOADER = "SET_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const SET_TOKEN = "SET_TOKEN";
export const REGISTER_ERRORS = "REGISTER_ERRORS";
export const SET_EMAIL = "SET_EMAIL";
export const LOGOUT = "LOGOUT";
export const LOGIN_ERRORS = "LOGIN_ERRORS";
export const CLOSE_LOGIN_ERRORS = "CLOSE_LOGIN_ERRORS";
export const SET_SKILLS = "SET_SKILLS";
export const UNSET_SKILLS = "UNSET_SKILLS";
export const SET_USER = "SET_USER";
export const SET_MESSAGE = "SET_MESSAGE";
export const UNSET_MESSAGE = "UNSET_MESSAGE";
export const SET_SEARCH = "SET_SEARCH";
export const SET_FOLLOW = "SET_FOLLOW";
export const SET_UNFOLLOW = "SET_UNFOLLOW";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_ALTERNATE_EMAIL = "UPDATE_ALTERNATE_EMAIL";
export const USER_DETAILS_EMAIL = "USER_DETAILS_EMAIL";
export const USER_NEW_FRIEND = "USER_NEW_FRIEND";
