import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
} from "@chakra-ui/react";

const FromWrapper = ({
  label,
  isInvalid,
  error,
  children,
  helperText,
  wrapperProps = {},
  touched,
}) => {
  return (
    <FormControl width="100%" isInvalid={isInvalid} {...wrapperProps}>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup width="100%" alignItems="center">
        {children}
      </InputGroup>

      <FormErrorMessage pos={"absolute"} color="red" fontSize=".75rem">
        {error}
      </FormErrorMessage>
      <FormHelperText fontSize="0.75rem" color="gray.400">
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default FromWrapper;
