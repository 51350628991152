import React from "react";

const VideoPreview = ({ video }) => {
  if (!video) {
    return <div className="w-1/2 p-4">Select a video to preview</div>;
  }

  return (
    <div className=" w-1/2 my-3 items-center  ">
      <h2 className="my-3 font-bold">Video Preview</h2>
      <div className="relative flex p-6 justify-center bg-white border border-transparent rounded-xl">
        {" "}
        {/* Aspect ratio 16:9 */}
        <video
          className=" top-0 border border-transparent rounded-xl  w-1/2 h-full"
          controls
          src={video}>
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoPreview;
