import React, { useState, useEffect } from "react";
import deleteIcon from "../Alert/39-trash-solid.gif";
import axios from "axios";
import { path } from "../../path";
import { useDispatch, useSelector } from "react-redux";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const DeletePostModal = ({ id, type, setdeleteModal }) => {
  // console.log("Post id: ", id)
  const { user, token } = useSelector((state) => state.AuthReducer);
  const [deleted, setdeleted] = useState(false);

  // Delete post using ID
  const DeletePost = async () => {
    // console.log("Inside Delete : ", id)
    let conf = true;
    // console.log("Id: ",id,type);
    if (conf) {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      let res;
      if (type == "blog") {
        res = await axios.delete(`${path}/deletePost/${id}`, config);
      } else {
        res = await axios.delete(`${path}/delete/${id}`, config);
      }
      //   console.log("Res: ", res);
      window.location.reload(false);
    }
    setdeleted(true);
    setTimeout(() => {
      setdeleteModal(false);
    }, 1500);
  };

  const handleCancel = () => {
    setdeleteModal(false);
  };
  useEffect(() => {
    //   document.getElementById("delete-modal").style.opacity = 1;
    // document.getElementById("home-content").style.opacity = 0.1;
    return () => {
      // document.getElementById("home-content").style.opacity = 1;
    };
  }, []);
  return (
    <div
      className="absolute  top-20 left-[5%] w-[90%] sm:left-[25%] sm:right-[25%] sm:w-[50%] lg:left-[30%] lg:right-[30%] lg:w-[40%] xl:left-[35%] xl:right-[35%] xl:w-[30%]  mx-auto p-5 border  shadow-lg rounded-2xl bg-card text-primary z-10"
      id="delete-modal">
      <div className="flex flex-row justify-between items-center">
        <h2 className="text-2xl font-bold text-red-600">Delete</h2>
        <button
          className="border-2 border-red-500 rounded-3xl my-1"
          data-testid="deletebtn"
          onClick={handleCancel}>
          <CloseOutlinedIcon className="text-red-400 hover:text-red-600" />
        </button>
      </div>
      <hr className="border-viewMore" />
      {deleted ? (
        <div className="flex flex-row items-center my-4 justify-evenly">
          <img src={deleteIcon} alt="df" className="w-16 h-16" />
          <h2 className="ext-primary bg-primary my-2 font-semibold ">
            Post has been deleted successfully!!
          </h2>
        </div>
      ) : (
        <div>
          <h2 className="text-primary bg-primary my-2 font-semibold">
            Are you sure to delete the post ?
          </h2>
          <div className="flex flex-row">
            <button
              onClick={DeletePost}
              className="border-solid cursor-pointer text-xl m-1 p-2 px-2 rounded-3xl bg-red-400 hover:bg-red-600 text-white flex items-center justify-center hover:bg-teal-600 ">
              {" "}
              Delete
            </button>
            <button
              onClick={handleCancel}
              className="border-solid cursor-pointer text-xl m-1 p-2 px-2 rounded-3xl bg-blue-400 hover:bg-blue-600 text-white flex items-center justify-center hover:bg-teal-600 ">
              {" "}
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeletePostModal;
