import React from 'react'
import CompetitionMobile from './competitionMobile'
import CompetetionDesktop from './CompetetionDesktop'

function AllComp() {
    return (
        <>
            <div className='md:hidden'>
                <CompetitionMobile />
            </div>
            <div className='hidden md:block bg-compBgSec'>
                <CompetetionDesktop />
            </div>
        </>
        
    )
}

export default AllComp