import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { path } from "../../path";
import ClipLoader from "react-spinners/ClipLoader";

const PaymentDetailModal = ({ setIsVisible, userId, toast, displayText }) => {
  const [paymentId, setPaymentId] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const closeModal = () => {
    setIsVisible(false);
    setLoading(false);
  };

  const handlePaymentIdChange = (event) => {
    setPaymentId(event.target.value);
    setIsValid(true);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const upiIdRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+$/;

      if (!upiIdRegex.test(paymentId) || paymentId.trim() === "") {
        setIsValid(false);
        setLoading(false);
        return;
      }
      setIsValid(true);
      const res = await axios.post(`${path}/payment/updatePaymentDetail`, {
        userId,
        paymentId,
      });
      if (res.data.success) {
        toast?.success(res.data.message);
        dispatch({ type: "SET_USER_PAYMENT_DETAIL", payload: paymentId });
      } else {
        throw new Error(res.data.error);
      }
      setLoading(false);
      closeModal();
    } catch (error) {
      setLoading(false);
      console.error("update payment error: " + error);
      if (error.response.status === 400) {
        toast?.error("Invalid UPI ID");
      } else {
        toast?.error("Something went wrong, please try again later.");
      }
    }
  };

  return (
    <>
      <div className="text-primary justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="top-0 left-0 right-0 bg-card text-primary relative w-auto my-6 mx-auto max-w-3xl rounded-xl border-2 border-viewMore">
          {/*content*/}
          <div className="rounded-lg shadow-lg relative flex flex-col w-full justify-center items-center">
            {/*header*/}
            <div className="items-start justify-between p-2 rounded-t">
              <p className="text-2xl text-primary font-normal font-sans">
                Payment Details
              </p>
            </div>
            <hr className="border-viewMore border w-[100%]" />

            <div className="relative px-3 pt-0 flex-auto">
              <p className="my-1 text-secondary text-lg leading-relaxed">
                {displayText}
              </p>
              {!isValid && (
                <span className="text-xs text-red-500 mb-1">
                  Invalid UPI ID
                </span>
              )}
              <input
                type="text"
                className="w-full px-2 py-1.5 rounded-lg outline-none text-primary bg-neutral border-viewMore border-1 focus:ring-2 ring-teal-700"
                placeholder="UPI ID"
                onChange={handlePaymentIdChange}
                value={paymentId}
              />
            </div>
            {/*footer*/}
            <div className="flex items-center justify-between px-3 my-3 pt-0 w-[100%]">
              <button
                className="text-red-500 w-[40%] bg-red-100 font-bold uppercase p-1 text-sm outline-none rounded focus:outline-none ease-linear transition-all duration-150 mr-2 hover:bg-red-300"
                type="button"
                onClick={() => setIsVisible(false)}>
                Cancel
              </button>
              <button
                className="flex items-center justify-center bg-teal-400 hover:bg-teal-500  w-[40%] text-black active:bg-teal-600 font-bold uppercase text-sm p-1 ml-2 rounded shadow hover:shadow-lg outline-none focus:outline-noneease-linear transition-all duration-150"
                type="button"
                onClick={handleSave}
                disabled={loading}>
                {!loading ? (
                  'Save'
                ) : (
                  <ClipLoader
                    color={"#008080"}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default PaymentDetailModal;
