import React, { useEffect } from 'react'
import yellow from "./yellow.png"
import axios from 'axios';
import { path } from '../../path';
import { toast } from 'react-toastify';

function ResultCard({ result, setopenResult, contestId }) {
  const declareResult = async (submissions) => {
    // Convert finalScore to integer and handle empty fields
    const scores = result.map((r) => ({
      id: r.id,
      score: r.score ? parseFloat(r.score) : 0,
    }));



    try {
      const epsilon = 0.000001; // You can adjust the tolerance as needed

      if (
        Math.abs(scores[0].score - scores[1].score) < epsilon ||
        Math.abs(scores[1].score - scores[2].score) < epsilon
      ) {
        console.log(scores);
        alert("Top 3 submission cannot have the same score");
        return;
      }

    } catch (e) {
      console.log("Need 3 submissions atleast");
    }

    // Create an object with ranking
    const resultss = {};
    scores.forEach((submission, index) => {
      resultss[index + 1] = submission.id;
    });
    let results = []
    results = Object.keys(resultss).map(key => ({
      rank: parseInt(key),
      submissionId: resultss[key]
    }));

    console.log(results)
    // Send ranking to result API
    console.log(contestId)
    console.log(results)

    try {
      const res = await axios.post(`${path}/contest/resdeclared`, {
        contestId,
        results,
      });

      console.log("Result declared successfully:", res.data);
      toast.success("Result Declared Succesfully");
      setopenResult(false);
    } catch (error) {
      console.error("Error declaring result:", error);
      toast.error("There is some error in declaring result")
    }
  };

  useEffect(() => {
    // console.log(result)
  })
  return (
    <div className="rounded-t-lg AllFriends bg-primary">
      <div className="AllFriendsContainer w-[80%] sm:w-[30%] border border-viewMore">
        <div className="top flex items-center justify-between rounded-t-[12px]">
          <p className="text-lg text-center font-gilroy-semibold">
            Result Preview
          </p>
          <button
            onClick={() => {
              //   users.close(false);
              setopenResult(false)
            }}>
            ❌
          </button>
        </div>
        {result?.length > 0 ? (
          <div
            className="allfriends flex flex-col py-2 gap-4"
            data-testid="allFriends-div">
            {result?.map((x, index) => {
              if (x == null) return;
              else
                return (
                  <>
                    <div
                      key={index}
                      onClick={() => {
                        //   {
                        //       if (x?._id === user._id) {
                        //           history.push("/profile");
                        //       } else {
                        //           history.push(`/userDetails/${x._id}`);
                        //       }
                        //       users.close(false);
                        //   }
                      }}
                      className="flex justify-between cursor-pointer flex-1 items-center gap-2 p-1.5 md:py-0.5 rounded-md  max-h-[53px]">
                      <div className='flex items-center justify-center gap-2'>
                        {x?.profilePicture !== "" && x?.profilePicture ? (
                          <img
                            loading="lazy"
                            src={x?.profilePicture}
                            alt="profilePic"
                            className="shrink-0 aspect-square rounded-md w-[39px] h-[39px]"
                          />
                        ) : (
                          <img
                            loading="lazy"
                            src={yellow}
                            alt="profilePic"
                            className="shrink-0 aspect-square ml-1 rounded-md w-[29px] h-[29px]"
                          />
                        )}

                        <div className="flex flex-col items-start justify-center">
                          <h2 className="flex-auto my-auto text-compSec font-gilroy-semibold text-xs">
                            {" "}
                            {x.firstName} {x.lastName}{" "}
                          </h2>
                          {/* {MutualFriends(x) == null ? (
                                                  ""
                                              ) : (
                                                  <h3 className="text-[0.6rem] mt-0 text-[#8D8D8D] mx-0">
                                                      {MutualFriends(x)}
                                                  </h3>
                                              )} */}
                        </div>
                      </div>
                      <h2 className='text-teal-700'>{x.score}</h2>
                    </div>
                    {/* <div
                      className=" flex border bg-feedNeutral hover:cursor-pointer hover:bg-neutral transition duration-200 border-x-0 border-viewMore border-t-0 w-[100%] p-[10px] items-center content-center"
                      data-testid="allFriends"
                      onClick={() => {
                        {
                          if (x?._id === user._id) {
                            history.push("/profile");
                          } else {
                            history.push(`/userDetails/${x._id}`);
                          }
                          users.close(false);
                        }
                      }}>
                      <div>
                        {x?.profilePicture ? (
                          <div className="">
                            <Avatar
                              src={x?.profilePicture}
                              className="w-8 h-8 rounded-full"
                            />
                          </div>
                        ) : x.gender === "male" ? (
                          <Avatar src={Man} sx={{ width: 40, height: 40 }} />
                        ) : x.gender === "female" ? (
                          <Avatar src={Female} sx={{ width: 40, height: 40 }} />
                        ) : x.gender === "others" ? (
                          <Avatar src={Others} sx={{ width: 40, height: 40 }} />
                        ) : x.theme === "dark" ? (
                          <Avatar
                            src={logoAntDark}
                            sx={{ width: 40, height: 40 }}
                          />
                        ) : (
                          <Avatar
                            src={logoAnt}
                            sx={{ width: 40, height: 40 }}
                          />
                        )}
                      </div>
                      <div className="flex flex-col mx-2 cursor-pointer h-min">
                        <h2 className="font-semibold">{x?.username}</h2>
                        <p className="text-[0.6rem] text-[#8D8D8D] mx-0">
                          {
                            //x?.username
                          }
                        </p>
                        {MutualFriends(x) == null ? (
                          ""
                        ) : (
                          <h3 className="text-[0.6rem] mt-0 text-[#8D8D8D] mx-0">
                            {MutualFriends(x)}
                          </h3>
                        )}
                      </div>
                    </div> */}
                  </>
                );
            })}
            <button className='bg-teal-600 mt-6 rounded-xl text-white font-gilroy-semibold p-2 ml-1 hover:bg-teal-500 transition-opacity' onClick={() => declareResult()}>Declare</button>
          </div>
        ) : (
          <p className="text-primary">Result not declared</p>
        )}
      </div>
    </div>
  )
}

export default ResultCard