import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
const MarketingAdminRoutes = (props) => {
  const { user } = useSelector((state) => state.AuthReducer);
  return (
    <div>
      {user.isMarketingAdmin ? (
        <Route
          path={props.path}
          exact={props.exact}
          component={props.component}
        />
      ) : (
        <Redirect to="/" from="/skills" />
      )}
    </div>
  );
};

export default MarketingAdminRoutes;
