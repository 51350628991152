import React, { useEffect, useState,useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { path } from "../../path";
import { WebSocketContext } from "../../WebSocket";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";
import "./Judging.css"

const JudgingPanel = () => {
  const { token } = useSelector((state) => state.AuthReducer);
  let { contestId } = useParams();
  console.log(contestId);
  const [submissions, setSubmissions] = useState();
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);

  const [ranking, setRanking] = useState({});
  const [result, setResult] = useState({});
const [contest,setContest] = useState({})
const [marks, setMarks] = useState({});


  useEffect(() => {
    // Fetch contest results when the component mounts
    fetchContestResults();
  }, [contestId]);

  const fetchContestResults = async () => {
    try {
      const response = await axios.get(
        `${path}/contest/contestById/${contestId}`
      );
      const res = await axios.get(`${path}/contest/getContestsbyId/${contestId}`)
      console.log(response, "respose");
      console.log(response.data);
      setContest({...res.data.data})
      
      setResult({ ...response.data.result });

      console.log(result, "results    ");
    } catch (error) {
      console.error("Error fetching contest results:", error);
    }
  };

  const hasKey = (obj, key) => Object.keys(ranking).includes(key);
  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log(value)
    const sanitizedValue = value > 10 ? 10 : value;
  setMarks(prevMarks => ({ ...prevMarks, [id]: sanitizedValue }));
  };
  useEffect(()=>{console.log(ranking);},[ranking])

  const handleSubmit = async () => {
    
    try {
      if(Object.keys(marks).length != submissions.length){
        alert("Please provide marks for all submissions")
        return;
      }
      const marksArray = Object.keys(marks).map(submissionId => ({
        submissionId,
        mark: parseFloat(marks[submissionId])
      }));
    
      // Check if all submissions have marks
      const allSubmissionsMarked = marksArray.every(submission => submission.mark);
    
      if (!allSubmissionsMarked) {
        alert("Please provide marks for all submissions.");
        return;
      }
    
      // Sort marksArray in descending order of marks
      marksArray.sort((a, b) => b.mark - a.mark);
    
    
      const date = new Date();
      const sclose = new Date(contest?.submissionClosingDate)
      console.log(contest)
      if(date<sclose){
        alert("Cannot submit ranks before submission closing time")
        window.location.reload()
        return;
      }
    console.log(marksArray)
      const newRankings = {};
      marksArray.forEach((submission, index) => {
        if (index < 3) {
          newRankings[index + 1] = submission.submissionId;
        }
      });
    setRanking(newRankings)
    
    } catch (error) {
      console.error("Error submitting contest result:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${path}/contest/resdec`, {
          contestId,
          ranking,
        });
        console.log(response);
        console.log(response.data.resUpdate.result);
        setResult(response.data.resUpdate.result);
        setRanking({});
        setMarks({});
        Array.from(document.getElementsByClassName("inputtag")).forEach(
          (ip) => (ip.value = "")
        );
      } catch (error) {
        console.error("Error:", error);
      }
    };
  
    if (Object.keys(ranking).length > 0) {
      fetchData();
    }
  }, [ranking]);
  

  // const [ans,setans] =useState([]);
  useEffect(() => {
    async function getAllSubmissions() {
      const config = {
        host: `${path}`,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.get(
          `${path}/contest/getSubmissionsbyId/${contestId}`,
          config
        );
        console.log(response);
        setSubmissions(response.data.submissions);
        console.log(submissions);
      } catch (err) {
        console.log(err.message);
      }
    }
    getAllSubmissions();
  }, [contestId]);

  useEffect(() => {
    if (submissions && submissions.length > 0) {
      console.log("Welcome");
      const resultSubmissionIds = Object.values(result);
      console.log(resultSubmissionIds);
      const filtered = [];
      for (let i = 0; i < resultSubmissionIds.length; i++) {
        filtered.push(submissions.find((s) => s._id == resultSubmissionIds[i]));
      }
      console.log(filtered);
      setFilteredSubmissions([...filtered]);
      console.log(filteredSubmissions);
    }
    console.log(filteredSubmissions);
  }, [result, submissions]);

  const handleDeleteSubmission = async (submissionId) => {
    console.log(submissionId)
    try {
      const response = await axios.post(`${path}/contest/submissionDelete`, { submissionId });
      console.log(response.data); 
      setSubmissions(submissions.filter(submission => submission._id !== submissionId));
    } catch (error) {
      console.error("Error deleting submission:", error);
    }
  };

  return (
    <div className="overflow-x-auto flex flex-col items-center">
    
    
{submissions?.map((submission, index) => (
  <div className="flex flex-col items-center m-2 border border-gray-300 p-4 w-[40vw]">
    <div className="grid grid-cols-8 grid-rows-2 gap-1 ">
      <div className="w-auto">

    {submission.user.profilePicture ? 
  <img
    src={submission.user.profilePicture}
    alt="Profile"
    className="h-16 w-16 rounded-full"
  />:<img src="yellow.png" className="h-16 w-16 rounded-full"/>
}
      </div>
      
      <div>
      {submission?.user.username}
      <div className="flex justify-between  w-full mb-2 ">
    <p>{submission?.user.username}</p>
    {/* Style the delete button */}
    <button className=" flex justify-end text-right items-end ml-[20rem] mb-10   bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded" onClick={() => handleDeleteSubmission(submission._id)}>Delete</button>
  </div>
        <div className="col-start-2">
        </div>

      <input
        id={submission._id}
        // onKeyDown={(e) => {
          // if (e.key === "Backspace") {
            // e.preventDefault();
          // }
        // }}
        // maxLength={1}
        className="inputtag border border-black mr-2"
        type="numeric"
        placeholder="Enter marks upto 10"
        name={submission._id}
        onChange={handleChange}
      />
        </div>
    </div>
    {/* <br></br> */}
    <div className="cont">

    {submission.mediaType === "image" ? (
      <img
        src={submission.mediaUrl}
        alt="submission"
        className="h-16 w-auto mb-2"
      />
    ) : (
      <video controls className="h-[50vh] w-[50vw] mb-2">
        <source src={submission.mediaUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    )}
    <p className="text-sm">{submission.description}</p>
    </div>
  </div>
))}

        {/* </tbody> */}
      {/* </table> */}

      <br></br>
      <button
        class="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={handleSubmit}>
        Submit
      </button>
      <h2>Contest Results</h2>
      <table className="table-auto border-collapse border border-gray-400">
        <thead>
          <tr>
            <th className="border border-gray-400 px-4 py-2">Rank</th>
            <th className="border border-gray-400 px-4 py-2">name</th>
          </tr>
        </thead>
        <tbody>
          {filteredSubmissions.map((submission, index) => (
            <tr key={submission.id}>
              <td>{index + 1}</td>
              <td>
                <p>{submission.user.username}</p>
                {/* Display other submission details here */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JudgingPanel;
