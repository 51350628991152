import LandingPage from "./Components/LandingPage/LandingPage.js";
import GoogleDetails from "./Components/LandingPage/SignUp/GoogleDetails.js";

import { withRouter } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Components/Header/Header.js";
import AccountInfo from "./Components/AccountInfo/AccountInfo.js";
import { useContext, useEffect, lazy, Suspense, Fragment } from "react";
import PrivateRoutes from "./private/PrivateRoutes.js";
import RouteLinks from "./private/RouteLinks.js";
import Loading from "./Components/Loading/Loading.js";
import EditImage from "./Components/Edit/EditImage.js";
import LoginRouteLink from "./private/LoginRouteLink.js";
import AdminRoutes from "./private/AdminRoutes.js";
import Edit from "./Components/Edit/Edit.js";
import HomePage from "./pages/HomePage/HomePage.js";
import Skillmodel from "./Components/SelectSkills/Skillmodel.jsx";
import ReactGA from "react-ga4";

import FallbackUI from "./Components/Loading/FallbackUI.js";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { WebSocketContext } from "./WebSocket.js";
import PeerInterest from "./Components/PeerInterests/PeerInterest.js";
import SocketNotification from "./Components/SocketNotification/SocketNotification.js";
import CompetitionPanel from "./Components/ContestPanel/Competition.js";
import Competition from "./Components/competition/Competition.js";
import MarketingAdminRoutes from "./private/MarketingAdminRoutes.js";
import JudgingPanel from "./Components/ContestPanel/JudgingPanel.js";
// import CompetitionMobile from "./Components/competition/CompetitionMobile.jsx";
import CompetetionDesktop from "./Components/competition/CompetetionDesktop.js";
import AllComp from "./Components/competition/AllComp.jsx";
import Rewards from "./Components/competition/Rewards.jsx";
import RewardsMobile from "./Components/competition/RewardsMobile.jsx";
import Judges from "./Components/competition/Judges.jsx";
import ContestEdit from "./Components/Edit New Contest/ContestEdit.jsx";

import Submission from "./Components/competition/Submission/Submission.jsx";
import SubmissionsList from "./Components/ContestPanel/SubmissionsList.js";
// import JudgingPanel from "./Components/ContestPanel/JudgingPanel.js";
import CompetitionNew from "./Components/competition/CompetitionNew.jsx";
import CompetitionMobile from "./Components/competition/competitionMobile.jsx";
import Levels from "./Components/Levels/levels-mobile.jsx";
import MyStatistics from "./Components/MyStatistics/my-statistics-mobile.jsx";
import MyStatisticsDesktop from "./Components/MyStatistics/my-statistics-desktop.jsx";

const VideoTrimmer = lazy(() =>
  import("./Components/competition/Submission/VideoTrimmer.jsx")
);
const Timeline = lazy(() => import("./Components/timeline/Timeline.js"));
const Admin = lazy(() => import("./Components/Admin/Admin.js"));
const Policy = lazy(() => import("./pages/PrivacyPolicy/Policy.js"));
const ForgotEmail = lazy(() => import("./Components/Forgot/ForgotEmail.jsx"));
const Messenger = lazy(() =>
  import("./Components/OnlineChatting/Messenger.js")
);
const PageNotFound = lazy(() =>
  import("./Components/PageNotFound/PageNotFound.jsx")
);
const FeedbackForm = lazy(() =>
  import("./Components/FeedbackForm/FeedbackForm.js")
);
const SimilarUser = lazy(() =>
  import("./Components/SimillarUsers/SimilarUser.js")
);
const FriendRequest = lazy(() =>
  import("./Components/FriendRequest/FriendRequest.js")
);
const NewPassword = lazy(() => import("./Components/Forgot/NewPassword.jsx"));
const NotificationPage = lazy(() =>
  import("./Components/NotificationPage/NotificationPage.js")
);
const Users = lazy(() => import("./Components/Admin/Users/Users.js"));
const Profile = lazy(() => import("./pages/profile/Profile.jsx"));
const SignUpForm = lazy(() =>
  import("./Components/LandingPage/SignUp/SignUpForm.jsx")
);
const ForgotPassword = lazy(() =>
  import("./Components/Forgot/ForgotPassword.jsx")
);
const SignUpInitial = lazy(() =>
  import("./Components/LandingPage/SignUp/SignUpInitial.js")
);
const Following = lazy(() => import("./Components/Follow/Following.jsx"));
const Followers = lazy(() => import("./Components/Follow/Followers.jsx"));
const SharePost = lazy(() => import("./Components/Sharepost/SharePost.js"));
const Commentsall = lazy(() => import("./Components/Comments/Commentsall.js"));
const Userdetails = lazy(() =>
  import("./Components/Userdetails/Userdetails.js")
);
const EditVideo = lazy(() => import("./Components/Edit/EditVideo.js"));
const VerifyOtp = lazy(() => import("./Components/Forgot/VerifyOtp.jsx"));
const ReportPage = lazy(() => import("./Components/ReportPage/ReportPage.js"));
const PostComment = lazy(() => import("./Components/Comments/PostComment.js"));
const EditUSkills = lazy(() =>
  import("./Components/SelectSkills/EditUSkills.js")
);
const ProfessionalSkills = lazy(() =>
  import("./Components/SelectSkills/ProfessionalSkills.js")
);
const About = lazy(() => import("./Components/about/About.js"));
const SimilarEmailUser = lazy(() => import("./Components/Forgot/Accounts.js"));
const EditSkills = lazy(() =>
  import("./Components/SelectSkills/EditSkills.js")
);
const TermsCondition = lazy(() =>
  import("./Components/TermsandConditions/TermsandConditions.js")
);
const UnprofessionalSkills = lazy(() =>
  import("./Components/SelectSkills/UnprofessionalSkills.js")
);
const VerifyEmail = lazy(() =>
  import("./Components/LandingPage/SignUp/VerifyEmail.js")
);

function App({ location }) {
  const { user, token, header } = useSelector((state) => state.AuthReducer);
  const [cookie, setCookie] = useCookies(["Theme"]);

  // This is one of the methods can use a library babel-plugin-transform-remove-console configure this in babel config
  if (1 === 0) {
    console.log = function no_console() {};
  }
  useEffect(() => {
    const root = window.document.documentElement;
    // console.log(cookie.Theme);

    if (user?.length !== 0) {
      if (user?.theme === "dark") {
        setCookie("Theme", "dark", { path: "/" });
        root.classList.remove("light");
        root.classList.add("dark");
      } else {
        setCookie("Theme", "light", { path: "/" });
        root.classList.remove("dark");
        root.classList.add("light");
      }
    } else {
      if (cookie) {
        if (cookie.Theme === "dark") {
          root.classList.remove("light");
          root.classList.add("dark");
        } else {
          root.classList.remove("dark");
          root.classList.add("light");
        }
      } else {
        setCookie("Theme", "light", { path: "/" });
        root.classList.remove("dark");
        root.classList.add("light");
      }
    }
  }, [user, cookie, setCookie]);

  const socket = useContext(WebSocketContext);

  useEffect(() => {
    const sendOnline = () => {
      socket.emit("online", user._id);
    };

    const sendOffline = () => {
      socket.emit("offline", user._id);
    };

    const handleConnect = () => {
      socket.emit("join room", user._id);
    };

    const handleDisconnect = () => {
      socket.emit("leave room", user._id);
    };

    socket.emit("setup", user?._id);

    if (user) {
      window.addEventListener("load", sendOnline);
      window.addEventListener("beforeunload", sendOffline);

      socket.on("connect", handleConnect);
      socket.on("disconnect", handleDisconnect);
    }

    return () => {
      window.removeEventListener("load", sendOnline);
      window.removeEventListener("beforeunload", sendOffline);
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    };
  }, [socket, user]);

  const sendOnline = (e) => {
    e.preventDefault();
    socket.emit("setup", user._id);
    return "Hello";
  };

  useEffect(() => {
    if (user?._id) {
      socket.emit("join room", user._id);
    }
  }, [user?._id]);

  const sendOffline = (e) => {
    e.preventDefault();
    socket.emit("disconnectt", user._id);
    return "Goodbye";
    // setTimeout(()=>{
    //   sendOnline();
    //   console.log('online again?')
    // },[5000])
  };

  // component for redirection to play store
  const RedirectToPlayStore = () => {
    if (window.navigator.userAgent.includes("Android")) {
      window.location.replace(
        "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.feedants&pcampaignid=web_share"
      );
      return <></>;
    } else if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      console.log("ios Agent : ", window.navigator.userAgent);
      window.location.replace(
        "https://apps.apple.com/in/app/feedants/id6477408585"
      );
      return <></>;
    }
    return <Redirect exact path="/" />;
  };

  ReactGA.initialize("G-MNH124L7NM");

  // useEffect(() => {
  //   //setup user for the first time
  //   socket.connect();

  //   socket.on("connect", () => {
  //     socket.emit("setup", user);
  //     socket.emit("join room", user);
  //   });

  //   socket.on("disconnect", () => {
  //     socket.emit("disconnectt", user);
  //     socket.emit("leave room", user);
  //     console.log('nope')
  //   });

  // }, [socket,user]);
  // console.log(window.location.pathname);

  return (
    // Wrapping up our complete App with the global state using Provider Tag and passing Store Variable to it From store folder
    <div className="bg-primary">
      {user &&
        header &&
        location.pathname !== "/notfound" &&
        location.pathname !== "/polished" &&
        location.pathname !== "/novice" &&
        location.pathname !== "/editPSkills" &&
        location.pathname !== "/editUSkills" &&
        location.pathname !== "/about" && (
          <Header cookie={cookie} setCookie={setCookie} />
        )}

      <SocketNotification />
      {/* Adding Header to ever Page */}
      <Suspense fallback={<FallbackUI />}>
        <Switch>
          {/* Changed the route */}
          <LoginRouteLink path="/" exact component={LandingPage} />
          <Route path="/about" exact component={About} />
          <Route exact path="/app" component={RedirectToPlayStore} />
          <MarketingAdminRoutes
            path="/marketingadmin"
            exact
            component={CompetitionPanel}
          />
          <MarketingAdminRoutes
            path="/submissions/:contestId"
            exact
            component={JudgingPanel}
          />
          <PrivateRoutes
            exact
            path={"/submissionList/:submissionId"}
            component={SubmissionsList}
          />
          <PrivateRoutes
            exact
            path={"/editcontest/:contestId"}
            component={ContestEdit}
          />
          <AdminRoutes path="/admin" exact component={Admin} />
          <Route path="/privacy-policy" exact component={Policy} />
          <LoginRouteLink
            path="/forgotPassword"
            exact
            component={ForgotPassword}
          />
          <LoginRouteLink path="/forgotEmail" exact component={ForgotEmail} />
          <LoginRouteLink path="/newPassword" exact component={NewPassword} />
          <LoginRouteLink path="/accounts" exact component={SimilarEmailUser} />
          <RouteLinks path="/signupinitial" component={SignUpInitial} />
          <Route path="/verifyEmail" component={VerifyEmail} />
          <RouteLinks exact path="/verifyOtp" component={VerifyOtp} />
          <RouteLinks exact path="/signup" component={SignUpForm} />
          <PrivateRoutes exact path="/profile" component={Profile} />
          <PrivateRoutes
            exact
            path="/accountInfo/:userId"
            component={AccountInfo}
          />
          <Route exact path="/polished" component={ProfessionalSkills} />
          <Route exact path="/novice" component={UnprofessionalSkills} />
          <Route exact path="/skillmodel" component={Skillmodel} />
          <Route path="/TermsAndCondition" exact component={TermsCondition} />

          {/* After signin with google redirect user to  new page  to fill some details */}
          <Route path="/googleSignIn" exact component={GoogleDetails} />

          <PrivateRoutes exact path="/editPost/:id" component={Edit} />
          <PrivateRoutes exact path="/editImage/:id" component={EditImage} />
          <PrivateRoutes
            exact
            path="/editVidoePost/:id"
            component={EditVideo}
          />
          <PrivateRoutes exact path="/editPSkills" component={EditSkills} />
          <PrivateRoutes exact path="/editUSkills" component={EditUSkills} />
          <PrivateRoutes exact path="/wirings" component={SimilarUser} />
          <PrivateRoutes exact path="/timeline" component={Timeline} />
          <PrivateRoutes exact path="/postComments" component={PostComment} />
          <PrivateRoutes exact path="/reportPost" component={ReportPage} />
          <PrivateRoutes exact path="/loading" component={Loading} />
          <PrivateRoutes exact path="/peerInterests" component={PeerInterest} />
          <PrivateRoutes
            exact
            path="/userDetails/:userId"
            component={Userdetails}
          />
          <PrivateRoutes path="/allcomments/:postId" component={Commentsall} />
          <PrivateRoutes path="/chatting" component={Messenger} />
          <PrivateRoutes path="/followers/:userId" component={Followers} />
          <PrivateRoutes path="/following/:userId" component={Following} />
          <PrivateRoutes
            path="/allNotifications"
            component={NotificationPage}
          />
          <PrivateRoutes path="/rewards/:contestId" component={Rewards} />
          <PrivateRoutes path="/rewardsMobile" component={RewardsMobile} />
          <PrivateRoutes path="/judges/:contestId" component={Judges} />
          <PrivateRoutes exact path="/feedback" component={FeedbackForm} />
          <Route path="/post/:postId" exact component={SharePost} />
          <AdminRoutes path="/users" exact component={Users} />
          <PrivateRoutes exact path="/home" component={HomePage} />
          {/* <Route path="/notfound" component={ErrorPage} /> */}
          <PrivateRoutes path="/competition" exact component={CompetitionNew} />
          <PrivateRoutes path="/competition/:id" exact component={AllComp} />
       
          <PrivateRoutes
            path="/submission/:contestId"
            exact
            component={Submission}
          />
          <PrivateRoutes
            path="/mystatistics/:userId"
            exact
            component={MyStatisticsDesktop}
          />
          <PrivateRoutes
            path="/statistics/:userId"
            exact
            component={MyStatistics}
          />
          <PrivateRoutes path="/videoPreview" exact component={VideoTrimmer} />
          {/* <PrivateRoutes path="/levels" exact component={Levels} /> */}
          {/* <PrivateRoutes path="/mystatistics" exact component={MyStatistics} /> */}
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>

      <ToastContainer></ToastContainer>
    </div>
  );
}

export default withRouter(App);
