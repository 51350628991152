import React from 'react';
import yellow from "./yellow.png"
import { useEffect, useState } from "react"
import axios from "axios"
import { path } from "../../path"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SubCard = ({ s, getmedia, contestId }) => {
  const [finalScore, setFinalScore] = useState(s.finalScore);
  const [judgeParams, setjudgeParams] = useState([]);
  const [description, setdescription] = useState("")
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored"
  };

  const getContest = async () => {
    const res = await axios.get(`${path}/contest/getContestsbyId/${contestId}`)
    console.log(res?.data?.data?.responses?.sections[8].fields[0].response)
    setjudgeParams(res?.data?.data?.responses?.sections[8].fields[0].response)
  }

  useEffect(() => {
    getContest();
    console.log(judgeParams)
  }, [])

  useEffect(() => {
    setFields(Array(judgeParams.length).fill(0))
   
  }, [judgeParams])
  const handleSubmit = (e)=>{
    e.preventDefault();
    updateScore(s._id)
  }
  const updateScore = async (sId) => {
    // Dynamic fields
    //   const numericalValues = fields
    //   .map(field => parseFloat(field.value))
    //   .filter(value => !isNaN(value));

    // const sum = numericalValues.reduce((acc, curr) => acc + curr, 0);
    // const average = numericalValues.length ? sum / numericalValues.length : 0;
    let total = 0;
    fields.forEach((val) => {
      total += parseInt(val);
    })
    const maximumScore = judgeParams.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.split("_")[1], 10), 0);
    console.log(maximumScore);
    
    const average = (total / maximumScore)*100;
    setFinalScore(average.toFixed(2));

    const res = await axios.post(`${path}/contest/updatescore`, { sId, finalScore: average.toFixed(2), description })

    toast.success("Updated successfully")
  }
  const [fields, setFields] = useState(Array(judgeParams.length).fill(0));
  const handleInputChange = (index, value,maxValue) => {
    console.log(value)
    // convert maxValue to Integer
    let maxi=parseInt(maxValue);
    
    if (value > maxi) value = maxValue;
    const newFields = [...fields]
    newFields[index] = value;
    setFields(newFields);
  };


  return (
    <div className="flex  items-center p-4 h-50 bg-primary shadow rounded-lg  w-[100%]  mb-2">

      <div className="w-[100%] pl-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {s.user.profilePicture ? <img src={s.user.profilePicture} alt="Profile" className="rounded-full w-10 h-10" /> : <img src={yellow} alt="Profile" className="rounded-full w-10 h-10" />}
            <span className="ml-2 text-md font-gilroy-medium text-gray-400">{s.user.firstName} {s.user.lastName}</span>
          </div>
          <button className="flex text-sm items-center px-3 py-1 border mb-1 border-teal-500 rounded-full text-teal-500" onClick={() => getmedia(s.mediaUrl)}>
            <span className="material-icons text-teal-500 mr-1 text-sm">| |</span>
            View Video
          </button>
        </div>
        <div className="mt-2">
          <textarea id="message" value={description} onChange={(e) => setdescription(e.target.value)} rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-compBgSec rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write a comment"></textarea>
          <form className="flex flex-col gap-2 items-center justify-between mt-2" onSubmit={handleSubmit}>
            {/* Dynamic fields */}

            {(!finalScore || finalScore === 0) && <>
              {judgeParams.map((field, index) => (
                <div className='flex items-center justify-between w-full ' key={index}>
                  <label className='font-gilroy-regular text-sm'>{field} <span className="text-red-500">*</span></label>
                 
                  <input
                    type="numeric"

                    required
                    value={fields[index]}
                    class="block text-center max-w-10 p-2.5 w-fit text-sm text-gray-900 bg-compBgSec rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => handleInputChange(index, e.target.value,field.split("_")[1])}
                    placeholder={0}
                  />
                </div>
              ))}
            </>}

            {/* <input type="numeric" class="block p-2.5 w-fit text-sm text-gray-900 bg-compBgSec rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter marks" value={finalScore} onChange={(e) => setFinalScore(e.target.value)}></input> */}
            {(!finalScore || finalScore === 0) ?
              <button type="submit" className="px-4 py-2 bg-teal-700 text-white font-gilroy rounded-full text-sm" >Submit</button>
              :
              <div className='flex items-center justify-between w-full'>
                <h2>Final Score</h2>
                <div class="block w-12  p-1 text-sm text-gray-900 bg-compBgSec rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500  dark:focus:ring-blue-500 dark:focus:border-blue-500"  >{finalScore}</div>
              </div>
            }

          </form>
        </div>
      </div>
    </div>
  );
};

export default SubCard;
