import React from "react";
import { Avatar } from "@mui/material";
import Man from "../Header/img/Man.svg";
import Female from "../Header/img/Female.svg";
// import Others from "../Header/img/Others.svg";
import Others from "./img/Other_Gender.png";
import logoAnt from "../Header/img/Ant.png";
import logoAntDark from "../Header/img/Ant_Dark.png"


function UserAvatar(props) {
  const { path, data, cl } = props;

  return (
    <>
      {data.profilePicture ? (
        <Avatar
          src={data.profilePicture}
          className="w-2/5 h-auto rounded-1xl"
        />
      ) : data.gender === "male" ? (
        <Avatar src={Man} className="w-2/5 h-auto" />
      ) : data.gender === "female" ? (
        <Avatar src={Female} className="w-2/5 h-auto" />
      ) : data.gender === "others" ? (
        <Avatar src={Others} className="w-2/5 h-auto" />
      ) : data.theme === "dark" ? (
        <Avatar src={logoAntDark} className="w-2/5 h-auto" />
      ) : (
        <Avatar src={logoAnt} className="w-2/5 h-auto" />
      )}
    </>
  );
}

export default UserAvatar;
