import React, { useContext, useEffect,useMemo, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../Alert/Alert";
import axios from "axios";
import { path } from "../../path";
import { useHistory } from "react-router-dom";
import { verifyPasswordOtp } from "../../store/asyncMethods/AuthMethods";
import "./ChangeEmail.css";
import {
  verifyOtp,
  getOtp,
  updateEmail,
} from "../../store/asyncMethods/AuthMethods";
import { faWindowRestore } from "@fortawesome/free-regular-svg-icons";
import { WebSocketContext } from "../../WebSocket";

const ChangeEmail = ({ setVal, getNotification }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.AuthReducer);
  const toastOption = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };
  const socket = useContext(WebSocketContext);

  const [updateoption, setupdateoption] = useState(false);
  const [otpVerfication, setotpVerification] = useState(false);
  const [FinalForm, setFinalForm] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const { token } = useSelector((state) => state.AuthReducer);
  const [otp, setOtp] = useState(new Array(66).fill(""));
  const { otpRecieved } = useSelector((state) => state.AuthReducer);
  const [isMobile, setIsMobile] = useState(false);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [editbtn, seteditbtn] = useState(true);
  const dispatch = useDispatch();
  const [editEmail, setEditEmail] = useState(false);
  const [min, setmin] = useState(0);
  const [sec, setsec] = useState(59);
  const [resendotpdisable, setresendotpdisable] = useState(true);
  const { otpVerified, email, registerErrors, type, phone, otpHash } =
    useSelector((state) => state.AuthReducer);
  const [additionalEmail, setAdditionalEmail] = useState(user.additionalEmail);
  const [state, setState] = useState({
    oldEmail: user.email,
    oldPassword: "",
    alternateEmail: user.alternateEmail,
  });
  const elementRef = useRef([]);
  const [show, setShow] = useState(false);
  const handleadditionalEmail = (e) => {
    setAdditionalEmail(e.target.value);
    state.alternateEmail = additionalEmail;
  };
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setEditEmail(true);
    setIsEditing(true);
    seteditbtn(true);
    setShow(false);
    setShowOtpBox(false); //This closes the OTP Entering boxes when clifcked in Edit.
    setShowEmail(false);   //This closes the confirm password Entering boxes when clicked in Edit.
  };


  
  const handleInput = (e) => {
    if (e.target.name === "alternateEmail") {
      setState({
        ...state,
        [e.target.name]: e.target.value.toLowerCase().trim(),
      });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  const newFormSubmit = async (e) => {
    e.preventDefault();

    if (
      state.oldEmail == null ||
      state.oldPassword == null ||
      state.alternateEmail == null
    ) {
      // alert("Please fill all the field");
      toast.error("Please fill all the fields", toastOption);
      return;
    }

    state.oldEmail = state.oldEmail;
    const msg = await dispatch(updateEmail(state));
    // console.log(msg);
    getNotification();
    if (msg !== "success") {
      setState({ ...state, oldPassword: "" });
      // alert(msg);
      // console.log("Thik Nahi hai ye")
      toast.error(msg, toastOption);
      setresendotpdisable(false);
    } else {
      setShowOtpBox(false);
      toast.success("Alternate email updated successfully", toastOption);
      seteditbtn(true);

      setFinalForm(true);
      setTimeout(() => {
        setFinalForm(false);
        document.getElementById("home-content").style.opacity = 1;
      }, 1200);
    }
    setupdateoption(false);

    setShowEmail(false);
    setVal((prev) => !prev);
  };
  const otpOnChangeHandler = (event) => {
    setOtp(event.target.value);
  };
  const resendOTP = (e) => {
    setresendotpdisable(true);
    setShow(true);
    handleSendOTP(e);
  };
  const [backflag, setBackflag] = useState();
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      setBackflag(true);
      otp[index] = "";
      setOtp(otp);
      let previous = elementRef.current[index - 1];
      let current = elementRef.current[index];
      let next = elementRef.current[index + 1];
      if (
        (previous?.value?.length > 0 && current?.value?.length == 0) ||
        previous?.value?.length === 0
      ) {
        previous.focus();
      }
    } else {
      setBackflag(false);
    }
    //alert(otp)
  };
  const handleOtpValues = (e, index) => {
    //console.log(e)
    let current = elementRef.current[index];
    let next = elementRef.current[index + 1];
    if (isNaN(e.value)) {
      return false;
    }
    setOtp([...otp.map((d, idx) => (idx === index ? e.value : d))]);
    if (index <= 5) {
      if (next?.value?.length > 0 || current?.value?.length > 0) {
        next?.focus();
      }
    }
  };
  const isOtpFilled = useMemo(() => otp.every((otpDigit) => !isNaN(otpDigit) && otpDigit !== ''), [otp]);
  const handleSendOTP = async (e) => {
    e.preventDefault();
    setIsEditing(false);
    console.log(user);
    console.log(state.alternateEmail);
if( 
  state.alternateEmail === user?.email){
    toast.error("Alternate email cannot be same as primary email")
    return
  }
  if(state.alternateEmail === user.alternateEmail ){
    toast.error("No changes found",toastOption)
    return
  }

    if (
      state.alternateEmail === undefined ||
      state.alternateEmail === "" 
     
    ) {
      toast.error("Please fill Alternate Email", toastOption);
    } else if (state.alternateEmail !== "") {
      setEditEmail(false);
      setOtp(new Array(6).fill(""));
      setShowOtpBox(true);
      dispatch(getOtp("email", state.alternateEmail));
      dispatch({ type: "SET_EMAIL", payload: state.oldEmail });
      setShow(true);
      setsec(59);
      setupdateoption(true);
      setTimeout(() => {
        setupdateoption(false);
      }, 1200);
    } else {
      // alert("Please fill Alternate Email");
      // toast.error("Please fill Alternate Email",toastOption)
    }
  };
  const handleOtp = async (e) => {
    e.preventDefault();
    let newOtp = otp.join("");
    console.log(newOtp);

    const config = {
      headers: {
        "Access-Control-Allow-Origin": `${path}`,
        Authorization: `Bearer ${token}`,
      },
    };
    try {

      const response = await axios.post(
        `${path}/verifyOtp`,
        {
          otp: newOtp,
          oid: otpHash,
          type: "email",
        },
        config
      );
      if (response.data.msg === "Fine otp") {
        setShowEmail(true);

        setState({ ...state, oldPassword: "" });
        socket.emit("detailsUpdated", user._id);

        console.log("console loggin state", state);
        setShowOtpBox(false)
      } else {
        console.log(response);
        toast.error("Enter a valid OTP");
        return;
      }
    } catch (error) {
      console.log("catche error in 215", error);
    }
  };
  useEffect(() => {
    console.log(user.additionalEmail + " " + state.alternateEmail);
    if (otpVerified) {
      dispatch({ type: "RESET_OTP_VERIFIED" });
      setShow(false);
      toast.success("OTP Verified Sucessfully", toastOption);
    }
  }, [otpVerified]);
  const emailOnChangeHandler = (e) => {
    setUserEmail(e.target.value);
  };

  function useKey(key, cb) {
    const callbackRef = useRef(cb);

    useEffect(() => {
      callbackRef.current = cb;
    }, []);

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          callbackRef.current(event);
        }
      }
      document.addEventListener("keypress", handle);
      return () => {
        document.removeEventListener("keypress", handle);
      };
    }, [key]);
  }

  useKey("Enter", handleSendOTP);

  const otpTimer = () => {
    setsec(sec + 1);
    if (sec > 0) {
      setsec(sec - 1);
    }
    if (sec == 0) {
      setmin(min - 1);
      setsec(59);
    }
    if (min <= 0 && sec < 1) {
      setmin(0);
      setsec(59);
    }
    if (min == 0 && sec == 0) {
      setresendotpdisable(false);
    }
  };

  let time;
  useEffect(() => {
    time = setInterval(otpTimer, 1000);
    return () => {
      clearInterval(time);
    };
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      <div>
        <div className="mx-2 my-5 text-primary ">
          <div className="grid grid-cols-1 mb-4">
            <input
              disabled
              data-testid="email-holder"
              className="w-full p-2 border rounded-lg outline-none bg-accountInfo focus:ring-2 ring-teal-700 text-grayish border-viewMore"
              value={state.oldEmail}></input>
          </div>
          <h3 className="ml-1">Alternate Email</h3>
          {!editEmail  ? (
            <button
              className="edit-button"
              onClick={handleEditClick}
              disabled={!editbtn}>
              Edit
            </button>
          ) : (
            <></>
          )}
          <form onSubmit={handleSendOTP} data-testid="input-form">
            <div className="relative grid grid-cols-[9fr, 1fr]">
              <input
                onChange={handleInput}
                data-testid="altemail-holder"
                value={state.alternateEmail}
                className="w-full p-2 border rounded-lg outline-none bg-neutral text-primary border-viewMore focus:ring-2 ring-teal-700"
                type="email"
                name="alternateEmail"
                placeholder="Alternate Email"
                // disabled="false"
                disabled={!editEmail}></input>
              {!isMobile ? (
                !show ? (
                  editEmail ? (
                    <div className="mt-[1rem]">
                      <input
                        data-testid="input-button"
                        style={{ border: "black" }}
                        type="submit"
                        className="edit-button"
                      />
                      <button
                        style={{ border: "black" }}
                        type="button"
                        onClick={() => setEditEmail(false)}
                        className="bg-neutral text-primary inline-block float-right hover:bg-gray-400 rounded-lg p-[7px] m-[2px] font-[12px] transition duration-200">
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <p className="cursor-pointer rounded-full text-grey-500 field4 absolute top-[2rem] right-3 md:text-[1rem]"></p>
                )
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>

        <div className="mx-2 my-2" data-testid="verify-otp">
          {showOtpBox ? (
            <>
              {" "}
              <form className="flex space-x-2" onSubmit={(e) => handleOtp(e)}>
                <div className="md:flex md:justify-between md:items-center ">
                  <div className="">
                    {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          className=" mr-2 w-[8vw] h-[3vh] text-primary text-center rounded-lg md:w-[3vw] md:h-[5vh] border border-viewMore bg-neutral outline-none focus:ring-2 ring-teal-700"
                          name="otp"
                          maxLength="1"
                          key={index}
                          value={data}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          onChange={(e) => handleOtpValues(e.target, index)}
                          onFocus={(e) => e.target.select()}
                          ref={(el) => (elementRef.current[index] = el)}
                        />
                      );
                    })}
                  </div>
                  <div className="flex flex-row justify-center md:justify-start">
                    <button
                      type="submit"
                      disabled={!isOtpFilled}
                      className={`cursor-${!isOtpFilled? "not-allowed" : "pointer"} rounded-full bg-[#0E0E0E] border border-[#0E0E0E] field4 w-[73vw] h-[5vh] px-2  hover:bg-gray-700 md:h-[6vh] md:text-[1rem] md:w-[10vw] md:border-[#0E0E0E] md:bg-[#0E0E0E] md:hover:bg-gray-700 md:hover:border-[#8C94AC] text-white transition duration-200`}>
                      Verify OTP
                    </button>
                  </div>
                </div>
              </form>
              <div className="mt-2">
                {resendotpdisable == true ? (
                  show ? (
                    <h3>
                      Resend code in {min < 10 ? "0" + min : min}:
                      {sec < 10 ? "0" + sec : sec}
                    </h3>
                  ) : (
                    <></>
                  )
                ) : (
                  <button
                    className="cursor-pointer"
                    onClick={(e) => resendOTP(e)}>
                    Resend OTP
                  </button>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div>
          {showEmail ? (
            !FinalForm ? (
              <form onSubmit={newFormSubmit}>
                <div className="grid grid-cols-1 mx-2 my-5">
                  <input
                    type="password"
                    value={state.oldPassword}
                    onChange={handleInput}
                    name="oldPassword"
                    placeholder="Current Password"
                    className="w-full md:w-[50%] p-2 border border-viewMore rounded-lg outline-none focus:ring-2 ring-teal-700 bg-neutral"></input>
                </div>
                <div className="flex flex-row justify-center md:justify-start">
                  <input
                    style={{ border: "black", marginLeft: "0.5rem" }}
                    type="submit"
                    className="cursor-pointer rounded-full field4 w-[73vw] h-[5vh] px-2 border-2 border-[#0E0E0E] bg-[#0E0E0E]  my-[0.7rem] hover:[#4950F6] md:h-[6vh] md:text-[1rem] md:w-[15vw] md:border-[#0E0E0E] md:bg-[#0E0E0E] md:hover:bg-[#8C94AC] md:hover:border-[#8C94AC] text-white"
                    value="Update"
                  />
                </div>
                <hr className="border-viewMore"></hr>
              </form>
            ) : (
              <>
                <Alert msg={"Alternate Email Updated"} type="success" />
              </>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      
    </>
  );
};

export default ChangeEmail;
