import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { path } from "../../path";
import LogoDark from "./LogoTransparent/Tranparent_dark_logo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

function FeedbackForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const toastOption = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };

  const { token, user } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
  }, []);

  const [state, setState] = useState({
    userId: user._id,
    comment1: "",
    comment2: "",
  });
  const [comm1Req, setComm1Req] = useState(false);
  const [comm2Req, setComm2Req] = useState(false);

  const stateHandler = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const feedbackHandler = async (e) => {
    e.preventDefault();
    console.log("feedbackHandler");
    if (
      state.comment1.trim().length >= 600 ||
      state.comment2.trim().length >= 600
    ) {
      toast.error(
        "Field lengths cannot be greater than or eqaul to 600 characters",
        toastOption
      );
      return;
    }
    try {
      const config = {
        host: `${path}`,
        headers: {
          "Access-Control-Allow-Origin": `${path}`,
          Authorization: `Bearer ${token}`,
        },
      };
      if (state.comment1.trim() !== "" && state.comment2.trim() !== "") {
        const result = await axios.post(`${path}/sendfeedback`, state);
        if (result.data.success === true) {
          dispatch({
            type: "SET_USER",
            user: result.data.user,
            token: result.data.token,
          });
          dispatch({ type: "UNSHOW_FEEDBACK" });
          toast.success("Thanks for your feedback.", toastOption);
          history.push("/home");
        } else {
          toast.error("Some error in saving feedback.", toastOption);
        }
      }
      if (state.comment1.trim() === "") {
        setComm1Req(true);
      }
      if (state.comment2.trim() === "") {
        setComm2Req(true);
      }
    } catch (error) {
      toast.error(error, toastOption);
    }
  };

  return (
    <div className="h-[100vh] flex justify-center items-center">
      <div className="border-2 h-full w-full border-viewMore shadow-lg rounded-lg py-6 px-4">
        <div className="flex flex-col items-center">
          {user.theme === "light" ? (
            <img
              src="/Logo-Design.png"
              alt="Logo"
              style={{ width: "200px" }}
              className="md:scale-125"
            />
          ) : (
            <img
              src={LogoDark}
              alt="Logo"
              style={{ width: "200px" }}
              className="md:scale-125"
            />
          )}
        </div>
        <p className="mt-4 font-semibold md:mt-6 text-primary">Feedback Form</p>
        <p className="text-primary">
          Kindly assist us in enhancing our services.
        </p>
        <form className="mt-2 h-full" onSubmit={feedbackHandler}>
          <label htmlFor="comment1" className="font-semibold text-primary">
            Did you find any feature not working or not redirecting properly?
            Max(600 characters)
            <span className="text-sm ml-2 text-gray-500">
              {state.comment1.length}/600
            </span>
          </label>
          <textarea
            name="comment1"
            className={`border border-viewMore resize-none h-[30%] rounded-lg w-[100%] p-2 outline-${
              comm1Req === false ? "#008080" : "red-500"
            } text-sm`}
            onChange={stateHandler}
            value={state.comment1}
          />
          {comm1Req === true && (
            <p className="text-red-500 text-sm">This field is required.</p>
          )}
          <label htmlFor="comment2" className="font-semibold mt-2 text-primary">
            Could you suggest any error you find on this website? Max(600
            characters)
            <span className="text-sm ml-2 text-gray-500">
              {state.comment2.length}/600
            </span>
          </label>
          <textarea
            name="comment2"
            className={`border border-viewMore resize-none h-[30%] rounded-lg w-[100%] p-2 outline-${
              comm2Req === false ? "#008080" : "red-500"
            } text-sm`}
            onChange={stateHandler}
            value={state.comment2}
          />
          {comm2Req === true && (
            <p className="text-red-500 text-sm">This field is required.</p>
          )}
          <button
            type="submit"
            onClick={feedbackHandler}
            className="bg-black text-white rounded-lg px-4 py-2 w-[100%] mt-2 cursor-pointer">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default FeedbackForm;
