import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { path } from "../../path";
import axios from "axios";
import { useSelector } from "react-redux";
import NotificationIcon from "./NotificationIcon";
import MobileFooter from "../footer/MobileFooter";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { WebSocketContext } from "../../WebSocket";
import "./Noti.css";

const NotificationCard = ({ settoggleProfileDropdown }) => {
    const dispatch = useDispatch();
    const { user, token } = useSelector((state) => state.AuthReducer);
    const [msg, setMsg] = useState("View More");
    // Notifications are handled by Reducers and Socket events are on SocketNotification.js page
    const { notification } = useSelector((state) => state.NotificationReducer);
    console.log(notification, "my");
    // const [notification, setNotification] = useState([]);
    const [length, setLength] = useState(5);
    const [loadMore, setLoadMore] = useState(false);
    const handleLoadMore = () => {
        if (msg === "View More") {
            setLength(notification.length);
            setMsg("View Less");
            return;
        } else {
            setLength(6);
            setMsg("View More");
            return;
        }
    };
    const history = useHistory();
    const [socketConnected, setSocketConnected] = useState(false);
    const [loader, setLoader] = useState(true);
    const config = {
        host: `${path}`,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": `${path}`,
            Authorization: `Bearer ${token}`,
        },
    };
    const getNotification = async () => {
        try {
            const response = await axios.get(
                `${path}/getNotification/${user._id}`,
                config
            );
            let filteredNoti = response.data.data.filter(
                (noti) =>
                    noti.fromUserId !== null &&
                    noti.type !== "Not Improve" &&
                    noti.type !== "Unappreciate" &&
                    noti.postId !== null
            );

            // filteredNoti = filteredNoti.map((noti)=>{
            //   return(
            //     {...noti, postId: {...noti.postId, body: null}}
            //   )
            // })
            // filteredNoti = [ filteredNoti[5]];

            // console.log(filteredNoti[0].postId.body)
            console.log(response.data.data, "Page", filteredNoti);
            dispatch({ type: "UPDATE_NOTIFICATION", payload: filteredNoti });

            // setLength(filteredNoti.length);
            x = filteredNoti.length;
        } catch (error) {
            console.log(error);
        }
    };

    const socket = useContext(WebSocketContext);
    useEffect(() => {
        socket.on("connected", () => {
            setSocketConnected(true);
            // console.log("Socket connected")
        });
        socket.on("notification received", (data) => {
            // console.log("notf Data: ", data)
            // const msgs = data.content;
            // getLastNotification();
            // console.log("chala_2");
            // getNotification();
        });

        socket.on("post notification recieved", (data) => {
            // console.log("notf Data: ", data);
            // const msgs = data.content;
            // getLastNotification();
            // getNotification();
        });

        socket.on("friend received", (data) => {
            // console.log("Notification Page");
            // getNotification();
        });

        socket.on("accept request", (data) => {
            // getNotification();
        });

        socket.on("profile update", (data) => {
            // getNotification();
        });
    }, []);

    const deleteAllNotification = async (notiId) => {
        // setNotification([]);
        dispatch({ type: "UPDATE_NOTIFICATION", payload: [] });
        setLength(0);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.post(
                `${path}/deleteAllNotification`,
                { userId: user._id },
                config
            );
            // console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteNotification = async (notiId) => {
        // setNotification(notification.filter((noti) => noti._id != notiId && noti));
        dispatch({
            type: "UPDATE_NOTIFICATION",
            payload: notification.filter((noti) => noti._id != notiId && noti),
        });
        // setLength(length - 1);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            let id = [];
            id.push(notiId);
            const response = await axios.post(
                `${path}/deleteNotification`,
                { id: JSON.stringify(id) },
                config
            );
            // console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getLastNotification = async () => {
        try {
            const response = await axios.get(
                `${path}/getNotification/${user._id}`,
                config
            );
            // console.log("notf res data ",response.data)
            // notification.unshift(response.data.data[0]);
            // setNotification([response.data.data[0], ...notification]);
            dispatch({
                type: "UPDATE_NOTIFICATION",
                payload: [response.data.data[0], ...notification],
            });

            setLength(response.data.length);
            // console.log("notf  : ",notification)
        } catch (error) {
            // console.log(error);
        }
    };

    let x = notification.length;
    useEffect(() => {
        console.log("ran get notification");
        dispatch({ type: "SET_HEADER" });
        getNotification();
        setLoader(false);
    }, []);

    // const formatDate = (dateString) => {
    //   const options = {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //   };
    //   return new Date(dateString).toLocaleDateString(undefined, options);
    // };

    const formatDate = (dateString) => {
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        };

        const notificationDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifferenceInSeconds = (currentDate - notificationDate) / 1000;

        if (timeDifferenceInSeconds < 60) {
            // Less than 1 minute
            return `${Math.floor(timeDifferenceInSeconds)} sec ago`;
        } else if (timeDifferenceInSeconds < 3600) {
            // Less than 1 hour
            return `${Math.floor(timeDifferenceInSeconds / 60)} min ago`;
        } else if (timeDifferenceInSeconds < 86400) {
            // Less than 24 hours
            return `${Math.floor(timeDifferenceInSeconds / 3600)} hrs ago`;
        } else if (timeDifferenceInSeconds < 604800) {
            // Less than 1 week
            return `${Math.floor(timeDifferenceInSeconds / 86400)} days ago`;
        } else {
            // More than 1 week
            return `${Math.floor(timeDifferenceInSeconds / 604800)} weeks ago`;
        }
    };

    const handleSeenAll = async () => {
        // console.log("notiid ", notification);
        const result = notification.map((noti) => ({ ...noti, seen: true }));
        // setNotification(result);
        dispatch({ type: "UPDATE_NOTIFICATION", payload: result });

        // console.log(result);
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.post(
                `${path}/seenAllNotification`,
                { userId: user._id },
                config
            );
        } catch (error) {
            console.log(error);
        }
    };
    const handleSeen = async (notiId) => {
        // console.log("notiid ", notification);
        const result = notification.map((noti) =>
            noti._id === notiId ? { ...noti, seen: true } : noti
        );

        // setNotification(result);
        dispatch({ type: "UPDATE_NOTIFICATION", payload: result });

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.post(
                `${path}/seenNotification`,
                { notiId },
                config
            );
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        let unseen = 0;
        notification.map((noti) => {
            if (noti.seen != true) unseen++;
        });

        //   console.log(unseen);
    }, [notification]);

    const calculateTime = (t) => {
        const actualTime = new Date().getTime();
        const notiCreationTime = new Date(t).getTime();
        return actualTime - notiCreationTime;
    };

    const getUserData = async (userId) => {
        const config = {
            headers: {
                "Access-Control-Allow-Origin": `${path}`,
                Authorization: `Bearer ${token}`,
            },
        };
        const res = await axios.get(`${path}/getUserData/${user._id}`, config);
        // console.log("Notifications : ", notification);
        return res.data.firstName;
    };
    // console.log(notification)

    // console.log(notification.slice(0, length).map((noti) => { console.log(noti) }))
    // console.log(notification.slice(0, length))

    // console.log(notification[notification.length])
    // console.log(loadMore)
    return (
        <>
            <div
                className="box-shadow absolute left-[69%] top-[24%] mt-10 p-2 flex items-center justify-center border rounded-lg border-viewMore bg-primary text-primary mx-auto max-w-[300px]  max-h-[395px]"
                data-testid="Noti-page">
                {/* <div className="flex flex-row items-center justify-between px-2">
                        <h3 className="mb-1 text-sm font-gilroy-bold text-center md:text-lg md:text-left">
                            All Notifications ({x})
                        </h3>
                        <div>
                            {notification.length > 0 ? (
                                <div className="flex space-x-2">
                                    <button
                                        className="text-teal-600 font-gilroy-semibold cursor-pointer hover:font-gilroy-bold"
                                        onClick={handleSeenAll}
                                        data-testid="seen-btn">
                                        Seen
                                    </button>
                                    <button
                                        className="text-teal-600 font-gilroy-semibold cursor-pointer hover:font-gilroy-bold"
                                        data-testid="clear-btn"
                                        onClick={deleteAllNotification}>
                                        Clear
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div> */}
                {/* deleteAllNotification */}
                {/* <hr className="border-viewMore"></hr> */}
                <div className="overflow-scroll h-[full] w-[full]  opop">
                    {loader ? <p>Loading...</p> : null}
                    {notification ? (
                        !loadMore ? (
                            notification.slice(0, length).map((noti) =>
                                noti.seen === false ? (
                                    <>
                                        <div
                                            className="py-[8px] ml-2 md:ml-0 mt-1 font-gilroy-bold md:flex md:flex-row md:justify-between"
                                            data-testid="Noti-div">
                                            <div
                                                onClick={() => handleSeen(noti._id)}
                                                className="flex flex-row items-center justify-start cursor-pointer md:justify-between">
                                                <div>
                                                    <NotificationIcon notification={noti} />
                                                </div>
                                                <div className="flex flex-col text-sm items-center justify-center ml-2 align-start">
                                                    {noti.fromUserId?._id !== user._id &&
                                                        noti.type === "comment_positive" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has left a
                                                                comment appreciating your post.
                                                            </Link>
                                                        )}
                                                    {noti.fromUserId?._id === user._id &&
                                                        noti.type === "comment_positive" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                you have appreciated your post.
                                                            </Link>
                                                        )}
                                                    {noti.fromUserId?._id === user._id &&
                                                        noti.type === "Improve" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                You suggested improvements to your post.
                                                            </Link>
                                                        )}{" "}
                                                    {noti.fromUserId?._id === user._id &&
                                                        noti.type === "Appreciate" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                You appreciated your post.
                                                            </Link>
                                                        )}
                                                    {noti.fromUserId?._id !== user._id &&
                                                        noti.type === "comment_negative" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has suggested
                                                                improvements to your post.
                                                            </Link>
                                                        )}
                                                    {noti.fromUserId?._id === user._id &&
                                                        noti.type === "comment_negative" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                you suggested improvements to your post.
                                                            </Link>
                                                        )}
                                                    {noti?.type === "post" ? (
                                                        noti?.fromUserId !== null &&
                                                            noti.fromUserId?._id !== user._id ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has recently
                                                                added a post to the{" "}
                                                                {noti.postId.category.includes("_")
                                                                    ? noti.postId.category.replace("_", " ")
                                                                    : noti.postId.category}{" "}
                                                                category.
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                You have posted{" "}
                                                                {noti.postId.type === "image" ? "an" : "a"}{" "}
                                                                {noti.postId.type} in the{" "}
                                                                {noti.postId.category.includes("_")
                                                                    ? noti.postId.category.replace("_", " ")
                                                                    : noti.postId.category}{" "}
                                                                category.
                                                            </Link>
                                                        )
                                                    ) : null}
                                                    {noti.type === "Improve" &&
                                                        noti.fromUserId?._id !== user._id ? (
                                                        calculateTime(noti.createdAt) >= 2 &&
                                                            noti.postId.improve.length < 5 ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                <b>
                                                                    <Link
                                                                        to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                        {noti.fromUserId?.firstName}
                                                                    </Link>
                                                                </b>{" "}
                                                                suggests improving your post.
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName + " "} and{" "}
                                                                {noti.postId.improve.length - 1}
                                                                others suggest improving your post.
                                                            </Link>
                                                        )
                                                    ) : null}
                                                    {noti.type === "Password" && (
                                                        <h3>Password changed successfully.</h3>
                                                    )}
                                                    {noti.type === "Alternate Email" && (
                                                        <h3>Alternative email added to your account.</h3>
                                                    )}
                                                    {noti.type === "Profile" && (
                                                        <h3>Account Info updated successfully.</h3>
                                                    )}
                                                    {noti.type === "name_update" && (
                                                        <h3>Name updated successfully.</h3>
                                                    )}
                                                    {noti.type === "profile_pic_update" && (
                                                        <h3>Profile pic added successfully!</h3>
                                                    )}
                                                    {noti.type === "skill_update" && (
                                                        <h3>Your skills has been updated.</h3>
                                                    )}
                                                    {noti.type === "social_media_update" && (
                                                        <h3>Social media account added successfully!</h3>
                                                    )}
                                                    {noti.type === "peerInterest" &&
                                                        noti.fromUserId !== null && (
                                                            <Link className="text-sm"
                                                                to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                {noti.fromUserId?.firstName} is interested in
                                                                connecting as peers.
                                                            </Link>
                                                        )}
                                                    {noti.type === "peerAccepted" &&
                                                        noti.fromUserId !== null && (
                                                            <Link className="text-sm"
                                                                to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                You are now connected as peers with{" "}
                                                                {noti.fromUserId?.firstName}!
                                                            </Link>
                                                        )}
                                                </div>
                                                {noti.type === "Appreciate" &&
                                                    noti.fromUserId?._id !== user._id ? (
                                                    calculateTime(noti.createdAt) >= 2 &&
                                                        noti.postId.appreciate.length < 5 ? (
                                                        <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                            Your post has been appreciated by{" "}
                                                            <b>
                                                                <Link className="text-sm"
                                                                    to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                    {noti.fromUserId?.firstName}
                                                                </Link>
                                                            </b>
                                                        </Link>
                                                    ) : (
                                                        <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                            Your post has been appreciated by{" "}
                                                            {noti.fromUserId?.firstName + " "}
                                                            and {noti.postId.appreciate.length - 1} others.
                                                        </Link>
                                                    )
                                                ) : null}

                                                {/** Notification code for contest */}

                       
                                                {noti.type === "contestCreated" && (
                                                    <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                     
                                                        {`${noti.contestId?.responses.sections[2].fields[0].response} is live register now`}
                                                    </Link>
                                                )}

                                                {noti.type === "joinedContest" && (
                                                    <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                        You join the {noti.contestId?.responses.sections[2].fields[0].response} Contest
                                                        Successfully!
                                                    </Link>
                                                )}
                                                {noti.type === "contestSubmitted" && (
                                                    <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                        {/* Congratulations you Submitted the{" "}
                                                        {noti.contestId?.responses.sections[2].fields[0].response} Contest Successfully! */}
                                                        Congratulations on your Submission in{" "}
                                                        {noti.contestId?.responses.sections[2].fields[0].response}
                                                    </Link>
                                                )}

                                                {noti.type === "resultDeclare" && (
                                                    <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                        {noti.contestId?.responses.sections[2].fields[0].response} Contest Result is Declared
                                                        Check Now !
                                                    </Link>
                                                )}

                                                {noti.type === "submissionStart" && (
                                                    <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                        {noti.contestId?.responses.sections[2].fields[0].response} Contest Submission Start
                                                        Now !
                                                    </Link>
                                                )}

                                                {noti.type === "submissionEndBefore" && (
                            <Link to={`/competition/${noti.contestId?._id}`}>
                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Submission End soon ,contribute fast !
                            </Link>
                          )}

                                            </div>

                                            <div className="flex items-end mt-2 sm:ml-7 md:ml-0 justify-end">
                                                <h3 className=" min-w-4 text-xs whitespace-nowrap" >
                                                    {formatDate(noti.createdAt)}
                                                </h3>
                                                {/* <div
                                                            onClick={() => deleteNotification(noti._id)}
                                                            className="text-[#EB8E8E] bg-card border border-[#EB8E8E] rounded-full p-[4px] cursor-pointer">
                                                            <img src="assets/NotificationIcon/disabled_by_default.png"></img>
                                                        </div> */}
                                            </div>

                                        </div>
                                        <hr className="border-viewMore"></hr>
                                    </>
                                ) : (
                                    <>
                                        <div
                                            key={noti._id}
                                            className="py-[8px] ml-2 md:ml-0 mt-1 md:flex md:flex-row md:justify-between">
                                            <div className="flex flex-row items-center justify-start md:justify-between">
                                                <div>
                                                    <NotificationIcon notification={noti} />
                                                </div>
                                                <div className="ml-2 flex max-w-44 flex-col line-clamp-2 text-sm items-center justify-center align-start text-[#A5A5A5]">
                                                    {noti.fromUserId?._id !== user._id &&
                                                        noti.type === "comment_positive" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has left a
                                                                comment appreciating your post.
                                                            </Link>
                                                        )}
                                                    {noti.fromUserId?._id !== user._id &&
                                                        noti.type === "comment_negative" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has suggested
                                                                improvements to your post.
                                                            </Link>
                                                        )}

                                                    {noti.type === "post" ? (
                                                        noti.fromUserId !== null &&
                                                            noti.fromUserId?._id !== user._id ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has recently
                                                                added a post to the{" "}
                                                                {noti.postId.category.includes("_")
                                                                    ? noti.postId.category.replace("_", " ")
                                                                    : noti.postId.category}{" "}
                                                                category.
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                You have posted{" "}
                                                                {noti.postId.type === "image" ? "an" : "a"}{" "}
                                                                {noti.postId.type} in the{" "}
                                                                {noti.postId.category.includes("_")
                                                                    ? noti.postId.category.replace("_", " ")
                                                                    : noti.postId.category}{" "}
                                                                category.
                                                            </Link>
                                                        )
                                                    ) : null}
                                                    {noti.type === "Appreciate" &&
                                                        noti.fromUserId?._id !== user._id ? (
                                                        calculateTime(noti.createdAt) >= 2 &&
                                                            noti.postId.appreciate.length < 5 ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                Your post has been appreciated by{" "}
                                                                <b>
                                                                    <Link
                                                                        to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                        {noti.fromUserId?.firstName}
                                                                    </Link>
                                                                </b>
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                Your post has been appreciated by
                                                                {noti.fromUserId?.firstName}
                                                                {/* {getUserData(
                                  noti.postId.appreciate[
                                  noti.postId.appreciate.length - 1
                                  ]
                                )} */}
                                                                and {noti.postId.appreciate.length - 1}{" "}
                                                                others.
                                                            </Link>
                                                        )
                                                    ) : null}
                                                    {noti.type === "Improve" &&
                                                        noti.fromUserId?._id !== user._id ? (
                                                        calculateTime(noti.createdAt) >= 2 &&
                                                            noti.postId.improve.length < 5 ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                <b>
                                                                    <Link
                                                                        to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                        {noti.fromUserId?.firstName}
                                                                    </Link>
                                                                </b>{" "}
                                                                suggests improving your post.
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName + " "}
                                                                and {noti.postId.improve.length - 1}
                                                                others suggest improving your post.
                                                            </Link>
                                                        )
                                                    ) : null}
                                                    {noti.type === "Password" && (
                                                        <h3>Password changed successfully.</h3>
                                                    )}
                                                    {noti.type === "Alternate Email" && (
                                                        <h3>Alternative email added to your account.</h3>
                                                    )}
                                                    {noti.type === "Profile" && (
                                                        <h3>Account Info updated successfully.</h3>
                                                    )}
                                                    {noti.type === "name_update" && (
                                                        <h3>Name updated successfully.</h3>
                                                    )}
                                                    {noti.type === "skill_update" && (
                                                        <h3>Your skills has been updated.</h3>
                                                    )}
                                                    {noti.type === "social_media_update" && (
                                                        <h3>Social media account added successfully!</h3>
                                                    )}
                                                    {noti.type === "profile_pic_update" && (
                                                        <h3>Profile pic added successfully!</h3>
                                                    )}
                                                    {noti.type === "peerInterest" &&
                                                        noti.fromUserId !== null && (
                                                            <Link
                                                                to={`/userDetails/${noti.fromUserId?._id}`}
                                                                className="text-[#A5A5A5] text-sm">
                                                                {noti.fromUserId?.firstName} is interested in
                                                                connecting as peers.
                                                            </Link>
                                                        )}
                                                    {noti.type === "peerAccepted" &&
                                                        noti.fromUserId !== null && (
                                                            <Link
                                                                to={`/userDetails/${noti.fromUserId?._id}`}
                                                                className="text-[#A5A5A5] text-sm">
                                                                You are now connected as peers with{" "}
                                                                {noti.fromUserId?.firstName} .
                                                            </Link>
                                                        )}


                                                    {noti.type === "contestCreated" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                       
                                                            {`${noti.contestId?.responses.sections[2].fields[0].response} is live register now`}
                                                        </Link>
                                                    )}

                                                    {noti.type === "joinedContest" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            You join the {noti.contestId?.responses.sections[2].fields[0].response} Contest
                                                            Successfully!
                                                        </Link>
                                                    )}
                                                    {noti.type === "contestSubmitted" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            {/* Congratulations you Submitted the{" "}
                                                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Successfully! */}
                                                            Congratulations on your Submission in{" "}
                                                            {noti.contestId?.responses.sections[2].fields[0].response}
                                                        </Link>
                                                    )}

                                                    {noti.type === "resultDeclare" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Result is
                                                            Declared Check Now !
                                                        </Link>
                                                    )}

                                                    {noti.type === "submissionStart" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Submission Start
                                                            Now !
                                                        </Link>
                                                    )}

                                                    {noti.type === "submissionEndBefore" && (
                            <Link to={`/competition/${noti.contestId?._id}`}>
                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Submission End soon ,contribute fast !
                            </Link>
                          )}

                                                </div>
                                            </div>

                                            <div className="flex items-end mt-2 sm:ml-7 md:ml-0 justify-end ">
                                                <h3 className=" text-[#A5A5A5] min-w-4 text-xs whitespace-nowrap">
                                                    {formatDate(noti.createdAt)}
                                                </h3>
                                                {/* <div
                                                            onClick={() => deleteNotification(noti._id)}
                                                            className="text-[#EB8E8E] bg-card border border-[#EB8E8E] rounded-full p-[4px] cursor-pointer">
                                                            <img src="assets/NotificationIcon/disabled_by_default.png"></img>
                                                        </div> */}
                                            </div>

                                        </div>
                                        <hr className="border-viewMore"></hr>
                                    </>
                                )
                            )
                        ) : (
                            notification.map((noti) =>
                                noti.seen == false ? (
                                    <>
                                        <div
                                            className="py-[8px] ml-2 md:ml-0 mt-1 font-gilroy-bold md:flex md:flex-row md:justify-between"
                                            data-testid="Noti-div">
                                            <div
                                                onClick={() => handleSeen(noti._id)}
                                                className="flex flex-row items-center justify-start cursor-pointer md:justify-between">
                                                <div>
                                                    <NotificationIcon notification={noti} />
                                                </div>
                                                <div className="flex flex-col text-sm items-center justify-center ml-2 align-start">
                                                    {noti.fromUserId?._id !== user._id &&
                                                        noti.type === "comment_positive" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has left a
                                                                comment appreciating your post.
                                                            </Link>
                                                        )}

                                                    {noti.fromUserId?._id !== user._id &&
                                                        noti.type === "comment_negative" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has suggested
                                                                improvements to your post.
                                                            </Link>
                                                        )}
                                                    {noti.type === "post" ? (
                                                        noti.fromUserId !== null &&
                                                            noti.fromUserId?._id !== user._id ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has recently
                                                                added a post to the{" "}
                                                                {noti.postId.category.includes("_")
                                                                    ? noti.postId.category.replace("_", " ")
                                                                    : noti.postId.category}{" "}
                                                                category.
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                You have posted{" "}
                                                                {noti.postId.type === "image" ? "an" : "a"}{" "}
                                                                {noti.postId.type} in the{" "}
                                                                {noti.postId.category.includes("_")
                                                                    ? noti.postId.category.replace("_", " ")
                                                                    : noti.postId.category}{" "}
                                                                category.
                                                            </Link>
                                                        )
                                                    ) : null}

                                                    {noti.type === "Improve" &&
                                                        noti.fromUserId?._id !== user._id ? (
                                                        calculateTime(noti.createdAt) >= 2 &&
                                                            noti.postId.improve.length < 5 ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                <b>
                                                                    <Link
                                                                        to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                        {noti.fromUserId?.firstName}
                                                                    </Link>
                                                                </b>{" "}
                                                                suggests improving your post.
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {/* {getUserData(
                                  noti.postId.improve[
                                  noti.postId.improve.length - 1
                                  ]
                                )}{" "} */}
                                                                {noti.fromUserId?.firstName + ""}
                                                                and {noti.postId.improve.length - 1}
                                                                others suggest improving your post.
                                                            </Link>
                                                        )
                                                    ) : null}
                                                    {noti.type === "Password" && (
                                                        <h3>Password changed successfully.</h3>
                                                    )}
                                                    {noti.type === "Alternate Email" && (
                                                        <h3>Alternative email added to your account.</h3>
                                                    )}
                                                    {noti.type === "name_update" && (
                                                        <h3>Name updated successfully.</h3>
                                                    )}
                                                    {noti.type === "profile_pic_update" && (
                                                        <h3>Profile pic added successfully!</h3>
                                                    )}
                                                    {noti.type === "skill_update" && (
                                                        <h3>Your skills has been updated.</h3>
                                                    )}
                                                    {noti.type === "social_media_update" && (
                                                        <h3>Social media account added successfully!</h3>
                                                    )}
                                                    {noti.type === "peerInterest" &&
                                                        noti.fromUserId !== null && (
                                                            <Link className="text-sm"
                                                                to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                {noti.fromUserId?.firstName} is interested in
                                                                connecting as peers.
                                                            </Link>
                                                        )}
                                                    {noti.type === "peerAccepted" &&
                                                        noti.fromUserId !== null && (
                                                            <Link className="text-sm"
                                                                to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                You are now connected as peers with{" "}
                                                                {noti.fromUserId?.firstName}!
                                                            </Link>
                                                        )}

                       

                                                    {noti.type === "contestCreated" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                         
                                                            {`${noti.contestId?.responses.sections[2].fields[0].response} is live register now`}
                                                        </Link>
                                                    )}

                                                    {noti.type === "joinedContest" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            You join the {noti.contestId?.responses.sections[2].fields[0].response} Contest
                                                            Successfully!
                                                        </Link>
                                                    )}

                                                    {noti.type === "contestSubmitted" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            {/* Congratulations you Submitted the{" "}
                                                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Successfully! */}
                                                            Congratulations on your Submission in{" "}
                                                            {noti.contestId?.responses.sections[2].fields[0].response}
                                                        </Link>
                                                    )}

                                                    {noti.type === "resultDeclare" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Result is
                                                            Declared Check Now !
                                                        </Link>
                                                    )}

                                                    {noti.type === "submissionStart" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Submission Start
                                                            Now !
                                                        </Link>
                                                    )}

                                                    {noti.type === "submissionEndBefore" && (
                            <Link to={`/competition/${noti.contestId?._id}`}>
                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Submission End soon ,contribute fast !
                            </Link>
                          )}

                                                </div>
                                                {noti.type === "Appreciate" &&
                                                    noti.fromUserId?._id !== user._id ? (
                                                    calculateTime(noti.createdAt) >= 2 &&
                                                        noti.postId.appreciate.length < 5 ? (
                                                        <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                            Your post has been appreciated by{" "}
                                                            <b>
                                                                <Link
                                                                    to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                    {noti.fromUserId?.firstName}
                                                                </Link>
                                                            </b>
                                                        </Link>
                                                    ) : (
                                                        <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                            Your post has been appreciated by{" "}
                                                            {noti.fromUserId?.firstName + " "}
                                                            and {noti.postId.appreciate.length - 1} others.
                                                        </Link>
                                                    )
                                                ) : null}
                                            </div>
                                            <div>
                                                <div className="flex items-center mt-2 sm:ml-7 md:ml-0 justify-end md:justify-center">
                                                    <h3 className="mr-2">
                                                        {formatDate(noti.createdAt)}
                                                    </h3>
                                                    {/* <div
                                                            onClick={() => deleteNotification(noti._id)}
                                                            className="text-[#EB8E8E] bg-card border border-[#EB8E8E] rounded-full p-[4px] cursor-pointer">
                                                            <img src="assets/NotificationIcon/disabled_by_default.png"></img>
                                                        </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-viewMore"></hr>
                                    </>
                                ) : (
                                    <>
                                        <div className="py-[8px] ml-2 md:ml-0 mt-1 md:flex md:flex-row md:justify-between">
                                            <div className="flex flex-row items-center justify-start md:justify-between">
                                                <div>
                                                    <NotificationIcon notification={noti} />
                                                </div>
                                                <div className="flex flex-col max-w-44 text-sm items-center justify-center ml-2 align-start">
                                                    {noti.fromUserId?._id !== user._id &&
                                                        noti.type === "comment_positive" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has left a
                                                                comment appreciating your post.
                                                            </Link>
                                                        )}
                                                    {noti.fromUserId?._id !== user._id &&
                                                        noti.type === "comment_negative" && (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has suggested
                                                                improvements to your post.
                                                            </Link>
                                                        )}
                                                    {noti.type === "Appreciate" &&
                                                        noti.fromUserId?._id !== user._id ? (
                                                        calculateTime(noti.createdAt) >= 2 &&
                                                            noti.postId.appreciate.length < 5 ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                Your post has been appreciated by{" "}
                                                                <b>
                                                                    <Link
                                                                        to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                        {noti.fromUserId?.firstName}
                                                                    </Link>
                                                                </b>
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                Your post has been appreciated by{" "}
                                                                {noti.fromUserId?.firstName + " "}
                                                                and {noti.postId.appreciate.length - 1}{" "}
                                                                others.
                                                            </Link>
                                                        )
                                                    ) : null}
                                                    {noti.type === "post" ? (
                                                        noti.fromUserId !== null &&
                                                            noti.fromUserId?._id !== user._id ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName} has recently
                                                                added a post to the{" "}
                                                                {noti.postId.category.includes("_")
                                                                    ? noti.postId.category.replace("_", " ")
                                                                    : noti.postId.category}{" "}
                                                                category.
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                You have posted{" "}
                                                                {noti.postId.type === "image" ? "an" : "a"}{" "}
                                                                {noti.postId.type} in the{" "}
                                                                {noti.postId.category.includes("_")
                                                                    ? noti.postId.category.replace("_", " ")
                                                                    : noti.postId.category}{" "}
                                                                category.
                                                            </Link>
                                                        )
                                                    ) : null}
                                                    {noti.type === "Improve" &&
                                                        noti.fromUserId?._id !== user._id ? (
                                                        calculateTime(noti.createdAt) <= 2 &&
                                                            noti.postId.improve.length < 5 ? (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                <b>
                                                                    <Link
                                                                        to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                        {noti.fromUserId?.firstName}
                                                                    </Link>
                                                                </b>{" "}
                                                                suggests improving your post.
                                                            </Link>
                                                        ) : (
                                                            <Link className="text-sm" to={`/post/${noti.postId.uniqueId}`}>
                                                                {noti.fromUserId?.firstName + " "}
                                                                and {noti.postId.improve.length - 1} others
                                                                suggest improving your post.
                                                            </Link>
                                                        )
                                                    ) : null}
                                                    {noti.type === "Password" && (
                                                        <h3>Password changed successfully.</h3>
                                                    )}
                                                    {noti.type === "Alternate Email" && (
                                                        <h3>Alternative email added to your account.</h3>
                                                    )}
                                                    {noti.type === "name_update" && (
                                                        <h3>Name updated successfully.</h3>
                                                    )}
                                                    {noti.type === "skill_update" && (
                                                        <h3>Your skills has been updated.</h3>
                                                    )}
                                                    {noti.type === "social_media_update" && (
                                                        <h3>Social media account added successfully!</h3>
                                                    )}
                                                    {noti.type === "profile_pic_update" && (
                                                        <h3>Profile pic added successfully!</h3>
                                                    )}
                                                    {noti.type === "peerInterest" &&
                                                        noti.fromUserId !== null && (
                                                            <Link className="text-sm"
                                                                to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                [{noti.fromUserId?.firstName}] is interested
                                                                in connecting as peers.
                                                            </Link>
                                                        )}
                                                    {noti.type === "peerAccepted" &&
                                                        noti.fromUserId !== null && (
                                                            <Link className="text-sm"
                                                                to={`/userDetails/${noti.fromUserId?._id}`}>
                                                                You are now connected as peers with{" "}
                                                                {noti.fromUserId?.firstName}.
                                                            </Link>
                                                        )}

                        

                                                    {noti.type === "contestCreated" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                     
                                                            {`${noti.contestId?.responses.sections[2].fields[0].response} is live register now`}
                                                        </Link>
                                                    )}

                                                    {noti.type === "joinedContest" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            You join the {noti.contestId?.responses.sections[2].fields[0].response} Contest
                                                            Successfully!
                                                        </Link>
                                                    )}
                                                    {noti.type === "contestSubmitted" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            {/* Congratulations you Submitted the{" "}
                                                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Successfully! */}
                                                            Congratulations on your Submission in{" "}
                                                            {noti.contestId?.responses.sections[2].fields[0].response}
                                                        </Link>
                                                    )}

                                                    {noti.type === "resultDeclare" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Result is
                                                            Declared Check Now !
                                                        </Link>
                                                    )}

                                                    {noti.type === "submissionStart" && (
                                                        <Link className="text-sm" to={`/competition/${noti.contestId?._id}`}>
                                                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Submission Start
                                                            Now !
                                                        </Link>
                                                    )}

                                                    {noti.type === "submissionEndBefore" && (
                            <Link to={`/competition/${noti.contestId?._id}`}>
                            {noti.contestId?.responses.sections[2].fields[0].response} Contest Submission End soon ,contribute fast !
                            </Link>
                          )}

                                                </div>
                                            </div>
                                            <div>
                                                <div className="flex items-center mt-2 sm:ml-7 md:ml-0 justify-end md:justify-center">
                                                    <h3 className="mr-2 text-[#A5A5A5] text-xs">
                                                        {formatDate(noti.createdAt)}
                                                    </h3>
                                                    {/* <div
                                                            onClick={() => deleteNotification(noti._id)}
                                                            className="text-[#EB8E8E] bg-card border border-[#EB8E8E] rounded-full p-[4px] cursor-pointer">
                                                            <img src="assets/NotificationIcon/disabled_by_default.png"></img>
                                                        </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-viewMore"></hr>
                                    </>
                                )
                            )
                        )
                    ) : (
                        <div className="text-primary">Loading...</div>
                    )}


                    <div className="flex flex-row justify-center mt-[-0.5rem]">
                        <button
                            onClick={() => {
                                history.push("/allNotifications")
                                settoggleProfileDropdown("hidden")
                            }}
                            className="rounded-full text-sm px-10 py-2 text-[#666666] border-1 border-viewmore bg-primary hover:bg-hover transition duration-200">
                            {/* {!loadMore ? "View More" : "View Less"}
                   */}
                            {x === 0 ? "No New Notification" : " See All Notifications"}
                        </button>
                    </div>

                </div>
            </div>


        </>
    );
};

export default NotificationCard;
