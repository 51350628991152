import { Avatar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NewSkills from "./NewSkills/NewSkills";
import ReportedPosts from "./ReportedPosts/ReportedPosts";
import Users from "./Users/Users";
import CloseAccount from "./CloseAccount/CloseAccounts";
import AddSkill from "./AddSkill/AddSkill";
import UsersCount from "./Skills/UsersCount";
import Feedback from "./Feedback/Feedback";

function Admin() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.AuthReducer);
  const [dashboardOpen, setDashboardOpen] = useState(true);
  const [reportPostOpen, setReportPostOpen] = useState(false);
  const [newSkillOpen, setNewSkillOpen] = useState(false);
  const [closeAccountOpen, setCloseAccountOpen] = useState(false);
  const [addSkillOpen, setAddSkillOpen] = useState(false);
  const [ourSkillOpen, setOurSkillOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: "UNSET_HEADER" });
  }, []);

  const openDashboardHandler = () => {
    setDashboardOpen(true);
    setReportPostOpen(false);
    setNewSkillOpen(false);
    setCloseAccountOpen(false);
    setAddSkillOpen(false);
    setOurSkillOpen(false);
  };

  const openReportPostHandler = () => {
    setReportPostOpen(true);
    setDashboardOpen(false);
    setNewSkillOpen(false);
    setCloseAccountOpen(false);
    setAddSkillOpen(false);
    setOurSkillOpen(false);
  };

  const openNewSkillHandler = () => {
    setNewSkillOpen(true);
    setReportPostOpen(false);
    setDashboardOpen(false);
    setCloseAccountOpen(false);
    setAddSkillOpen(false);
    setOurSkillOpen(false);
  };

  const closeAccountOpenHandler = () => {
    setCloseAccountOpen(true);
    setNewSkillOpen(false);
    setReportPostOpen(false);
    setDashboardOpen(false);
    setAddSkillOpen(false);
    setOurSkillOpen(false);
  };

  const openAddSkillHandler = () => {
    setAddSkillOpen(true);
    setCloseAccountOpen(false);
    setNewSkillOpen(false);
    setReportPostOpen(false);
    setDashboardOpen(false);
    setOurSkillOpen(false);
  };
  const feedbackHandler = () => {
    setFeedbackOpen(true);
    setAddSkillOpen(false);
    setCloseAccountOpen(false);
    setNewSkillOpen(false);
    setReportPostOpen(false);
    setDashboardOpen(false);
  };

  const openOurSkillHandler = () => {
    setOurSkillOpen(true);
    setAddSkillOpen(false);
    setCloseAccountOpen(false);
    setNewSkillOpen(false);
    setReportPostOpen(false);
    setDashboardOpen(false);
  };

  const logOutHandler = () => {
    localStorage.removeItem("myToken");
    dispatch({ type: "LOGOUT" });
    dispatch({ type: "RESET_POSTS" });
  };

  return (
    <div className="flex bg-light">
      <div className="border-r-2 h-[100vh]">
        <h1 className="border-b-2 py-2 px-4">FeedAnts Admin</h1>
        <ul className="p-2 w-[14vw]">
          <li className="py-2">
            <button
              onClick={openDashboardHandler}
              style={{
                background: dashboardOpen === true && "#008080",
                borderRadius: "10px",
                color: dashboardOpen === true ? "white" : "black",
              }}
              className="px-2 text-[0.9rem]">
              Dashboard
            </button>
          </li>
          <li className="py-2">
            <button
              onClick={openReportPostHandler}
              style={{
                background: reportPostOpen === true && "#008080",
                borderRadius: "10px",
                color: reportPostOpen === true ? "white" : "black",
              }}
              className="px-2 text-[0.9rem]">
              Reported Posts
            </button>
          </li>
          <li className="py-2">
            <button
              onClick={closeAccountOpenHandler}
              style={{
                background: closeAccountOpen === true && "#008080",
                borderRadius: "10px",
                color: closeAccountOpen === true ? "white" : "black",
              }}
              className="px-2 text-[0.9rem]">
              Closed Account Request
            </button>
          </li>
          <li className="py-2 text-[0.9rem]">
            <button
              onClick={openOurSkillHandler}
              style={{
                background: ourSkillOpen === true && "#008080",
                borderRadius: "10px",
                color: ourSkillOpen === true ? "white" : "black",
              }}
              className="px-2 text-[0.9rem]">
              Users count
            </button>
          </li>
          <li className="py-2 text-[0.9rem]">
            <button
              onClick={openNewSkillHandler}
              style={{
                background: newSkillOpen === true && "#008080",
                borderRadius: "10px",
                color: newSkillOpen === true ? "white" : "black",
              }}
              className="px-2 text-[0.9rem]">
              New Skills Request
            </button>
          </li>
          <li className="py-2 text-[0.9rem]">
            <button
              onClick={openAddSkillHandler}
              style={{
                background: addSkillOpen === true && "#008080",
                borderRadius: "10px",
                color: addSkillOpen === true ? "white" : "black",
              }}>
              Add/Delete Skills
            </button>
          </li>
          <li className="py-2 text-[0.9rem]">
            <button
              onClick={feedbackHandler}
              style={{
                background: feedbackOpen === true && "#008080",
                borderRadius: "10px",
                
              }}>
              Feedbacks
            </button>
          </li>
        </ul>
      </div>
      <div className="flex flex-col w-[87vw]">
        <div className="relative border-b-2 h-[5.6vh]">
          <div className="absolute right-2">
            {user.profilePicture ? (
              <Avatar src={user.profilePicture} />
            ) : (
              <Avatar>
                {user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}
              </Avatar>
            )}
          </div>
          <button className="text-blue-300" onClick={logOutHandler}>
            Logout
          </button>
        </div>
        <div>
          {feedbackOpen && <Feedback></Feedback>}
          {dashboardOpen && <Users />}
          {reportPostOpen && <ReportedPosts />}
          {newSkillOpen && <NewSkills />}
          {closeAccountOpen && <CloseAccount />}
          {addSkillOpen && <AddSkill />}
          {ourSkillOpen && <UsersCount />}
        </div>
      </div>
    </div>
  );
}

export default Admin;
